import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import DashboardLayout from '../layouts/menu_layouts/index';
import CalculateLandingPage from '../views/auth/landing_page';
import Error401 from '../views/errors/error401';
import Error404 from '../views/errors/error404';
import Error500 from '../views/errors/error500';
import Overview from '../views/overview/overview';
import Dashboard from '../views/dashboards/dasbhoard';
import Permissions from '../views/permissions/permissions';
import Components from '../views/components/components';
import MyCalendar from '../views/calendar/calendar';
import TestSuite from '../views/test_suite/test_suite';
import TestReviews from '../views/test_review/test_reviews';
import RunReview from '../views/test_review/test_review';
import Run from '../views/run/run';
import AuthLayout from '../layouts/auth_layout';
import Login from '../views/auth/login';
import Register from '../views/auth/register';
import TeamWall from '../views/teams/team_wall';
import Teams from '../views/teams/teams';
import Projects from '../views/projects/projects';
import Jobs from '../views/jobs/jobs';
import ProjectMembers from '../views/projects/project_members';
import OrgSettings from '../views/org/org_settings';
import UserProfile from '../views/users/user_profile';
import TestCaseInfo from '../views/test_suite/test_case_info';
import ProjectSettings from '../views/projects/project_settings';
import TeamSettings from "../views/teams/team_settings";
import MyOrgs from "../views/org/my_orgs";
import Forgot from '../views/auth/forgot';
import Reset from '../views/auth/reset';
import ArgsAndScripts from '../views/arguments_scripts/arguments_scripts';
import CreateOrg from '../views/auth/create_org_page';
import UserSettings from '../views/users/user_settings';
import TeamMembers from '../views/teams/team_members';
import Notifications from '../views/users/notifications';
import {GoogleOAuthProvider} from '@react-oauth/google';
import OrgUsers from '../views/org/org_users';
import withAuthElement from '../layouts/authHOC';
import Tools from "../views/tools/tools";

// Org root
const org_root = '/:org_domain';

export const getRoutes = createBrowserRouter([
        {
            path: '/',
            errorElement: <Error404/>,
            children: [
                {
                    path: '/',
                    element: <CalculateLandingPage/>,
                },
                {
                    element: <Error404/>
                }
            ],
        }, {
            path: "/login",
            errorElement: <Error404/>,
            children: [
                {
                    path: 'register',
                    element: <AuthLayout page_type='register'><Register/></AuthLayout>
                },
                {
                    path: 'forgot',
                    element: <AuthLayout page_type='forgot'><Forgot/></AuthLayout>
                },
                {
                    path: 'reset',
                    element: <AuthLayout page_type='reset'><Reset/></AuthLayout>
                },
                {
                    path: '/login',
                    element: <AuthLayout page_type='login'><React.StrictMode>
                        <GoogleOAuthProvider
                            clientId='1003242977401-coiq7cvi1udfqotbs4qek6qjmqofj08b.apps.googleusercontent.com'>
                            <Login/>
                        </GoogleOAuthProvider>
                    </React.StrictMode></AuthLayout>
                },
                {
                    element: <Error404/>
                }
            ]
        }, {
            path: "/errors",
            children: [
                {
                    path: '401',
                    element: <Error401/>
                },
                {
                    path: '403',
                    element: <Error404/>
                },
                {
                    path: '404',
                    element: <Error404/>
                },
                {
                    path: '500',
                    element: <Error500/>
                },
                {
                    element: <Error401/>
                }
            ]
        }, {
            path: "/create_org",
            element: <AuthLayout page_type='createOrg' skipLogin={true}><CreateOrg/></AuthLayout>,
            children: [
                {
                    element: <Error404/>
                }
            ]
        }, {
            path: org_root,
            element: withAuthElement(() => <DashboardLayout/>),
            errorElement: <Error404/>,
            children: [
                {
                    path: `overview`,
                    element: <Overview/>
                }, {
                    path: `teams`,
                    element: <Teams/>
                },
                {
                    path: `projects`,
                    element: <Projects/>
                },
                {
                    path: `components`,
                    element: <Components hideGlobal={true}/>
                },
                {
                    path: `test-reviews`,
                    element: <TestReviews personal={true}/>
                },
                {
                    path: `manage/settings`,
                    element: <OrgSettings/>
                },
                {
                    path: `manage/jobs`,
                    element: <Jobs/>
                },
                {
                    path: `users`,
                    element: <OrgUsers/>
                },
                {
                    path: `manage/logs`,
                    element: <OrgUsers/>
                },
                {
                    path: `manage/permissions`,
                    element: <Permissions/>
                }, {
                    path: 'projects/:project_key',
                    children: [{
                        path: `overview`,
                        element: <Overview/>
                    }, {
                        path: `run`,
                        element: <Run/>
                    }, {
                        path: `test-review/:run_id`,
                        element: <RunReview/>
                    }, {
                        path: `test-reviews`,

                        element: <TestReviews personal={false}/>
                    }, {
                        path: `test-suite/:case_id/info`,
                        element: <TestCaseInfo/>
                    }, {
                        path: `test-suite`,
                        element: <TestSuite/>
                    }, {
                        path: `calendar`,
                        element: <MyCalendar/>
                    }, {
                        path: `components`,
                        element: <Components/>
                    }, {
                        path: `dashboards`,
                        element: <Dashboard/>
                    }, {
                        path: `users/:user_id/view`,
                        element: <UserProfile/>
                    }, {
                        path: `manage/settings`,
                        element: <ProjectSettings/>
                    }, {
                        path: `manage/users`,
                        element: <ProjectMembers/>
                    }, {
                        path: `manage/jobs`,
                        element: <Jobs/>  // Todo remove this?
                    }, {
                        path: `manage/usage`,
                        element: <Dashboard/>  // TODO Change this
                    }, {
                        path: `manage/logs`,
                        element: <Dashboard/>  // TODO Change this
                    }, {
                        path: `manage/args_scripts`,
                        element: <ArgsAndScripts/>  // TODO Change this
                    }, {
                        element: <Error404/>
                    }]
                },{
                    path: 'teams/:team_id/:team_name',
                    errorElement: <Error404/>,
                    children: [
                        {
                            path: `wall`,
                            element: <TeamWall/>
                        }, {
                            path: `members`,
                            element: <TeamMembers/>
                        }, {
                            path: `settings`,
                            element: <TeamSettings/>
                        },
                        {
                            path: `users/:user_id/view`,
                            element: <UserProfile/>
                        },
                        {
                            element: <Error404/>
                        }
                    ]
                },{
                    path: 'user/:user_id',
                    errorElement: <Error404/>,
                    children: [
                        {
                            path: `profile`,
                            element: <UserProfile/>
                        },
                        {
                            path: `projects`,
                            element: <Projects/>
                        },
                        {
                            path: `teams`,
                            element: <Teams/>
                        },
                        {
                            path: `settings`,
                            element: <UserSettings/>
                        },
                        {
                            path: `notifications`,
                            element: <Notifications/>
                        },
                        {
                            path: `organizations`,
                            element: <MyOrgs/>
                        },
                        {
                            path: `tools`,
                            element: <Tools/>
                        },
                        {
                            element: <Error404/>
                        }
                    ]
                },
                {
                    element: <Error404/>
                }
            ]
        }
    ]
)