import React, { useState } from 'react';
import { Input, Typography, Space } from 'antd';
import {colors} from "../../assets/data/colors";

const { TextArea } = Input;
const { Text } = Typography;

const UrlDecoder: React.FC = () => {
    const [encodedUrl, setEncodedUrl] = useState<string>('');
    const [decodedUrl, setDecodedUrl] = useState<string>('');

    const handleDecode = (value: string) => {
        try {
            const decoded = decodeURIComponent(value);
            setDecodedUrl(decoded);
        } catch (err) {
            setDecodedUrl('Invalid URL encoding.');
        }
    };

    return (
        <Space direction="vertical" size="large" style={{ width: '97%' }}>
            <p style={{backgroundColor: colors.gray.background, padding: 25}}>
                Example: <br/>https://www.example.com/search?query=url%20encoding%20example&sort=desc&page=1
            </p>
            <TextArea
                rows={4}
                value={encodedUrl}
                onChange={(e) => {
                    setEncodedUrl(e.target.value);
                    handleDecode(e.target.value);
                }}
                placeholder="Enter URL-encoded string here"
            />
            <Text strong>Decoded URL:</Text>
            <TextArea
                rows={4}
                value={decodedUrl}
                readOnly
                style={{ backgroundColor: '#f5f5f5' }}
            />
        </Space>
    );
};

export default UrlDecoder;
