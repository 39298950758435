import {get, post, put, deletion} from '../../services/axiosWrapper';
import {PageResource} from "../../models/dtos/page_resource";
import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import { Project, LandingProject, ProjectSetting, ProjectTypes, ProjectTeamOwners } from "../../models/entities/project";
import {
    ArgumentScriptSetting,
    GithubSetting,
    ModuleSettings,
    ProjectSettingParams,
    RunProjectSetting,
    SlackIntSetting
} from "../../models/entities/settings";
import {MemberInfo, User} from "../../models/entities/user";
import { PermissionsInterface } from '../../models/entities/permissions';
import {addMemberParamsModalType} from "../../models/entities/helpers/modal";
import {memberType} from "./team_actions";


const api_path = '/api/project/';

interface org_project {
    project_id: number,
    org_id: number,
    only_visible?: boolean,
    search?: string
}

interface putSettingActionsParams extends org_project{
    title?: string,
    key?: string,
    // run_arg_switch?: boolean,
    project_visible?: boolean,
    project_state?: boolean,
    project_type_id?: number
}

interface updateProjectGithubIntegrationParams extends org_project{
    project_id: number,
    org_id: number,
    owner?: string,
    integration_user?: string,
    token_name?: string,
    token_value?: string,
    repo_name?: string,
    repo_url?: string,
    repo_base?: string,
    repo_path?: string,
    github_warnings?: boolean
}

interface saveProjectRunScriptParams extends org_project{
    id?: number,
    title?: string,
    default_value?: string,
    status?: boolean
}

interface saveProjectRunSlackIntParams extends org_project{
    id?: number,
    title?: string,
    url?: string,
    channel_id?: string,
    status?: boolean
}

interface deleteRunArgumentScriptsParams extends org_project{
    ids?: number[]
}

interface deleteSlackIntParams extends org_project{
    ids?: number[]
}

interface updateProjectOwnerParams extends org_project{
    owner_id?: number
}

interface saveProjectModulesParams {
    project_id: number,
    title?: string,
    version?: string,
    module_order?: number,
    project_module_id?: number
}


export const getProjects = (params: { org_id: number, user_id?: string | number, key?: string, search?: string , limit?:number}) => (dispatch: AppDispatch): Promise<PageResource<Project>> => {
    dispatch(fetchStartAction());

    let url = `${api_path}getProjects?`
    const request: any = get(url, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const getLandingProject = (user_id:number) => (dispatch: AppDispatch): Promise<LandingProject> => {
    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getLandingProject?user_id=${user_id}`)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const getProjectSettings = (params: {project_id:number}) => (dispatch: AppDispatch): Promise<ProjectSetting> => {
    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getProjectSettings`, params)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const getProjectTypes = (params: {project_id:number}) => (dispatch: AppDispatch): Promise<ProjectTypes[]> => {
    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getProjectTypes`, params)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const createProject = (data:{ name:string, project_key:string, test_cases:number, org_id: number, icon:string, user_id: number}, projects:Project[]) => (dispatch: AppDispatch): Promise<Project[]> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}create`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                value.data = [...projects, value.data.new_project];
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const deleteProject = (project_id:number, org_id:number) => (dispatch: AppDispatch): Promise<number> => {
    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteProject?org_id=${org_id}&project_id=${[project_id]}`)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.status;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const deleteProjectModule = (project_id:number, ids:number[]) => (dispatch: AppDispatch): Promise<number> => {
    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteProjectModule?project_id=${[project_id]}&ids=${ids}`)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.status;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getAddProjectSettingsActions = (params: {project_id:number}) => (dispatch: AppDispatch): Promise<RunProjectSetting> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectRunSettings`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectSlackIntegrations = (params: {org_id: number, project_id:number, only_visible?: boolean, search?: string}) => (dispatch: AppDispatch): Promise<PageResource<SlackIntSetting>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectSlackIntegrations`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getOrgProjectKeys = (org_id: number) => (dispatch: AppDispatch): Promise<string[]> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getOrgProjectKeys?org_id=${org_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectGithubIntegrations = (org_id: number, project_id:number) => (dispatch: AppDispatch): Promise<{content: GithubSetting}> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectGithubIntegrations?org_id=${org_id}&project_id=${[project_id]}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectModules = (project_id:number) => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectModules?project_id=${[project_id]}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectProgrammingLanguages = (project_id:number) => (dispatch: AppDispatch): Promise<{content: {id: number, version: string}}> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectProgrammingLanguages?project_id=${project_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectRunSettings = (params: ProjectSettingParams) => (dispatch: AppDispatch): Promise<PageResource<RunProjectSetting>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectRunSettings`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectNotificationSetting = (params: {id: number, org_id: number, before_run?: boolean, after_run?: boolean, case_assignment?: boolean, case_unassignment?: boolean}) => (dispatch: AppDispatch): Promise<boolean> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}updateProjectNotificationSetting`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectProgrammingLanguages = (params: {org_id: number, project_id:number, language_id: number, version: string}) => (dispatch: AppDispatch): Promise<boolean> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectProgrammingLanguages`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const addProjectMembers = (params: addMemberParamsModalType) => (dispatch: AppDispatch): Promise<PageResource<MemberInfo>> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}addProjectMembers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProject = (params: putSettingActionsParams) => (dispatch: AppDispatch): Promise<PageResource<LandingProject>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProject`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectGithubIntegration = (params: updateProjectGithubIntegrationParams) => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectGithubIntegration`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectOwner = (params: updateProjectOwnerParams) => (dispatch: AppDispatch): Promise<PageResource<ProjectTeamOwners>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectOwner`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectUsers = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<User>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectUsers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const fetchProjectMembers = (params: {
    id:number,
    search:string,
    limit:number,
    offset:number
}) => (dispatch: AppDispatch): Promise<PageResource<MemberInfo>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}fetchProjectMembers?`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getSystemProgrammingLanguages  = () => (dispatch: AppDispatch): Promise<PageResource<{id: number, title: string}>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getSystemProgrammingLanguages `)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectRunScript = (params: saveProjectRunScriptParams) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectRunScript`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectSlackIntegration = (params: saveProjectRunSlackIntParams) => (dispatch: AppDispatch): Promise<PageResource<SlackIntSetting>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectSlackIntegration`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectRunArgument = (params: saveProjectRunScriptParams) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectRunArgument`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectSystemArgument = (params: saveProjectRunScriptParams) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectSystemArgument`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectModules = (params: saveProjectModulesParams) => (dispatch: AppDispatch): Promise<PageResource<ModuleSettings>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectModules`, {params})

    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value.data;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const updateMemberRole = (params: {id: number, role_id: number}) => (dispatch: AppDispatch): Promise<{"new_role": number, "new_label": string, permissions: PermissionsInterface}> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateMemberRole`, params)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const getProjectRunArgs = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectRunArguments`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectSystemArguments = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectSystemArguments`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectRunScripts = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectRunScripts`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectRunNotifications = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<any>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getProjectRunNotifications`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteRunArgument = (params: deleteRunArgumentScriptsParams) => (dispatch: AppDispatch): Promise<ArgumentScriptSetting[]> => {

    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteRunArgument`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteSystemArgument = (params: deleteRunArgumentScriptsParams) => (dispatch: AppDispatch): Promise<ArgumentScriptSetting[]> => {

    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteSystemArgument`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteRunScript = (params: deleteRunArgumentScriptsParams) => (dispatch: AppDispatch): Promise<ArgumentScriptSetting[]> => {

    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteRunScript`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteSlackIntegration = (params: deleteSlackIntParams) => (dispatch: AppDispatch): Promise<SlackIntSetting[]> => {

    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteSlackIntegration`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getAllProjectUsers = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<User>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getAllProjectUsers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getMembersNotInProject = (params: {
    id: number,
    org_id: number,
    search: string,
    searchFor: memberType[]
}) => (dispatch: AppDispatch): Promise<{
    id: number,
    member_type: 1 | 2,
    email: string,
    name: string
}[]> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getMembersNotInProject`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteProjectUser = (project_id:number, user_ids:number[]) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deleteProjectUser?project_id=${project_id}&user_ids=${user_ids}`)
    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const syncProject = (project_id:number, branch:string, org_id:number) => (dispatch: AppDispatch): Promise<{
    status: number,
    success: boolean,
    message: string
}> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}syncProject`, {project_id, branch, org_id})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const validateBranch = (params: {project_id:number, branch: string}) => (dispatch: AppDispatch): Promise<{
    success: boolean,
    msg: string
}> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}validateBranch`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
