import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal, Mentions } from 'antd';
import { PageResource } from "../../models/dtos/page_resource";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import {useAppContext} from "../../AppContext";
import { RunCases } from "../../models/entities/test_reviews";
import { postRunCasesComment } from "../../redux/actions/run_actions";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";

type TeamProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    page: PageResource<RunCases>
    setPage: Dispatch<SetStateAction<PageResource<RunCases>>>;
    checkedCases: number[];
    setCheckedCases: Dispatch<SetStateAction<number[]>>;
    newComments: any;
    setNewComments: Dispatch<SetStateAction<any>>;
    projectUsers: {key: string, value: string, label: string}[];
    setProjectUsers: Dispatch<SetStateAction<{key: string, value: string, label: string}[]>>
}

export function MassCommentModal(props: TeamProps):JSX.Element{
    const {setVisible, visible, page, setPage, checkedCases, newComments, setNewComments, projectUsers} = props;
    
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg, currentProject, loggedInUser} = useAppContext();

    const mentions: any = [];

    const [massComment, setMassComment] = useState<string>('')
    

    const org_id = currentOrg.id
    const project_id = currentProject.id

    const updateBadgeNumber = (case_id:number, increase=true) => {
        let temp_data = {...page}
        const row: RunCases | undefined = temp_data.content.find(item => item.test.id === case_id)
        if (row) {
            const rowIndex = temp_data.content.indexOf(row)
            if(increase){
                row.test.comments += 1
            }else{
                row.test.comments -= 1
            }
            temp_data.content[rowIndex] = row
            setPage(temp_data)
        }
    }

    const handlePostMassComment = () => {
        const checkedList = page.content.filter(item => checkedCases.includes(item.row_id));

        checkedList.forEach(item => {
            const test_id = item.test.id
            const t_comments = newComments[test_id] ? newComments[test_id] : {
                comment: '', mentions: [], visible: true, loading: true, commentPage: {
                    content: [],
                    first: true,
                    last: true,
                    number: 0,
                    numberOfElements: 0,
                    size: 20,
                    totalElements: 0,
                    totalPages: 0
                }
            }
            const data = {
                run_id: item.run.id,
                case_ids: [test_id],
                user_id: loggedInUser.id,
                comment: massComment,
                project_id: project_id,
                org_id: org_id
            }
            
            dispatch(postRunCasesComment(data))
                .then(result => {
                    t_comments.comment = ''
                    t_comments.commentPage.content = [{
                        commentator: loggedInUser,
                        id: result.content,
                        created_on: new Date().toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        }),
                        comment: massComment
                    }, ...t_comments.commentPage.content]
    
                    // update newComments
                    let temp_new_comments = {...newComments}
                    temp_new_comments[item.row_id] = t_comments
    
                    setNewComments(temp_new_comments)
    
                    // update badge number
                    updateBadgeNumber(test_id)
                    setVisible(false)
                    setMassComment('')
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        })
    }


    const handleCancel = () => {
        setVisible(false)
        };


    return (
        <Modal open={visible}
               title="Comment to selected Cases"
               okText='Comment'
               cancelText="Cancel"
               onCancel={handleCancel}
               onOk={() => handlePostMassComment()}
        >    
        <Mentions 
            rows={6}
            placeholder="You can use @ to ref user here"
            value={massComment}
            onChange={(text) => {
                setMassComment(text)
            }}
            onSelect={(e) => {
                mentions.push(e);
            }}
            options={projectUsers}
        />
        </Modal>
    )
}
