import React from 'react';
import { Layout, Menu, Skeleton} from 'antd';
import LcsManager from "../assets/local_storage/lcs";
import {colors} from "../assets/data/colors";


function SkeletonLayout(): JSX.Element {
    const lcs_manager = new LcsManager()

    const isCollapsed = ():boolean => {
        const is_collapsed = lcs_manager.get_item_from_lcs(lcs_manager.Keys.is_sidemenu_collapsed)
        if(is_collapsed === null){
            lcs_manager.set_item_to_lcs(lcs_manager.Keys.is_sidemenu_collapsed, false)
            return false
        }
        return is_collapsed
    }

    return <Layout style={{minHeight: "100vh"}}>
        <Layout.Sider trigger={null} className={isCollapsed()?'skeletonMini': ''} style={{backgroundColor: colors.gray.dark, color: '#fff'}}>
            <div style={{paddingTop:1, top: 0, position: 'sticky'}}>
                <div className="logo"> </div>
                <Menu className='custom-menu' mode='inline' theme='dark' style={{backgroundColor: 'inherit', color: 'inherit', fontSize:15}}>
                    <Skeleton loading={true} active/>
                </Menu>
            </div>
        </Layout.Sider>
        <Layout className="site-layout" >
            <Layout.Header className="site-layout-background"/>
            <Layout.Content className="site-layout-background site-layout-content">
                <Skeleton loading={true} active/>
                <Skeleton loading={true} active/>
            </Layout.Content>
        </Layout>
    </Layout>
}
export default SkeletonLayout;