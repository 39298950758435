import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import ButtonC from "../button";
import {Dropdown, Space, Switch, Table} from "antd";
import {AppDispatch} from '../../redux/store';
import {useDispatch} from 'react-redux';
import {useAppContext} from '../../AppContext';
import CancelAction from '../notification/cancel_req';
import {DeleteOutlined, DownOutlined} from '@ant-design/icons';
import {getProjectRunNotifications} from '../../redux/actions/project_actions';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

interface SystemNotificationsProps {
    systemNotifications: {
        id: number;
        status: boolean;
        title: string;
    }[], // Corrected structure to match the first file
    setSystemNotifications: Dispatch<SetStateAction<{
        id: number;
        status: boolean;
        title: string;
    }[]>>; // Added missing closing brace and corrected the structure
}


function TableRunNotifications(props: SystemNotificationsProps): JSX.Element {

    const dispatch: AppDispatch = useDispatch();
    const {currentOrg, currentProject} = useAppContext();

    const org_id = currentOrg.id
    const project_id = currentProject.id

    // const [runNotifications, setRunNotifications] = useState<any[]>([]);
    const [checkedNotifications, setCheckedNotifications] = useState<any[]>([])

    const {systemNotifications, setSystemNotifications} = props

    const selected_notifications: number = checkedNotifications ? checkedNotifications.length : 0

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getProjectRunNotifications({org_id, project_id, only_visible: true})).then(notifications => {
                let notifs: any[] = [];
                notifications.content.forEach(notification => {
                    notifs.push({title: notification.title, id: notification.id, status: notification.status})
                    ;
                    setSystemNotifications(notifs)
                })
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    const deleteSelectedCases = () => {
        let new_table = Object.values(systemNotifications);

        new_table = new_table.filter(item => !checkedNotifications.includes(item.id))

        setSystemNotifications(new_table);
        setCheckedNotifications([]);
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title:
                <>
                    <Dropdown
                        disabled={selected_notifications === 0}
                        menu={{
                            items: [{
                                key: `mass-delete`,
                                label: <CancelAction secs={2}
                                                     action={deleteSelectedCases}><DeleteOutlined/> Delete</CancelAction>,
                            }]
                        }}
                    >
                        <a>
                            <Space>
                                Actions <DownOutlined/>
                            </Space>
                        </a>
                    </Dropdown>
                    <div style={{fontSize: 11}}>selected {selected_notifications}</div>
                </>,
            dataIndex: 'actions',
            key: 'actions'
        }
    ];

    const handleSwitchChange = (id: number) => {
        setSystemNotifications(systemNotifications.map(notification => {
            if (notification.id === id) {
                return {...notification, status: !notification.status}; // Toggle status
            }
            return notification;
        }));
    };


    return (
        <>
            <ButtonC key={'clear_all'} type={"primary"} onClick={() => setSystemNotifications([])} text={'Clear All'}
                     style={{float: 'right', marginBottom: 10}}/>
            <Table
                pagination={{pageSize: 10, hideOnSinglePage: true}}
                rowSelection={{
                    selectedRowKeys: checkedNotifications,
                    onChange: (keys, rows) => {
                        setCheckedNotifications(keys)
                    }
                }}
                dataSource={systemNotifications && systemNotifications.map(notification => ({
                    title: notification.title,
                    key: notification.id,
                    status: <Switch checked={notification.status}
                                    onChange={() => handleSwitchChange(notification.id)}/>,
                    actions: <div style={{display: 'flex', alignItems: 'center'}}/>
                }))}
                columns={columns}
            />
        </>
    )
}

export default TableRunNotifications;