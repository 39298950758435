import React from 'react';
import {useAppContext} from "../AppContext";
import {Link, Navigate} from 'react-router-dom';
import {stringHasValue} from "../assets/helpers/helper";
import {AuthOptions} from "../models/entities/menu";
import {colors} from "../assets/data/colors";

type AuthOptionsProps = {
    page_type: AuthOptions,
    children: React.ReactNode,
    skipLogin?: boolean
}

const pagesProps = {
    'login': {
        img: "/icons/loginPanda.png",
        bcColor: colors.gray.login,
        header: "Welcome Panda!",
        showSignUp: true,
        showForgot: true,
        showBackToLogin: ""
    },
    'register': {
        img: "/icons/signup.png",
        bcColor: colors.gray.signup,
        header: "Join the other Pandas",
        showSignUp: false,
        showForgot: false,
        showBackToLogin: "Already have an account?"
    },
    'forgot': {
        img: "/icons/forgot.png",
        bcColor: colors.gray.forgot,
        header: "Did you forgot your password?",
        showSignUp: true,
        showForgot: false,
        showBackToLogin: "Back to Sign In"
    },
    'reset': {
        img: "/icons/forgot.png",
        bcColor: colors.gray.forgot,
        header: "Reset your password Panda!",
        showSignUp: true,
        showForgot: false,
        showBackToLogin: "Back to Sign In"
    },
    'underConstruction': {
        img: "/icons/underConstruction.png",
        bcColor: colors.gray.underConstruction,
        header: "Our Home Page Is Under Construction",
        showSignUp: true,
        showForgot: true,
        showBackToLogin: "Already have an account?"
    },
    'createOrg': {
        img: "/icons/createOrg.png",
        bcColor: colors.gray.createOrg,
        header: "Create New Organization",
        showSignUp: false,
        showForgot: false,
        showBackToLogin: ""
    }
}

// page_type?:AuthOptions
function AuthLayout({page_type, children, skipLogin}: AuthOptionsProps): JSX.Element {
    const { userToken } = useAppContext();

    const pageProps = pagesProps[page_type]

    const isAuthenticated = stringHasValue(userToken);

    if (isAuthenticated && !skipLogin) {
        return <Navigate to="/" />;
    }

    return (
        <div className="signupApp">
            <div className="signupImagesSection" style={{backgroundColor: pageProps.bcColor}}>
                {/* Add your images or content for the right section here */}
                <img src={pageProps.img} alt="Login Image Logo" className="signupImages"/>
            </div>
            <div className="signupContainer">
                <h1>{pageProps.header}</h1>
                {children}
                {
                    pageProps.showSignUp? <p className="signupP">
                        Don't have an account? <Link to='/login/register'> Sign Up </Link>
                    </p>: null
                }
                {
                    stringHasValue(pageProps.showBackToLogin)? <p>
                        <Link to="/login"> {pageProps.showBackToLogin}</Link>
                    </p>: null
                }
                {
                    pageProps.showForgot? <p><Link to="/login/forgot"> Forgot your password? </Link></p>: null
                }
            </div>
        </div>
    )
}

export default AuthLayout;
