import React, {Dispatch, SetStateAction, useState} from 'react';
import {Col, FloatButton, Tooltip, Collapse} from 'antd';
import RowC from "../../components/row";
import TableRunSlack from '../../components/tables/table_run_slack';
import {InfoCircleOutlined} from '@ant-design/icons';
import {colors} from '../../assets/data/colors';
import TableRunTeams from '../../components/tables/table_run_teams';
import TableRunScripts from '../../components/tables/table_script_notifications';
import TableRunNotifications from '../../components/tables/table_run_notifications';
import {ArgumentScriptSetting} from '../../models/entities/settings';
import {RunSlacks, RunTeams} from '../../models/entities/run_script_notifications';

const {Panel} = Collapse;

// interface NotificationsProps {
//     notifications: {
//         system: {id: number, status: boolean}[]

//     },
//     setNotifications: Dispatch<SetStateAction<{
//         system: {id: number, status: boolean}[]

//     }>>
//     // scripts:{

//     // },
//     // slack:{

//     // },
//     // teams:{

//     // }
//     // settings: {
//     //     run: {
//     //         runDate: Dayjs | null,
//     //         isRecurring: boolean,
//     //         recurringOption: 'Day'| 'Week'| 'Month'| 'Quarter'| 'Custom',
//     //         title: string
//     //     },
//     //     githubBranch: string,
//     //     reruns: number,
//     //     maxParallel: number,
//     //     handleSetMaxParallel:(e:number|null)=>void,
//     // },
//     // selected:CasesPreviewAction
//     // scripts: string;
// }

interface NotificationsProps {
    systemNotifications: {
        id: number;
        status: boolean;
        title: string;
    }[], // Corrected structure to match the first file
    setSystemNotifications: Dispatch<SetStateAction<{
        id: number;
        status: boolean;
        title: string;
    }[]>>; // Added missing closing brace and corrected the structure
    scripts: ArgumentScriptSetting[],
    setScripts: Dispatch<SetStateAction<ArgumentScriptSetting[]>>
    slackNotifications: RunSlacks[],
    setSlackNotifications: Dispatch<SetStateAction<RunSlacks[]>>,
    teamNotifications: RunTeams[],
    setTeamNotifications: Dispatch<SetStateAction<RunTeams[]>>
}

function RunScripts(props: NotificationsProps): JSX.Element {
    const icon_style = {color: colors.green.bamboo, fontSize: 18}

    const [showButton, setShowButton] = useState<boolean>(false)
    const {
        systemNotifications, setSystemNotifications,
        scripts, setScripts,
        slackNotifications, setSlackNotifications,
        teamNotifications, setTeamNotifications
    } = props

    return (
        <RowC>
            <Col flex={'auto'}>
                <Collapse onChange={(e) => {
                    setShowButton(e.includes('1'))
                }}>
                    <Panel
                        key={1}
                        header={<div><span>Notifications</span>
                            <Tooltip
                                placement="right"
                                title={
                                    <>
                                        <h3>Info</h3>
                                        <p>Includes all the notifications that have been set up by your
                                            Administrator.</p>
                                    </>
                                }
                                color={colors.green.bamboo}
                            >
                                <InfoCircleOutlined style={Object.assign({}, {marginLeft: 15}, icon_style)}/>
                            </Tooltip>
                        </div>}
                        // extra={
                        //     showButton ?
                        //         <Button size={'small'}
                        //                 onClick={(event) => {
                        //                     // If you don't want click extra trigger collapse, you can prevent this:
                        //                     event.stopPropagation();
                        //                 }}> Add new</Button> : null
                        // }
                    >
                        <TableRunNotifications systemNotifications={systemNotifications}
                                               setSystemNotifications={setSystemNotifications}/>
                    </Panel>
                    <Panel header={<div><span>Scripts</span>
                        <Tooltip
                            placement="right"
                            title={
                                <>
                                    <h3>Info</h3>
                                    <p>Includes all the enabled System Scripts that have been set up by your
                                        Administrator.</p>
                                </>
                            }
                            color={colors.green.bamboo}
                        >
                            <InfoCircleOutlined style={Object.assign({}, {marginLeft: 15}, icon_style)}/>
                        </Tooltip>
                    </div>} key={2}>
                        <TableRunScripts scripts={scripts} setScripts={setScripts}/>
                    </Panel>
                    <Panel header={<div><span>Slack Messages</span>
                        <Tooltip
                            placement="right"
                            title={
                                <>
                                    <h3>Info</h3>
                                    <p>You can customize your desired Slack Messages in this section, and then
                                        notifications will be posted on the respective Slack Channels.</p>
                                </>
                            }
                            color={colors.green.bamboo}
                        >
                            <InfoCircleOutlined style={Object.assign({}, {marginLeft: 15}, icon_style)}/>
                        </Tooltip>
                    </div>} key={3}>
                        <TableRunSlack slackNotifications={slackNotifications}
                                       setSlackNotifications={setSlackNotifications}/>
                    </Panel>
                    <Panel header={<div><span>Teams</span>
                        <Tooltip
                            placement="right"
                            title={
                                <>
                                    <h3>Info</h3>
                                    <p>You can customize your desired Team(s) in this section, and then notifications
                                        will be posted on the respective Team(s) Walls.</p>
                                </>
                            }
                            color={colors.green.bamboo}
                        >
                            <InfoCircleOutlined style={Object.assign({}, {marginLeft: 15}, icon_style)}/>
                        </Tooltip>
                    </div>} key={4}>
                        <TableRunTeams teamNotifications={teamNotifications}
                                       setTeamNotifications={setTeamNotifications}/>
                    </Panel>
                    {/* Add more Panel components as needed */}
                </Collapse>
            </Col>
            <Col flex={'70px'}/>
            <FloatButton.BackTop style={{marginBottom: 50, marginRight: 15}}/>
        </RowC>
    )
}

export default RunScripts;