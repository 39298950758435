import React from 'react';
import {Avatar, Tooltip} from "antd";
import {ReviewersPreview} from "../models/entities/test_case_info";
import {colors} from "../assets/data/colors";
import {useAppContext} from "../AppContext";


interface PreviewProps {
    reviewers: ReviewersPreview,
    avatarSize?: 'large' | 'small' | 'default'
}

function ReviewersAvatars({reviewers, avatarSize}: PreviewProps): JSX.Element {
    const {currentOrg, currentProject} = useAppContext()

    if(reviewers.total_reviewers>0){
        return (
            <Avatar.Group>
                {
                    reviewers.reviewers.map(reviewer=>{
                        return <Tooltip
                            key={reviewer.id}
                            placement="top"
                            title={
                                <a target='_blank' rel="noreferrer"
                                   href={`/${currentOrg.domain}/projects/${currentProject.p_key}/users/${reviewer.id !== undefined ? reviewer.id : reviewer.assignee}/view`}>
                                    {reviewer.fullname}
                                </a>}
                        >
                            <Avatar size={avatarSize} style={{ backgroundColor: colors.blue.dark }}>{reviewer.initials?reviewer.initials:null}</Avatar>
                        </Tooltip>
                    })
                }
                {
                    reviewers.total_reviewers > 5?
                        <Avatar size={avatarSize} style={{ backgroundColor: colors.blue.dark }}>+{reviewers.total_reviewers - 5}</Avatar>
                        : null
                }
            </Avatar.Group>
        )
    }

    return(<></>)

}

export default ReviewersAvatars;
