import React, {useEffect, useState} from 'react';
import {Dropdown, Space, Table} from "antd";
import {PageResource} from "../../models/dtos/page_resource";
import {useAppContext} from "../../AppContext";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";
import {Job} from "../../models/entities/job";
import {Team} from "../../models/entities/team";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {getJobs} from "../../redux/actions/job_actions";
import {handleErrorResponse, safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import {getOrgTeams} from "../../redux/actions/team_actions";
import {DeleteOutlined, DownOutlined} from "@ant-design/icons";
import {ColumnsType, FilterValue} from "antd/es/table/interface";
import {allowedExtraUserColumns} from "../../models/entities/helpers/table";
import CancelAction from "../notification/cancel_req";

type UsersProps = {
    data: any,
    extra_columns: allowedExtraUserColumns[],
    permissions: boolean,
    limit: number,
    deleteUserAction?: (user_ids:number[]) => void,
    fetchFilteredUsers?: (value_type: string, value:React.Key | boolean) => boolean
}

function TableUsers(props: UsersProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const {extra_columns, data, deleteUserAction, fetchFilteredUsers, permissions, limit} = props;
    const {currentOrg, loggedInUser} = useAppContext();

    // handle state
    const [sortedInfo, setSortedInfo] = useState<FilterValue| null| any>(null);
    const [checkedUsers, setCheckedUsers] = useState<number[]>([])
    const [jobsPage, setJobsPage] = useState<PageResource<Job>>(createEmptyPage())
    const [teamsPage, setTeamsPage] = useState<PageResource<Team>>(createEmptyPage())

    const selected_users: number = checkedUsers.length

    const hasIconColumn = extra_columns.includes('icon')
    const hasJobsColumn = extra_columns.includes('jobs')
    const hasTeamsColumn = extra_columns.includes('teams')
    const hasActionsColumn = extra_columns.includes('actions')

    useEffect(() => {
        if(hasJobsColumn){
            dispatch(getJobs(currentOrg.id))
            .then(foundPage => {
                setJobsPage(foundPage)
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
        }

        if(hasTeamsColumn){
            dispatch(getOrgTeams({
                org_id: currentOrg.id,
                user_id: loggedInUser.id,
                limit: limit?limit:10,
                teams_type: 'all'}))
            .then(foundPage => {
                setTeamsPage(foundPage)
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
    }, [dispatch])

    const handleChange = (filters:any, sorter:any) => {
        setSortedInfo(sorter)
    };

    // const getActionsItems = () => {
    //     let actionMenu: MenuItemType[] =
    // }
    const handleDelete = () => {
        if(deleteUserAction){
            deleteUserAction(checkedUsers)
            setCheckedUsers([])
        }
    }

    const columns = () => {
        let columns: ColumnsType = []
        if(hasIconColumn){
            columns.push({
                title: "",
                width: '30px',
                dataIndex: "icon",
                key: "icon",
                ellipsis: true
            })
        }
        const defaultColumns: ColumnsType = [{
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a:any, b:any) => a.name.props.children.localeCompare(b.name.props.children),
            ellipsis: true
        },{
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a:any, b:any) => a.email.localeCompare(b.email),
            ellipsis: true
        },{
            title: "Role",
            dataIndex: "role",
            key: "role",
            filteredValue: sortedInfo? sortedInfo.role : null,
            filters: [
                // Fetch All jobs, or some of them
                {text: 'Admin', value: 'Admin'},
                {text: 'Member', value: 'Member'}
            ],
            onFilter: (value, row) => row.role.includes(value.toString()),
            ellipsis: true
        }]

        columns = [...columns, ...defaultColumns]

        if (hasJobsColumn){
            columns.push({
                title: "Jobs",
                dataIndex: "jobs",
                key: "jobs",
                filteredValue: sortedInfo? sortedInfo.jobs : null,
                filters: jobsPage.content.map((job)=> {
                    return {text: job.name, value: job.id}
                }),
                onFilter: fetchFilteredUsers? (value) => fetchFilteredUsers('job_id', value): undefined,
                ellipsis: true
            })
        }

        if (hasTeamsColumn){
            columns.push({
                title: "Teams",
                dataIndex: "teams",
                key: "teams",
                filteredValue: sortedInfo? sortedInfo.teams : null,
                filters: teamsPage.content.map((team)=> {
                    return {text: team.name, value: team.id}
                }), // fetch teams TODO
                // onFilter: (value, row) => filterJobs(value, row), // filter by team TODO
                ellipsis: true
            })
        }

        if (hasActionsColumn) {
            columns.push({
                title: <>
                    <Dropdown
                        disabled={
                            selected_users === 0 || !permissions
                        }
                        menu={{items: [
                                {
                                    key: `mass-delete`,
                                    label: <CancelAction secs={2} action={handleDelete}><DeleteOutlined /> Delete</CancelAction>,
                                }
                            ]
                        }}
                    >
                        <a>
                            <Space>
                                Actions <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    {permissions ? <div style={{fontSize: 11}}>selected {selected_users}</div> : null}
                </>,
                width: '170px',
                dataIndex: 'actions',
                key: 'actions'
            })
        }

        return columns
    }

    return <Table
        columns={columns()}
        dataSource={data}
        onChange={handleChange}
        rowSelection={ permissions ? {
            selectedRowKeys: checkedUsers,
            onChange: (keys ) => {
                setCheckedUsers(keys as number[]);
                },
            getCheckboxProps: (record) => ({
                disabled: record.disabled
            })
            } : undefined // Instead of null, use undefined
        }
        pagination={{pageSize: limit, hideOnSinglePage:true}}
    />

}

export default TableUsers;
