import React, { useState } from 'react';
import { InputNumber, Button, Typography, Space } from 'antd';
import CopyToClipboard from "../buttons/copyToClipboard";
import {colors} from "../../assets/data/colors";

const { Text } = Typography;

const generatePassword = (length: number): string => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+[]{}|;:,.<>?';
    let password = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    return password;
};

const PasswordGenerator: React.FC = () => {
    const [length, setLength] = useState<number>(12); // Default length is 12 characters
    const [password, setPassword] = useState<string>(generatePassword(12));

    const handleGenerate = () => {
        setPassword(generatePassword(length));
    };

    return <>
        <p style={{backgroundColor: colors.gray.background, padding: 25}}>
            <Text strong>Description:</Text> Enter the desired number of characters (minimum 4) and click "Generate Password" to create a strong password.
        </p>
        <Space direction="horizontal" size="large" style={{ width: '100%', padding: '20px', marginLeft: '15%', marginBottom: 25 }}>
            <InputNumber
                min={4}
                max={100}
                value={length}
                onChange={(value) => setLength(value || 4)}
                style={{ width: '200px' }}
                placeholder="Enter password length"
            />
            <Button type="primary" onClick={handleGenerate}>
                Generate Password
            </Button>
            <CopyToClipboard text={password}/>

        </Space>
        <div style={{padding: 25}}>
            <Text>{password}</Text>
        </div>
    </>
};

export default PasswordGenerator;
