import React from "react";
import {
    BarChartOutlined, CalendarOutlined,
    PlayCircleOutlined, DashboardFilled, SettingOutlined, TagFilled,
    CheckSquareFilled, FolderOpenOutlined, RadarChartOutlined, HddOutlined,
    HomeOutlined, TeamOutlined, UserOutlined, ToolOutlined,
    KeyOutlined, ProjectOutlined, BellOutlined, BankOutlined
} from "@ant-design/icons";

/*
    fetch Projects
    fetch user role
*/

export type sideMenuItems = {
    label: string,
    href?: string,
    icon: JSX.Element,
    has_access: boolean,
    children?: {
        label: string,
        icon?: JSX.Element,
        href: string
    }[]
}


export const projectMenuItems: sideMenuItems[] = [
    {
        label: 'Overview',
        href: 'overview',
        icon: <DashboardFilled/>,
        has_access: true
    },
    {
        label: 'Test Review', 
        href: 'test-reviews',
        icon: <CheckSquareFilled />,
        has_access: true
    },
    {
        label: 'Run',
        href: 'run',
        icon: <PlayCircleOutlined />,
        has_access: true
    },
    {
        label: 'Test Suite', 
        href: 'test-suite',
        icon: <FolderOpenOutlined />,
        has_access: true
    },
    {
        label: 'Components',
        href: 'components',
        icon: <TagFilled/>,
        has_access: true
    },
    {
        label: 'Calendar', 
        href: 'calendar',
        icon: <CalendarOutlined />,
        has_access: true
    },
    {
        label: 'Dashboard',
        href: 'dashboards',
        icon: <BarChartOutlined />,
        has_access: true
    },
    {
        label: 'Manage',
        icon: <SettingOutlined />,
        has_access: true,
        children: [{
            label: 'Settings',
            href: 'manage/settings'
        }, {
            label: 'Users',
            icon: <TeamOutlined />,
            href: 'manage/users'
        }, {
            label: 'Jobs',
            href: 'manage/jobs'
        }, {
            label: 'Usage',
            icon: <RadarChartOutlined />,
            href: 'manage/usage'
        }, 
        {
            label: 'Arguments',
            icon: <ToolOutlined />,
            href: 'manage/args_scripts'
        }, {
            label: 'Logs',
            icon: <HddOutlined />,
            href: 'manage/logs'
        }]
    }
]

export const teamMenuItems: sideMenuItems[] = [
    {
        label: 'Wall',
        href: 'wall',
        icon: <HomeOutlined />,
        has_access: true
    },{
        label: 'Members',
        href: 'members',
        icon: <TeamOutlined />,
        has_access: true
    },
    {
        label: 'Manage',
        icon: <SettingOutlined />,
        has_access: true,
        children: [{
            label: 'Settings',
            href: 'settings',
            icon: <SettingOutlined />
        }]}
]

export const userMenuItems: sideMenuItems[] = [{
    label: 'Profile',
    href: 'profile',
    icon: <UserOutlined />,
    has_access: true
},{
    label: 'My Projects',
    href: 'projects',
    icon: <ProjectOutlined />,
    has_access: true
},{
    label: 'My Teams',
    href: 'teams',
    icon: <TeamOutlined />,
    has_access: true
},{
    label: 'My Notifications',
    href: 'notifications',
    icon: <BellOutlined />,
    has_access: true
},{
    label: 'My Settings',
    href: 'settings',
    icon: <SettingOutlined />,
    has_access: true
},{
    label: 'My Organizations',
    href: 'organizations',
    icon: <BankOutlined />,
    has_access: true
},{
    label: 'My Tools',
    href: 'tools',
    icon: <ToolOutlined />,
    has_access: true
}
]

export const orgMenuItems: sideMenuItems[] = [{
    label: 'Overview',
    href: 'overview',
    icon: <DashboardFilled />,
    has_access: true
},{
    label: 'Projects',
    href: 'projects',
    icon: <ProjectOutlined />,
    has_access: true
},{
    label: 'My Runs',
    href: 'test-reviews',
    icon: <CheckSquareFilled />,
    has_access: true
},{
    label: 'Users',
    href: 'users',
    icon: <TeamOutlined />,
    has_access: true
},{
    label: 'Components',
    href: 'components',
    icon: <TagFilled/>,
    has_access: true
},{
    label: 'Teams',
    href: 'teams',
    icon: <TeamOutlined />,
    has_access: true
},
{
    label: 'Manage',
    icon: <SettingOutlined />,
    has_access: true,
    children: [{
        label: 'Settings',
        href: 'manage/settings',
        icon: <SettingOutlined/>,
    },{
        label: 'Permissions',
        href: 'manage/permissions',
        icon: <KeyOutlined/>
    },{
        label: 'Jobs',
        href: 'manage/jobs',
        icon: <TagFilled/>,
    },{
        label: 'Logs',
        icon: <HddOutlined />,
        href: 'manage/logs',
    }]}
]
