import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppContext} from '../../AppContext';
import { useNavigate} from 'react-router-dom';
import {AppDispatch} from '../../redux/store';
import { Form, Input, Button } from 'antd';
import {User} from '../../models/entities/user';
import {googleLogin, loginUser, signUpUser} from '../../redux/actions/auth_actions';
import axios from 'axios';
import {TokenResponse, useGoogleLogin} from '@react-oauth/google';
import { UserOutlined, LockOutlined, GoogleOutlined } from '@ant-design/icons';
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";


interface UserFound {
    user: User;
    token: string;
}

// Declaration merging for the global Window interface to include the Google object
declare global {
    interface Window {
        google?: any; // Make 'google' an optional property
    }
}

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string>('');
    const dispatch: AppDispatch = useDispatch();
    const {setUserToken, setAppContextUser, resetApp} = useAppContext();
    const [user, setUser] = useState<TokenResponse>();

    const login = useGoogleLogin({
        onSuccess: (codeResponse: TokenResponse) => {
            // Transform TokenResponse into User object
            setUser(codeResponse);
        },
        onError: (error) => {
            // console.log('Login Failed:', error);
        }
    });

    useEffect(() => {
        const passwordGen: string = Array.from({length: 8}, () => ["abcdefghijklmnopqrstuvwxyz", "ABCDEFGHIJKLMNOPQRSTUVWXYZ", "!@#$%^&*()_+-=[]{}|;:,.<>?"][Math.floor(Math.random() * 3)][Math.floor(Math.random() * 26)]).join('');

        if (user) {
            axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
                .then((res) => {
                        dispatch(googleLogin(res.data.email)).then((userFound: any) => {
                            if (userFound === 290) {
                                // User not found, proceed to create a new user
                                dispatch(signUpUser({
                                    name: res.data.given_name, lastname: res.data.family_name, email: res.data.email,
                                    password: passwordGen
                                })).then((found) => {
                                    setAppContextUser(found.new_user);
                                    setUserToken(found.token);
                                    navigate(`/`)
                                }); // Assuming you have a function createUser to handle user creation
                            } else {
                                setAppContextUser(userFound.user);
                                setUserToken(userFound.token);
                                navigate(`/`);
                            }
                        }).catch((err) => {
                            resetApp()
                            safeHandleErrorResponse(err)
                        });
                    }
                )
                .catch((err) => {
                    safeHandleErrorResponse(err)
                    }
                );
        }
    }, [user]);

    const handleSignUp = (e: React.FormEvent) => {
        e.preventDefault();
    };

    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(loginUser(email, password))
            .then((userFound: UserFound) => {

                setAppContextUser(userFound.user);
                setUserToken(userFound.token);
                navigate(`/`);
            })
            .catch((err) => {
                setError(err);
                resetApp()
                safeHandleErrorResponse(err)
            });
    };

    return <>
        <Form
            onFinish={handleSignUp}
            style={{ width: 400 }} // Ensure the form takes the full width
        >
            <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your Email!' }]}
            >
                <Input
                    size={'large'}
                    prefix={<UserOutlined />}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
            >
                <Input.Password
                    size={'large'}
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Form.Item>
            {error !== '' ? <p style={{ color: 'red' }}>{error}</p> : null}
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleLogin}
                    disabled={email.trim() === '' || password.trim() === ''}
                >
                    Sign In
                </Button>
            </Form.Item>
            <Form.Item>
                <Button
                    type="default"
                    onClick={() => login()}
                    icon={<GoogleOutlined />}
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                >
                    <span style={{ marginLeft: '10px' }}>Login with Google</span>
                </Button>
            </Form.Item>
        </Form>

    </>
};

export default Login;
