import React, {Dispatch, SetStateAction, useMemo} from 'react';
import {Select} from "antd";
// import {SizeType} from "antd/es/config-provider/SizeContext";
import debounce from "lodash/debounce";
import {SelectProps} from "rc-select/lib/Select";

interface selectProps {
    style?: React.CSSProperties,
    selectedOptions: string[],
    setSelectedOptions: Dispatch<SetStateAction<string[]>>,
    options: SelectProps[],
    setSearch: Dispatch<SetStateAction<string>>
    // size: SizeType
}


export function TPSelectMultiple({selectedOptions, setSelectedOptions, style, options, setSearch}: selectProps): JSX.Element {
    /*
    * Default with backend Search
    * */

    const debouncedSetSearch = useMemo(() => debounce((input) => {
        setSearch(input);
    }, 500), []);

    return <Select
        mode="multiple"
        size='large'
        placeholder="Type to search .."
        optionLabelProp="label"
        filterOption={false}

        style={style}

        value={selectedOptions}
        onChange={setSelectedOptions}
        options={options}
        onSearch={debouncedSetSearch}
    />
}