import {TestCaseLogs} from "../../models/entities/test_case_info";


export const test_case_logs: TestCaseLogs = {
    id: 1,
    case_id: 1,
    logs: [{
        log_id: 1,
        run_id: 1,
        log: 'Test Passed Successfully',
        execution_time: 302,
        logged_at: 1685386368,
        owner: 'Panos Pantazos',
        status: 'Passed'
    }, {
        log_id: 2,
        run_id: 2,
        log: 'Test Failed',
        execution_time: 310,
        owner: 'Panos Pantazos',
        logged_at: 1685382368,
        status: 'Failed'
    }, {
        log_id: 3,
        run_id: 3,
        log: 'Test Passed Successfully',
        execution_time: 350,
        owner: 'Panos Pantazos',
        logged_at: 1685385368,
        status: 'Passed'
    }, {
        log_id: 4,
        run_id: 4,
        log: 'Test Passed Successfully',
        execution_time: 600,
        owner: 'Panos Pantazos',
        logged_at: 1685385368,
        status: 'Passed'
    }]
}
