import React, {useState} from 'react';
import { Menu } from 'antd';

type menuOptionsType = {
    items: any,
    defaultSelectedKeys?: any,
    mode?: "horizontal" | "inline",
    style?: any,
    theme?: "dark",
    key?: string
}


function MenuC(menu_options:menuOptionsType): JSX.Element {
    const [selected, setSelected] = useState<any>(menu_options.defaultSelectedKeys?menu_options.defaultSelectedKeys:['0'])

    return(
    <Menu mode={menu_options.mode}
          style={menu_options.style}
          items={menu_options.items}
          selectedKeys={selected}
          onSelect={(e)=>setSelected(e.selectedKeys)}
          // defaultSelectedKeys={menu_options.defaultSelectedKeys?menu_options.defaultSelectedKeys:['0']}
          theme={menu_options.theme}
          key={menu_options.key}
          />)

}
export default MenuC;