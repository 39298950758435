import React, { useEffect, useState } from 'react';
import {Dropdown, Input, Space, Table,} from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import ButtonC from '../../components/button';
import CancelAction from '../../components/notification/cancel_req';
import { ArgumentScriptSetting } from '../../models/entities/settings';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { deleteRunScript, getProjectRunScripts } from '../../redux/actions/project_actions';
import { useAppContext } from '../../AppContext';
import { empty_argument } from '../../services/EmptyEntities/EmptyArgs';
import SystemScripts from '../modals/system_scripts_modal';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const { Search } = Input;


function SysScripts(): JSX.Element{
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg, currentProject} = useAppContext();
    const org_id = currentOrg.id
    const project_id = currentProject.id

    const params = {org_id, project_id}

    const [visible, setVisible] = useState<boolean>(false);
    const [checkedScripts, setCheckedScripts] = useState<any[]>([])
    const [savedScripts, setSavedScripts] = useState<ArgumentScriptSetting[]>([]);
    const [selectedScript, setSelectedScript] = useState<ArgumentScriptSetting | undefined>(empty_argument);
    const [search, setSearch] = useState<string>('')

    const selected_slacks: number = checkedScripts ? checkedScripts.length : 0

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getProjectRunScripts(params)).then(scripts => {
                setSavedScripts(scripts.content);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            });
        }
        fetchData();
    }, [dispatch]);
    
    const handleDeleteDefaultScripts = () => {
    
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            ids: checkedScripts}
    
            dispatch(deleteRunScript(params)).then(() => {
                let updatedList = savedScripts.filter((item => !checkedScripts.includes(item.id)));
                setSavedScripts(updatedList);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Default',
            dataIndex: 'default',
            key: "default",
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: "active",
        },
        {
            title: 
                <>  
                <Dropdown
                    disabled={selected_slacks === 0}
                    menu={{
                        items: [{
                            key: `mass-delete`,
                            label: <CancelAction secs={2} action={handleDeleteDefaultScripts}><DeleteOutlined /> Delete</CancelAction>,
                        }]
                    }}
                >
                    <a>
                        <Space>
                            Actions <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                <div style={{fontSize: 11}}>selected {selected_slacks}</div></>,
            dataIndex: 'actions',
            key: 'actions'
        }
    ];

    const fetchData = async () => {
        dispatch(getProjectRunScripts({org_id, project_id, search})).then(scripts => {
            setSavedScripts(scripts.content);
    }).catch((err) => {
        safeHandleErrorResponse(err)
    })}

    return (
        <>
            <Search
                style={{marginBottom: 10, width: 250}}
                placeholder="Search for system script"
                onSearch={fetchData}
                value={search}
                onChange={(value)=>setSearch(value.target.value)}
                />
            <Table
                pagination={{pageSize: 10, hideOnSinglePage:true}}
                rowSelection={{
                    selectedRowKeys: checkedScripts,
                    onChange: (keys, rows) => {
                        setCheckedScripts(keys)
                    }
                }}
                dataSource={savedScripts && savedScripts.map((script, index) => ({
                    key: script.id,
                    name: script.title,
                    default: script.default_value,
                    active: script.status === 1 || script.status === true ? 'Yes' : 'No',
                    actions: <><EditOutlined onClick={() => {setVisible(true); setSelectedScript(script)}}/> Edit </>
                }))}
                key='system_scripts'
                columns={columns}
            />
            <ButtonC type={"primary"} onClick={()=>setVisible(true)} text={'Add New'} style={{marginTop: 10}}/>
            <SystemScripts visible={visible} setVisible={setVisible} 
                           sysScripts={savedScripts} setSysScripts={setSavedScripts}
                           selectedScript={selectedScript} setSelectedScript={setSelectedScript}/>
        </>
    )
}

export default SysScripts;
