import {PageResource} from "../../models/dtos/page_resource";
import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import {TestCase} from "../../models/entities/test_case_info";
import {Component, ComponentDD} from "../../models/entities/component";
import {segment_period} from "../../models/entities/time";
import {LandingProject} from "../../models/entities/project";
import {get, post, put, deletion} from '../../services/axiosWrapper';

const api_path = '/api/case/';
// const section_id = 1;

type getCasesParams = {
    project_id:number,
    section_ids?:React.Key[],
    search?:string,
    case_id?:number
}

export const getCases = (params: getCasesParams) => (dispatch: AppDispatch): Promise<PageResource<TestCase>> => {
    /**
     * project_id: required
     * search: optional to filter cases
     *
     * Use on of these
     * section_ids: get cases for specific sections
     * case_id: get specific case
     */

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getCases`, params)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const getCase = (case_id:number) => (dispatch: AppDispatch): Promise<TestCase> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getCase?case_id=${case_id}`)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const addCase = (data:any)=> (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}addCase`, data)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data.content;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteCase = (case_id:number) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    const request: any = deletion(`${api_path}deleteCase?case_id=${case_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getCaseComponents = (case_id:number) => (dispatch: AppDispatch): Promise<Component[]> => {
    dispatch(fetchStartAction());
    const request: any = get(`${api_path}getCaseComponents?case_id=${case_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteCases = (project_id:number, case_ids:React.Key[]) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    const request: any = deletion(`${api_path}deleteCases?project_id=${project_id}&case_ids=${case_ids}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}


// export const deleteCases = (data: {project_id:number, case_ids:React.Key[]}) => (dispatch: AppDispatch): Promise<any> => {
//     dispatch(fetchStartAction());
//     // Create a request config object with data as a query parameter
//     const requestConfig: AxiosRequestConfig = {
//         method: 'delete',
//         url: `${api_path}deleteCases`,
//         data: data, // Pass your data here as the request body
//     };

//     return axios(requestConfig)
//         .then((response) => {
//             dispatch(fetchSuccessAction());
//             return response.data; // Return the response data
//         })
//         .catch((error) => {
//             dispatch(fetchErrorAction(error));
//             return Promise.reject(error);
//         });
// };

export const getNewCasesStats = (id:number, id_type:'org'|'project', period:segment_period) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    // Create a request config object with data as a query parameter
    const request: any = get(`${api_path}getNewCasesStats?project_id=${id}&id_type=${id_type}&period=${period}`)

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response.data; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
}

export const getCasesStats = (id:number, id_type:'org'|'project') => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    // Create a request config object with data as a query parameter
    const request: any = get(`${api_path}getCasesStats?project_id=${id}&id_type=${id_type}`)

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response.data[0]; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
}

export const moveCases = (data: {project_id:number, section_id:number, case_ids:number[]}) => (dispatch: AppDispatch): Promise<PageResource<LandingProject>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}moveCases`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateCase = (data: {project_id:number, id:number, title?:string, priority?: number, description?: string, steps?: string[], components?: ComponentDD[], org_id?: number}) => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateCase`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateCaseComponents = (data:{id:number, project_id:number, org_id:number, components_ids:number[] | ComponentDD[]})=> (dispatch: AppDispatch): Promise<PageResource<LandingProject>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}updateCaseComponents`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
