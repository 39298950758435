import React from 'react';
import {Card, Col, Row, Statistic} from 'antd';
import {secondsToTime} from '../../assets/helpers/helper';
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import {TestCase} from '../../models/entities/test_case_info';
import { colors } from '../../assets/data/colors';

type TestSuiteCaseStatsProps = {
    openedInfo: TestCase
}

function TestSuiteCaseStats({openedInfo}: TestSuiteCaseStatsProps): JSX.Element {

    const statsSpan2 = 12;
    const statsSpan3 = 8;
    const statsSpan4 = 6;

    return <div style={{textAlign: 'center'}}>
        <Row gutter={16}>
            <Col span={statsSpan2}>
                <Card>
                    <Statistic
                        title="Created At"
                        value={openedInfo.createAt}
                    />
                </Card>
            </Col>
            <Col span={statsSpan2}>
                <Card>
                    <Statistic
                        title="Creator"
                        value={`${openedInfo.creator.name} ${openedInfo.creator.lastname}`}
                    />
                </Card>
            </Col>
        </Row>
        <Row style={{marginTop: 16}} gutter={16}>
            <Col span={statsSpan2}>
                <Card>
                    <Statistic
                        title="Estimation (HH:MM:SS)"
                        value={secondsToTime(openedInfo.estimation)}
                    />
                </Card>
            </Col>
            <Col span={statsSpan2}>
                <Card>
                    <Statistic
                        title="Defects"
                        value={openedInfo.defects}
                    />
                </Card>
            </Col>
        </Row>
        <Row style={{marginTop: 16}} gutter={16}>
                <Col span={statsSpan2}>
                <Card>
                    {
                        openedInfo.pass_rate ?
                            <Statistic
                                title="Passing Rate"
                                value={openedInfo.pass_rate*100}
                                precision={1}
                                valueStyle={{color: colors.green.bamboo}}
                                // from setting set the default up/no/down arrows
                                prefix={<ArrowUpOutlined/>}
                                suffix="%"
                            />
                            : <Statistic
                                title="Passing Rate"
                                value="-"
                            />
                    }

                </Card>
            </Col>
            <Col span={statsSpan2}>
                <Card>
                    {
                        openedInfo.pass_rate ?
                            <Statistic
                                title="Failing Rate"
                                value={openedInfo.pass_rate}
                                precision={0}
                                valueStyle={{color: colors.red.vermilion}}
                                // get latest log to compare for arrow up/down colors etc
                                prefix={<ArrowDownOutlined/>}
                                suffix="%"
                            />
                            : <Statistic
                                title="Failing Rate"
                                value="-"
                            />
                    }

                </Card></Col>
            {/* <Col span={statsSpan2}>
                <Card>
                    {
                        openedInfo.pass_rate ?
                            <Statistic
                                title="Passing Rate"
                                value={openedInfo.pass_rate}
                                precision={0}
                                valueStyle={{color: '#3f8600'}}
                                // get latest log to compare for arrow up/down colors etc
                                prefix={<ArrowUpOutlined/>}
                                suffix="%"
                            />
                            : <Statistic
                                title="Passing Rate"
                                value="-"
                            />
                    }

                </Card></Col> */}
            {/* <Col span={statsSpan4}>
                <Card>
                    {
                        openedInfo.pass_rate ?
                            <Statistic
                                title="Passing Rate"
                                value={openedInfo.pass_rate}
                                precision={0}
                                valueStyle={{color: '#3f8600'}}
                                // get latest log to compare for arrow up/down colors etc
                                prefix={<ArrowUpOutlined/>}
                                suffix="%"
                            />
                            : <Statistic
                                title="Passing Rate"
                                value="-"
                            />
                    }

                </Card></Col>
            <Col span={statsSpan4}>
                <Card>
                    {
                        openedInfo.pass_rate ?
                            <Statistic
                                title="Passing Rate"
                                value={openedInfo.pass_rate}
                                precision={0}
                                valueStyle={{color: '#3f8600'}}
                                // get latest log to compare for arrow up/down colors etc
                                prefix={<ArrowUpOutlined/>}
                                suffix="%"
                            />
                            : <Statistic
                                title="Passing Rate"
                                value="-"
                            />
                    }

                </Card></Col> */}
        </Row>
    </div>


}

export default TestSuiteCaseStats;