import axios from "axios";
import {get_item_from_lcs, LcsKeys} from '../assets/local_storage/lcs'


const get_header = () => {
    const token = get_item_from_lcs(LcsKeys.user_token)

    return {
        Authorization: `Bearer ${token}`,
        contentType: 'application/json'
    }
}


export const get = (url, params) => {

    const config = {
        headers: get_header(),
        params
    }

    return axios.get(url, config)
}

export const post = (url, data, params) => {

    const config = {
        headers: get_header(),
        params
    }

    return axios.post(url, data, config)
}

export const put = (url, data, params) => {

    const config = {
        headers: get_header(),
        params
    }

    return axios.put(url, data, config)
}

export const deletion = (url, params) => {

    const config = {
        headers: get_header(),
        params
    }

    return axios.delete(url, config)
}
