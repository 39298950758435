import React, { useMemo } from "react";
import {VictoryPie} from 'victory';
import {Pie} from '@ant-design/plots';


function VicPie(colors: string[], chart_data: object[]): JSX.Element {

    return <VictoryPie
        colorScale={colors}
        data={chart_data}
        innerRadius={100}
        animate={{
            duration: 2000
        }}
        labelPlacement='perpendicular'
    />

}

interface PieCProps {
    data: { type: string; value: number }[],
    colors?: string[],
    style?: React.CSSProperties,
    innerRadius?: number,
    suffix?: string
}

export const MemoPie = React.memo<PieCProps>(({colors, data, suffix, innerRadius, style}) => {

    const contentFunction = useMemo(() => {
        return (labelData: any) => {
            return labelData.value ? `${labelData.value}${suffix ? suffix : ''}` : '';
        };
    }, [suffix]);

    const default_config = {
        appendPadding: 0,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: innerRadius !==undefined? innerRadius: 0.64,
        color: colors,
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
            content: contentFunction,
        },
        statistic: {
            title: {
                offsetY: -4,
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: '32px',
                },
            },
        },

        interactions: [
            {type: 'element-selected'},
            {type: 'element-active'},
            {type: 'pie-statistic-active'},
        ]
    }
    return <Pie style={style} data={data} {...default_config} />;
});

export default VicPie;