import React from 'react';
// import Text from "../intl/text";
import { Button } from 'antd';
import { ArrowLeftOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

type PageHeaderProps = {
    title: React.ReactNode;
    hasPermissions?: boolean;
    headerButton?: React.ReactNode,
    buttonHeader?: {
        type: "create" | "edit" | "nav" | "back"; 
        onClickFunction: ()=>any,
        style?: {},
        title?: string,
        icon?: React.ReactNode
    } | null;
    editButton?: {
        onClickFunction: ()=>any;
        style?: {};
        title?: string;
    };
}

function PageHeader(props: PageHeaderProps): JSX.Element {
    let {title, hasPermissions, buttonHeader, headerButton} = props;

    const has_permission = () => {
        if(hasPermissions === undefined){
            hasPermissions = true
        }
        return hasPermissions
    }

    return (
            <h2>{title} &nbsp;
            {headerButton? headerButton
                    : buttonHeader?
                        <Button onClick={buttonHeader.onClickFunction}
                                shape="circle"
                                size="small"
                                icon={buttonHeader.icon?buttonHeader.icon:buttonHeader.type==="create"
                                    ? <PlusOutlined />
                                    : buttonHeader.type=== "nav"? <Link to={buttonHeader.onClickFunction()}><PlusOutlined /></Link>
                                    : buttonHeader.type=== "back"? <ArrowLeftOutlined />
                                        :<EditOutlined />}
                          title={buttonHeader.title? buttonHeader.title : "New"}
                        > 
                     </Button>
                : null}
            </h2>
    )
}

export default PageHeader;
