import { Divider } from 'antd';
import React from 'react';
import RowC from './row';

type SettingRowProps = {
    section_id: string,
    divider_title?: string,
    settings: {
        title: React.ReactNode,
        value: React.ReactNode,
        description?: React.ReactNode
    }[]
  };

function SettingSection({section_id, divider_title, settings}:SettingRowProps): JSX.Element {
    const settings_style:React.CSSProperties = {
        width:'100%', 
        float:'left',
        marginTop: divider_title? 20: 5
    }

    return (
    <div id={section_id} style={settings_style}>
        {
            divider_title? <Divider orientation="left">{divider_title}</Divider>: null
        }
        {
            settings.map((setting, key)=>{ return (
                <div key={key} style={{marginTop:10}}>
                    <RowC>
                        <div className='project-setting-container'>
                            <div className='project-setting-title'> 
                                <h3>{setting.title}</h3> 
                                <h4 style={{paddingRight:20, paddingLeft:5}}> {setting.description} </h4>
                            </div>
                            <div className='project-setting-value'> {setting.value} </div>
                        </div>
                    </RowC>
                </div>
            
            )})
        }
        {
            divider_title? null: <Divider/>
        }
    </div>
    )
}
  
export default SettingSection;
