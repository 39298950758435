import React, {useEffect, useState} from 'react';
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {
    getAddProjectSettingsActions, getProjectRunArgs
} from "../../redux/actions/project_actions";
import {useAppContext} from "../../AppContext";
import {ArgumentScriptSetting} from "../../models/entities/settings";
import {Col, Steps, FloatButton, Tabs, notification} from "antd";
import RowC from "../../components/row";
import RunConfigSettings from "./run_config_settings";
import RunConfigArgs from "./run_config_args";
import {DataNode} from "antd/lib/tree";
import {getSections} from "../../redux/actions/section_actions";
import {
    FolderOpenOutlined,
    PlayCircleOutlined,
    TagFilled
} from "@ant-design/icons";
import TestSuite from "../test_suite/test_suite";
import RunComplete from "./run_complete";
import {colors} from '../../assets/data/colors'
import Dropdown from "antd/lib/dropdown";
import {Component} from "../../models/entities/component";
import { checked_section, SuiteCases} from "../../models/entities/suite_section_props";
import {getComponents} from "../../redux/actions/component_actions";
import {PageResource} from "../../models/dtos/page_resource";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import Components from "../components/components";
import {createNewTestRun} from "../../redux/actions/run_actions";
import dayjs, {Dayjs} from "dayjs";
import RunScripts from "./run_scripts";
import type { Moment } from 'moment';
import { RunSlacks, RunTeams } from '../../models/entities/run_script_notifications';
import TimeHandler from "../../assets/helpers/timeHandler";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

export function Run(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {currentProject, currentOrg, loggedInUser} = useAppContext()

    const [runTitle, setRunTitle] = useState<string>('New Run');  // Set this per project configuration
    const [githubBranch, setGithubBranch] = useState<string>('');  // Set this per project configuration

    // navigation
    const [step, setStep] = useState<number>(0);
    const [caseTab, setCaseTab] = useState<string>("1");

    // Step1 state settings
    const [runOption, setRunOption] = useState<'Now'| 'Manually'| 'Scheduled'>('Now');
    const [recurringOption, setRecurringOption] = useState<'Day'| 'Week'| 'Month'| 'Quarter'| 'Custom'>('Week');
    const [runDate, setRunDate] = useState<Dayjs | null>(dayjs());
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
    const [runRecurringDate, setRunRecurringDate] = useState<Moment | null>(null);
    const [runRecurringWeeklyDate, setRunRecurringWeeklyDate] = useState<string | null>(null);
    const [runRecurringMonthlyDate, setRunRecurringMonthlyDate] = useState<string | null>(null);
    const [runRecurringQuarterlyDate, setRunRecurringQuarterlyDate] = useState<string | null>(null);
    const [isRecurring, setIsRecurring] = useState<boolean>(false);
    const [endRecurring, setEndRecurring] = useState<boolean>(false);
    const [maxParallel, setMaxParallel]  = useState<number>(1);
    const [selectedBrowsers, setSelectedBrowsers]  = useState<string[]>(['Chrome']);
    const [sequence, setSequence]  = useState<'Continuously' | 'In Parallel'>('Continuously');
    const [runInterval, setRunInterval]  = useState<number>(0);
    const [useMaxParallel, setUseMaxParallel]  = useState<boolean>(maxParallel === -1);
    const [reruns, setReruns] = useState<number>(0);
    const [args, setArgs] = useState<ArgumentScriptSetting[]>([])
    const [systemNotifications, setSystemNotifications] = useState<{id: number, status: boolean; title: string;}[]>([]);
    const [scripts, setScripts] = useState<ArgumentScriptSetting[]>([]);
    const [slackNotifications, setSlackNotifications] = useState<RunSlacks[]>([]);
    const [teamNotifications, setTeamNotifications] = useState<RunTeams[]>([]);

    // Select Cases
    const [treeData, setTreeData] = useState<DataNode[]>([])
    const [checkedSections, setCheckedSections] = useState<checked_section>({checked: [], halfChecked: []}) // not sure about this default state
    const [checkedCases, setCheckedCases] = useState<SuiteCases>({}) // not sure about this default state

    const [componentPage, setComponentPage] = useState<PageResource<Component>>(createEmptyPage())
    const [checkedComponents, setCheckedComponents] = useState<React.Key[]>([]) // not sure about this default state

    const [forceDisableButton, setForceDisableButton] = useState<boolean>(false)
    const checkedCasesIds = [].concat(...Object.values(checkedCases))

    const handleCreateRun = () =>{
        let data:any = {
            info:{
                project_id: currentProject.id,
                title: runTitle,
                githubBranch,
                browsers: selectedBrowsers,
                maxParallel,
                total_runs: reruns + 1,
                sequence: sequence === 'Continuously' ? {sequence: sequence} : {sequence: sequence, runInterval: runInterval},
                creator_id: loggedInUser.id,
                schedule_for: runOption === 'Manually' ? null : runOption === 'Now'? TimeHandler.getDateToSend() : TimeHandler.getDatejsToSend(runDate),
                status: runOption === 'Manually'? 0: 3,
                runOptions:{
                    runDate: isRecurring? TimeHandler.getDatejsToSend(endDate): TimeHandler.getDatejsToSend(runDate),
                    option: recurringOption,
                    day: runRecurringWeeklyDate,
                    month: runRecurringMonthlyDate,
                    quarter: runRecurringQuarterlyDate
                },
                until: endRecurring ? TimeHandler.getDatejsToSend(endDate) : null
            },
            args,
            scripts: scripts,
            notifications: {system: systemNotifications, slack: slackNotifications, team: teamNotifications},
            selection:{
                sections: checkedSections.checked,
                components: checkedComponents,
                cases: checkedCasesIds
            }

        }

        setForceDisableButton(true)
        dispatch(createNewTestRun(data))
            .then((response:any)=>{
                if(response.success === false){
                    notification.error({
                        message: 'Empty Combination',
                        description: response.err,
                    });
                }else{
                    setCheckedSections({checked:[], halfChecked:[]})
                    setCheckedCases({})
                    setCheckedComponents([])
                }
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })

        setTimeout(() => {
            setForceDisableButton(false)
        }, 2000);
    }

    const items: ItemType[] = [
        { key: 1, label: 'Create', onClick: handleCreateRun},
        { key: 2, label: 'Save Template'},
        { key: 3, label: 'Save Template & Create'},
        { key: 4, label: 'Preview', onClick:()=>{setStep(4)}}
    ]

    useEffect(() =>{
        try {
            // get  defaults from appContext
            dispatch(getAddProjectSettingsActions({project_id: currentProject.id}))
                .then((settings)=> {
                    setGithubBranch(settings.branch)
                    setReruns(settings.runs_number)
                    setMaxParallel(settings.parallels)
                    setUseMaxParallel(settings.parallels === -1)
                })
                .catch((err) => {
                    safeHandleErrorResponse(err)
                })
        } catch (err) {
            // Handle error
        }

            dispatch(getSections(currentOrg.id, currentProject.id))
                .then(foundPage => setTreeData([{title: currentProject.name,
                    key: -currentProject.id,
                    children: foundPage,
                    selectable:true}])).catch((err) => {
                        safeHandleErrorResponse(err)
                    })

        const params = {
            org_id: currentOrg.id,
            project_id: currentProject.id
        }
            
        dispatch(getComponents(params)).then(foundPage => {
                setComponentPage(foundPage)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getProjectRunArgs({project_id: currentProject.id, org_id:currentOrg.id}))
            .then(args=>{setArgs(args.content)}).catch((err) => {
                safeHandleErrorResponse(err)
            })

    }, [dispatch])


    const handleSetGithubBranch = (e:any)=>{
        setGithubBranch(e.target.value)
    }

    const handleSetMaxParallel = (e: number|null) => {
        if(e){ setMaxParallel(e) }
    }

    const handleBrowserSelect = (e:string[]) => {
        if(e){ setSelectedBrowsers(e) }
    }

    const handleChangeReruns = (e:number|null) => {
        setReruns(e?e:0)
    }

    const select_tab_items = [{
        label: <span><FolderOpenOutlined /> Sections & Cases </span>,
        key: "1",
        children:
            <TestSuite
                title={'Select Cases'}
                sectionProps={{
                    sections:{
                        treeData,
                        setTreeData,
                        checkbox:{
                            checkedSections,
                            setCheckedSections
                        }
                    },
                }}
                casesProps={{
                    casesOptions:{
                        checkedCases,
                        setCheckedCases
                    }
                }}
                options={{useModal: false}}
            />

    },{
        label: <span><TagFilled/> Components </span>,
        key: "2",
        children: <Components
            componentPage={componentPage}
            checkbox={{checkedComponents,setCheckedComponents}}
        />
    }]

    const get_step_progress = (rules:boolean[]) => {
        const trueCount = rules.filter((rule) => rule).length
        return (trueCount/rules.length)*100
    }
    const all_rules:boolean[] = [

        runTitle !== "",
        githubBranch !== "",
        selectedBrowsers.length>0,
        runOption === "Scheduled" && recurringOption !== 'Week' && recurringOption !== 'Month' && recurringOption !== 'Quarter' ? runDate !== null: true,
        checkedSections.checked?.length>0 ||
        checkedCasesIds.length >0 ||
        checkedComponents.length>0,
        runOption === "Scheduled" && isRecurring && recurringOption === 'Week' ? runRecurringDate !== null : true,
        runOption === "Scheduled" && isRecurring && recurringOption === 'Week' ? runRecurringWeeklyDate !== null : true,
        runOption === "Scheduled" && isRecurring && recurringOption === 'Month' ? runRecurringDate !== null : true,
        runOption === "Scheduled" && isRecurring && recurringOption === 'Month' ? runRecurringWeeklyDate !== null : true,
        runOption === "Scheduled" && isRecurring && recurringOption === 'Quarter' ? runRecurringDate !== null : true,
        runOption === "Scheduled" && isRecurring && recurringOption === 'Quarter' ? runRecurringWeeklyDate !== null : true,
        runOption === "Scheduled" && isRecurring && recurringOption === 'Quarter' ? runRecurringMonthlyDate !== null : true,
        runOption === "Scheduled" && isRecurring && recurringOption === 'Quarter' ? runRecurringQuarterlyDate !== null : true,
        endRecurring ? endDate !== null : true
    ]


    const all_steps:any = {
        0: {
            description: 'Settings',
            percent: get_step_progress([
                all_rules[0],
                all_rules[1],
                all_rules[2],
                all_rules[3]
            ]),
            childes: <RunConfigSettings settings={{
                run: {
                    runOption, setRunOption,
                    runDate,
                    setRunDate,
                    endDate,
                    setEndDate,
                    runRecurringDate,
                    setRunRecurringDate,
                    runRecurringWeeklyDate,
                    setRunRecurringWeeklyDate,
                    runRecurringMonthlyDate,
                    setRunRecurringMonthlyDate,
                    runRecurringQuarterlyDate,
                    setRunRecurringQuarterlyDate,
                    isRecurring,
                    setIsRecurring,
                    endRecurring,
                    setEndRecurring,
                    recurringOption,
                    setRecurringOption
                },
                runTitle, setRunTitle,
                githubBranch, handleSetGithubBranch,
                reruns, handleChangeReruns,
                useMaxParallel, setUseMaxParallel,
                maxParallel, handleSetMaxParallel,
                browser:{
                    handleBrowserSelect,
                    selectedBrowsers,
                    sequence,
                    setSequence,
                    runInterval,
                    setRunInterval
                }
            }}/>
        },
        1: {
            description: 'Arguments',
            percent: 100, // TODO: get % of required fields
            childes: <RunConfigArgs args={{args, setArgs}} />
        },
        2: {
            description: 'Scripts & Notifications',
            percent: 100,
            childes: <RunScripts systemNotifications={systemNotifications} setSystemNotifications={setSystemNotifications} 
                                 scripts={scripts} setScripts={setScripts}
                                 slackNotifications={slackNotifications} setSlackNotifications={setSlackNotifications}
                                 teamNotifications={teamNotifications} setTeamNotifications={setTeamNotifications}/>
        },
        3: {
            description: 'Select Cases',
            percent: get_step_progress([all_rules[4]]),
            childes: <Tabs activeKey={caseTab}
                           onChange={(e)=>setCaseTab(e)}
                           items={select_tab_items}
            />
        },
        4: {
            description: 'Preview',
            percent: get_step_progress(all_rules),
            childes: <RunComplete
                settings={{
                    run: {
                        runDate,
                        endDate,
                        runRecurringDate,
                        isRecurring,
                        runOption,
                        recurringOption,
                        title: runTitle
                    },
                    githubBranch,
                    reruns,
                    maxParallel,
                    handleSetMaxParallel,
                }}
                // args={}
                // scripts={}
                selected={
                    {
                        sections:checkedSections.checked,
                        cases:checkedCasesIds,
                        components:checkedComponents
                    }
                }
            />
        }
    }

    const get_step_items = (steps:any):any[] => {
        return  Object.keys(steps).map((step_item:any, key:any) => {
            return {
                title: `Step ${key+1}`,
                description: steps[step_item].description,
                disabled: steps[step_item].disabled
            }
        })
    }

    return (
        <Page title="Configure New Run">
            <PageHeader
                title={`Configure New Run: ${all_steps[step].description}`}
                headerButton={
                    <>
                        <Dropdown.Button
                            disabled={!all_rules.every((item:boolean)=>item) || forceDisableButton}
                            size={'small'}
                            style={{width: 100, display: 'inline', color:colors.green.forest}}
                            menu={{ items }}
                            onClick={handleCreateRun}
                        >
                            <PlayCircleOutlined style={{color:colors.green.bamboo}}/>
                            <div style={{ margin:5, color:colors.green.bamboo, display: 'inline'}}>Create</div>
                        </Dropdown.Button>
                        {/*<InfoCircleOutlined />*/}
                    </>

                }
            />
            <PageContent>
                <RowC style={{flexFlow:"nowrap"}}>
                    <Col flex='160px'>
                        <Steps
                            current={step}
                            onChange={setStep}
                            // status="error"
                            percent={all_steps[step].percent}
                            direction="vertical"
                            items={get_step_items(all_steps)}
                        />
                    </Col>
                    <Col flex='25px'>

                    </Col>
                    <Col flex='auto'>
                        {all_steps[step].childes}
                    </Col>
                </RowC>
                {
                    step!==4?
                        <FloatButton
                            style={{marginRight:15}}
                            type="primary"
                            tooltip={<div>Templates</div>} />
                        :null

                }
            </PageContent>
        </Page>
    )
}

export default Run;