import React from 'react';
import { Input, QRCode, Space, Button } from 'antd';

function doDownload(url: string, fileName: string) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const downloadCanvasQRCode = () => {
    const canvas = document.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
        const url = canvas.toDataURL('image/png');
        doDownload(url, 'QRCode.png');
    } else {
        console.error("Canvas not found!");
    }
};

const QrCodeTool: React.FC = () => {
    const [text, setText] = React.useState('https://testing-panda.com/');

    return (
        <Space style={{ marginLeft: '28%' }} direction="vertical" align="center">
            <div id="myqrcode">
                <QRCode value={text || '-'} />
            </div>
            <Input
                placeholder="-"
                maxLength={150}
                style={{ width: 350 }}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <Button
                type="primary"
                onClick={downloadCanvasQRCode}
            >
                Download
            </Button>
        </Space>
    );
};

export default QrCodeTool;
