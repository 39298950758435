import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Drawer} from "antd";
import {Tool} from "../../models/entities/user";
import ColorPickerTool from "../tools/colorPicker";
import QrCodeTool from "../tools/qrCodeTool";
import FindIpTool from "../tools/findIpTool";
import LoremIpsumGenerator from "../tools/TextGeneratorTool";
import PasswordGenerator from "../tools/passwordGeneratorTool";
import HighlightDifferences from "../tools/textCompareTool";
import JsonValidatorFormatter from "../tools/jsonValidatorTool";
import UrlDecoder from "../tools/urlDecoderTool";
import TimestampConverter from "../tools/timestampConverterTool";

interface DrawerToolsProps{
    visibleDrawer: boolean,
    setVisibleDrawer: Dispatch<SetStateAction<boolean>>,
    tool: Tool
}

function DrawerTools({tool, visibleDrawer, setVisibleDrawer}: DrawerToolsProps): JSX.Element {
    const [component, setComponent] = useState<React.ReactNode>(<>{tool.id}</>)
    useEffect(()=>{
        if(tool.id === 1){
            setComponent(<HighlightDifferences/>)
        }else if(tool.id === 2){
            setComponent(<LoremIpsumGenerator/>)
        }else if(tool.id === 3){
            setComponent(<TimestampConverter/>)
        }else if(tool.id === 4){
            setComponent(<JsonValidatorFormatter/>)
        }else if(tool.id === 5){
            setComponent(<ColorPickerTool/>)
        }else if(tool.id === 6){
            setComponent(<QrCodeTool/>)
        }else if(tool.id === 7){
            setComponent(<PasswordGenerator/>)
        }else if(tool.id === 8){
            setComponent(<FindIpTool/>)
        }else if(tool.id === 9){
            setComponent(<UrlDecoder/>)
        }
    }, [tool])

    return <Drawer
        title={tool.title}
        open={visibleDrawer}
        size='large'
        onClose={()=>setVisibleDrawer(false)}
        >
        {component}
    </Drawer>
}

export default DrawerTools;