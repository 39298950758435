import React from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";

function Dashboard(): JSX.Element {
    return (
        <Page title="Dashboard">
            <PageHeader title="Dashboards"/>
            <PageContent>
            Dashboard content
            </PageContent>
        </Page>
    )
}
  
export default Dashboard;