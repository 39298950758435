import { WallPosts } from "../../models/entities/team";


export const empty_post:WallPosts = {
    org_id: 0,
    team_id: 0,
    post_text: "",
    post_id: 0,
    poster: {id: 0, img: null, name: "", lastname: ""}
}
