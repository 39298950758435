import React from 'react';
import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';

type ChildComponentProps = {
    label: string;
    onChange:  SwitchChangeEventHandler  | undefined;
    defaultChecked?: boolean;
    size?: 'small' | 'default';
    checkedChildren?: React.ReactNode;
    unCheckedChildren?: React.ReactNode;
    orientation?: 'left' | 'right'
  };


function SwitchC(props: ChildComponentProps): JSX.Element {
    const {label, onChange, size, unCheckedChildren, checkedChildren, defaultChecked} = props
    let {orientation} = props
    orientation = orientation? orientation: 'left'

    return <>
        {
            orientation === 'left'? <label> {label}: </label> : null
        }
        <Switch value={defaultChecked}
                onChange={onChange} 
                size={size}
                checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                />

        {
            orientation === 'right'? <label> &nbsp;{label} </label> : null
        }
    </>

}
export default SwitchC;