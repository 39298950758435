import React from 'react';
import ButtonC from "../button";
import {colors} from "../../assets/data/colors";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

type ButtonsSaveOnEditProps = {
    onClick: any,
    disableSave?: boolean,
    reset: any
}

const ButtonsSaveOnEdit = (props: ButtonsSaveOnEditProps): JSX.Element => {
    const {onClick, reset, disableSave} = props

    return <>&nbsp;
        <ButtonC
            type={'text'}
            style={{color: colors.green.forest}}
            disabled={disableSave}
            onClick={() => {
                onClick()
                reset()
            }}
            icon={<CheckOutlined alt={'Save'}/>}/>
        <ButtonC
            type={'text'}
            style={{color: colors.red.vermilion}}
            onClick={reset}
            icon={<CloseOutlined alt={'Cancel'}/>}/>
    </>
}
export default ButtonsSaveOnEdit;
