import React, { Dispatch, SetStateAction, useState } from 'react';
import ButtonC from "../button";
import { Checkbox, Dropdown, Row, Space, Table, Tooltip} from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CancelAction from '../notification/cancel_req';
import ShareWallNotificationPopup from '../modals/team_wall_notifications_modal';
import { RunTeams } from '../../models/entities/run_script_notifications';
import { empty_team_run } from '../../services/EmptyEntities/EmptyRunTeams';

interface TeamProps {
    teamNotifications: RunTeams[],
    setTeamNotifications: Dispatch<SetStateAction<RunTeams[]>>
}

function TableRunTeams(props: TeamProps): JSX.Element{

    const [visible, setVisible] = useState<boolean>(false);
    // const [runTeams, setRunTeams] = useState<RunTeams[]>([]);
    const [checkedTeams, setCheckedTeams] = useState<any[]>([])
    const [message, setMessage] = useState<any>();
    const [selectedTeam, setSelectedTeam] = useState<RunTeams>(empty_team_run);

    const {teamNotifications, setTeamNotifications} = props


    const selected_teams: number = checkedTeams ? checkedTeams.length : 0

    const icon_style = {color: 'grey', fontSize:18}

    const deleteSelectedCases = () => {
        let new_table = Object.values(teamNotifications);
    
        new_table = new_table.filter(item => !checkedTeams.includes(item.id))
    
        setTeamNotifications(new_table);
        setCheckedTeams([]);
    };

    const columns = [
        {
            title: 'Team',
            dataIndex: 'team',
            key: 'team'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Before',
            children: [
                {
                    title: 'Run Starts',
                    dataIndex: 'run_starts',
                    key: 'run_starts'
                },
                {
                    title: 'Each Run',
                    dataIndex: 'b_each_run',
                    key: 'b_each_run'
                }
            ]
        },
        {
            title: 'After',
            children: [
                {
                    title: 'Each Run',
                    dataIndex: 'a_each_run',
                    key: 'a_each_run'
                },
                {
                    title: 'Run Ends',
                    dataIndex: 'completion',
                    key: 'completion'
                }
            ]
        },
        {
            title: 
                <>  
                <Dropdown
                    disabled={selected_teams === 0}
                    menu={{
                        items: [{
                            key: `mass-delete`,
                            label: <CancelAction secs={2} action={deleteSelectedCases}><DeleteOutlined /> Delete</CancelAction>,
                        }]
                    }}
                >
                    <a>
                        <Space>
                            Actions <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                <div style={{fontSize: 11}}>selected {selected_teams}</div></>,
            dataIndex: 'actions',
            key: 'actions'
        }
    ];

    const handleBRSCheckboxChange = (id: number | undefined) => {
        setTeamNotifications(teamNotifications.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        brs: !script.notify?.brs
                    }
                };
            }
            return script;
        }));
    };

    const handleBERCheckboxChange = (id: number | undefined) => {
        setTeamNotifications(teamNotifications.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        ber: !script.notify?.ber
                    }
                };
            }
            return script;
        }));
    };

    const handleAERCheckboxChange = (id: number | undefined) => {
        setTeamNotifications(teamNotifications.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        aer: !script.notify?.aer
                    }
                };
            }
            return script;
        }));
    };

    const handleARECheckboxChange = (id: number | undefined) => {
        setTeamNotifications(teamNotifications.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        are: !script.notify?.are
                    }
                };
            }
            return script;
        }));
    };

    return (
        <>
            <ButtonC key={'clear_all'} type={"primary"} onClick={()=>setTeamNotifications([])} text={'Clear All'} style={{float:'right', marginBottom: 10}}/>
            <Table
                pagination={{pageSize: 10, hideOnSinglePage:true}}
                rowSelection={{
                    selectedRowKeys: checkedTeams,
                    onChange: (keys, rows) => {
                        setCheckedTeams(keys)
                    }
                }}
                dataSource={teamNotifications && teamNotifications.map(team => ({
                    team: team.team,
                    title: <Row><div>{team.title}</div>
                    <Tooltip
                                placement="bottom"
                                title={
                                    <>
                                    <h4>Message</h4>
                                    <div dangerouslySetInnerHTML={{ __html: team.message}} /></>
                                        }
                                        color={'grey'}
                                    >
                                        <InfoCircleOutlined style={Object.assign({}, {marginLeft:15}, icon_style)}/>
                                    </Tooltip>
                    </Row>,
                    key: team.id,
                    run_starts: <Checkbox checked={team.notify.brs} onChange={() => handleBRSCheckboxChange(team.id)}/>,
                    b_each_run: <Checkbox checked={team.notify.ber} onChange={() => handleBERCheckboxChange(team.id)}/>,
                    a_each_run: <Checkbox checked={team.notify.aer} onChange={() => handleAERCheckboxChange(team.id)}/>,
                    completion: <Checkbox checked={team.notify.are} onChange={() => handleARECheckboxChange(team.id)}/>,
                    actions: <><EditOutlined onClick={() => {setVisible(true); setSelectedTeam(team)}}/> Edit </>
                }))}
                key='team_notifications'
                columns={columns}
            />
            <ButtonC type={"primary"} onClick={()=>setVisible(true)} text={'Add New'} style={{marginTop: 10}}/>
            <ShareWallNotificationPopup visible={visible} setVisible={setVisible}
                                        message={message} setMessage={setMessage}
                                        runTeams={teamNotifications} setRunTeams={setTeamNotifications}
                                        selectedTeam={selectedTeam} setSelectedTeam={setSelectedTeam}/>
        </>
    )
}

export default TableRunTeams;