import React, {Dispatch, SetStateAction} from "react";
import dayjs, { Dayjs } from 'dayjs';
import {DatePicker} from "antd";

type FutureDatepickerPops = {
    selectedDate: Dayjs | null,
    setSelectedDate: Dispatch<SetStateAction<Dayjs | null>>,
    format?: string,
    includeCurrentDay?: boolean,
    disabled?: boolean}

export function FutureDatepicker(props: FutureDatepickerPops): JSX.Element {
    const { selectedDate, setSelectedDate, format, disabled } = props;

    // const disabledDate = (current: Dayjs) => {
    //     return current && includeCurrentDay !== false?
    //         current < dayjs().startOf('day'):
    //         current < dayjs().endOf('day');
    // };

    const disabledTime = (current: Dayjs | null) => {
        if (!current) {
            return {
                disabledHours: () => [],
                disabledMinutes: () => [],
            };
        }

        const currentDate = current.format('YYYY-MM-DD');
        const currentMoment = dayjs();
        const currentHour = currentMoment.hour();
        const currentMinute = currentMoment.minute();

        return {
            disabledHours: () => (currentDate === currentMoment.format('YYYY-MM-DD') ? Array.from({ length: currentHour }, (_, i) => i) : []),
            disabledMinutes: (hour: number) =>
                hour === currentHour && currentDate === currentMoment.format('YYYY-MM-DD')
                    ? Array.from({ length: currentMinute }, (_, i) => i)
                    : [],
        };
    };

    return (
        <DatePicker
        value={selectedDate ? dayjs(selectedDate) : null}
        onChange={(date)=>{setSelectedDate(date || null)}}
        format={format? format : "YYYY-MM-DD HH:mm"}
        // onOpenChange={(e)=>console.log(e)}
        // disabledDate={disabledDate}
        disabled={disabled}
        disabledTime={disabledTime}
        showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
        />
    );
}


