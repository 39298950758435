import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, Form, Input } from 'antd';
import { PageResource } from "../../models/dtos/page_resource";
import { Team } from "../../models/entities/team";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { createTeam } from '../../redux/actions/team_actions';
import {useAppContext} from "../../AppContext";
import {handleErrorResponse, safeHandleErrorResponse} from "../../assets/helpers/errorHandler";

type TeamProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    setTeamPage: Dispatch<SetStateAction<PageResource<Team>>>;
    teamPage: PageResource<Team>
}

export function TeamModal(props: TeamProps):JSX.Element{
    const {setVisible, visible, setTeamPage, teamPage} = props;
    
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg} = useAppContext();

    // form state

    const [componentSize, setComponentSize] = useState();
    const [form] = Form.useForm();

    // team state
    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")

    const org_id = currentOrg.id

    const onFormLayoutChange = ({ size } :any) => {
        setComponentSize(size);
    };

    useEffect(()=>{
        form.setFieldsValue({
            name: name,
            description: description,
            jobs: []
        })
    }, [])

    const handleOk = () => {
        const new_team = {name:name, 
            description:description, 
            org_id: org_id};

        // setLoading(true)
        // setIsValid(true)
        dispatch(createTeam(new_team, teamPage.content))
        .then(foundPage => {
            teamPage.content=foundPage;
            setTeamPage(teamPage)

            form.resetFields()
            setName('');
            setDescription('');

            // setLoading(false)
            setVisible(false)
        })
        .catch((err) => {
            safeHandleErrorResponse(err)
        })
    }


    const handleCancel = () => {
        form.resetFields()
        setName('');
        setDescription('');
        setVisible(false)
    };


    return (
        <Modal open={visible}
               title="Add New Team"
               okText='Save'
               cancelText="Cancel"
               onOk={handleOk}
               onCancel={handleCancel}
        >
            <Form form={form}
                  name="createTeam"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  layout="horizontal"
                  initialValues={{ size: componentSize }}
                  onValuesChange={onFormLayoutChange}
                  size={componentSize}
            >
                <Form.Item  name="name" label="Team Name"  style={{width: 600}}
                            rules={[{ required: true, message: 'Please input a Team Name!' }]}>
                    <Input  value={name} placeholder="Enter Team name"
                            onChange={(event) => setName(event.target.value)} style={{marginLeft: 20}}/>
                </Form.Item>
                <Form.Item  name="description" label="Description"  style={{width: 600}}> 
                    <Input  value={description} placeholder="Enter description"
                            onChange={(event) => setDescription(event.target.value)} style={{marginLeft: 20}}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}