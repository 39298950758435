import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux/store';
import {useNavigate} from 'react-router-dom';
import {recoverUserPassword} from '../../redux/actions/auth_actions';
import {Button, Form, Input, notification} from "antd";
import {UserOutlined} from "@ant-design/icons";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const Forgot: React.FC = () => {
    const [email, setEmail] = useState('');
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const handleSignUp = (e: React.FormEvent) => {
        e.preventDefault();
        // console.log('Signing up with:', {email});
    };

    const handleRecover = () => {
        dispatch(recoverUserPassword({email}))
            .then((success) => {
                if(success){
                    notification.success({
                        message: 'Email Send',
                        description: <>
                            <p>Your password recovery process has been successfully initiated.</p>
                            <p>An email has been sent to "<b>{email}</b>" with further instructions on resetting your password.</p>
                        </>,
                    });
                    navigate('/login/reset')
                }else{
                    notification.warning({
                        message: 'Email Send',
                        description: <>
                            <p>An email has been sent to "<b>{email}</b>" with further instructions on resetting your password.</p>
                        </>,
                    });
                }

            }).catch((err) => {
                safeHandleErrorResponse(err)
            });
    };

    return <Form
        onFinish={handleSignUp}
        style={{ width: 400 }} // Ensure the form takes the full width
    >
        <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
        >
            <Input
                size={'large'}
                prefix={<UserOutlined />}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
        </Form.Item>
        <Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                onClick={handleRecover}
                disabled={email.trim() === ''}
            >
                Recover Password
            </Button>
        </Form.Item>
    </Form>
};

export default Forgot;
