import React from 'react';
import {Col, Tooltip} from "antd";
import RowC from "./row";

interface ProgressBarProps {
    options:{
        title: string,
        color: string,
        progress: number
    }[]
    total: number,
    bar_width?: string
    // scripts: string;
}

function ProgressBar({options, total, bar_width}:ProgressBarProps): JSX.Element {

    let title = '';
    let current_progress = 0;
    const parts:React.ReactNode = options.map((option, key) => {
        title += `${option.progress} ${option.title} / `
        current_progress += option.progress
        return <div key={option.title} style={{ width: `${option.progress/total*100}%`, height:'100%', backgroundColor: option.color}}/>
    })

    title += `${total} Total`

    return <RowC style={{padding: '0px 20px 0px 20px'}}>

            <Col flex='auto'>
                <Tooltip
                    placement="top"
                    title={title}
                >
                <div style={{float: 'right', width: bar_width?bar_width:'100%', marginRight:10, height:8, borderRadius:8, overflow:'hidden', display:"flex"}}>
                    {parts}
                    <div key='remaining' style={{ width: `${(total-current_progress)/total*100}%`, height:'100%', backgroundColor: '#000', opacity: 0.06}}/>
                </div>
                </Tooltip>

            </Col>
            <Col flex='20px' style={{marginTop:-30}}> &nbsp; {Math.round((current_progress) / total * 100)}%</Col>

        </RowC>


}

export default ProgressBar;
