import React, {Dispatch, SetStateAction, useState} from "react";
import {Col, Form, Input, Row, Select, Space} from "antd";
import {priorities} from "../../assets/data/priority";
import RowC from "../row";
import ButtonC from "../button";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {colors} from "../../assets/data/colors";
import {ArrowUpIcon} from "primereact/icons/arrowup";
import {ArrowDownIcon} from "primereact/icons/arrowdown";
import RichEditor from "../rich_editor/rich_editor";
import { ComponentDD } from "../../models/entities/component";

export function SectionForm(): JSX.Element {
    return <>
        <Form.Item name="section_title" label="Section Title"
                   rules={[{required: true, message: 'Please enter a Title for the section!'}]}>
            <Input placeholder="Enter Section Title"/>
        </Form.Item>
    </>
}

type CaseFormProps = {
    caseTitle: string,
    setCaseTitle: Dispatch<SetStateAction<string>>,
    caseDescription: string,
    setCaseDescription: Dispatch<SetStateAction<string>>,
    casePriority: number,
    setCasePriority: Dispatch<SetStateAction<number>>,
    caseSteps: string[],
    setCaseSteps: Dispatch<SetStateAction<string[]>>,
    caseComponents: ComponentDD[],
    setCaseComponents: Dispatch<SetStateAction<ComponentDD[]>>,
    selectedComponents: ComponentDD[],
    setSelectedComponents: Dispatch<SetStateAction<ComponentDD[]>>,
}

export function CaseForm(props: CaseFormProps): JSX.Element {
    const {caseTitle, setCaseTitle, setCaseSteps, caseSteps, caseComponents, caseDescription, setCaseDescription, selectedComponents, setSelectedComponents, casePriority, setCasePriority} = props
    const [totalSteps, setTotalSteps] = useState<number>(1);

    return <>
        <Row style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
        <Col flex="none">
            <label htmlFor="case_title" style={{ marginRight: '10px', minWidth: '70px', textAlign: 'right' }}>
                <span style={{ color: 'red' }}>*</span> Title:
            </label>
        </Col>
        <Col flex="auto">
            <Input
                id="case_title"
                value={caseTitle}
                placeholder="Enter Case Title"
                style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                onChange={(event) => setCaseTitle(event.target.value)}
            />
        </Col>
    </Row>
    <Row style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
        <Col flex="none">
            <label htmlFor="case_priority" style={{ marginRight: '10px', minWidth: '70px', textAlign: 'right' }}>
                Priority:
            </label>
        </Col>
        <Col flex="auto">
            <Select
                id="case_priority"
                style={{ width: '10%' }}
                options={priorities}
                value={casePriority}
                onChange={(value) => setCasePriority(value)}
            />
        </Col>
    </Row>
    <Row style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
        <Col flex="none">
            <label htmlFor="case_description" style={{ marginRight: '10px', minWidth: '70px', textAlign: 'right' }}>
                Description:
            </label>
        </Col>
        <Col flex="auto">
            <Input.TextArea
                id="case_description"
                placeholder="Enter Case Description"
                value={caseDescription}
                onChange={(event) => setCaseDescription(event.target.value)}
                style={{ width: '100%' }}
                autoSize={{ minRows: 2, maxRows: 6 }}
            />
        </Col>
    </Row>
    <Row style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
        <Col flex="none">
            <label htmlFor="case_steps" style={{ marginRight: '10px', minWidth: '70px', textAlign: 'right' }}>
                Steps:
            </label>
            </Col>
        <Col flex="auto">
            <Space direction="vertical" style={{width: '100%', marginLeft: 10}}>
                {
                    caseSteps.map((step, index) => {
                        return <RowC key={index}>
                            <Col flex={'auto'}>
                                {/* <Input.TextArea
                                    key={index}
                                    autoSize={{minRows: 5, maxRows: 8}}
                                    value={caseSteps[index]}
                                    placeholder="Enter Case Steps"
                                    onChange={(event) => {
                                        let temp_steps = [...caseSteps]
                                        temp_steps[index] = event.target.value
                                        setCaseSteps(temp_steps)
                                    }}/> */}
                                <RichEditor value={caseSteps[index]} onChange={(event) => {
                                        let temp_steps = [...caseSteps]
                                        temp_steps[index] = event
                                        setCaseSteps(temp_steps)
                                    }}/>
                            </Col>
                            <Col flex={'20px'} style={{marginLeft: 5}}>
                                <Space direction="vertical">
                                    <ButtonC
                                        shape="circle"
                                        size="small"
                                        type={'text'}
                                        text={<PlusOutlined style={{color: colors.green.forest}}/>}
                                        onClick={() => {
                                            let temp_steps = [...caseSteps]
                                            temp_steps.splice(index+1, 0, '')
                                            setCaseSteps(temp_steps)
                                            let tss = totalSteps
                                            tss++
                                            setTotalSteps(tss)
                                        }}
                                    />
                                    {
                                        index !== 0 ?
                                            <ButtonC
                                                shape="circle"
                                                type={'text'}
                                                size={"small"}
                                                text={<MinusCircleOutlined
                                                    style={{color: colors.red.vermilion}}/>}
                                                onClick={() => {
                                                    let tss = totalSteps
                                                    let temp_steps = [...caseSteps]
                                                    tss--
                                                    temp_steps.splice(index, 1)
                                                    setCaseSteps(temp_steps)
                                                    setTotalSteps(tss)
                                                }}/>
                                            : null
                                    }
                                    {
                                        index !== 0 ?
                                            <ButtonC
                                                shape="circle"
                                                type={'text'}
                                                size={"small"}
                                                text={<ArrowUpIcon
                                                    style={{color: colors.blue.dark}}/>}
                                                onClick={() => {
                                                    const newArray = [...caseSteps];
                                                    [newArray[index - 1], newArray[index]] = [newArray[index], newArray[index - 1]]
                                                    setCaseSteps(newArray)
                                                }}/>
                                            : null
                                    }
                                    {
                                        index + 1 !== caseSteps.length ?
                                            <ButtonC
                                                shape="circle"
                                                type={'text'}
                                                size={"small"}
                                                text={<ArrowDownIcon
                                                    style={{color: colors.blue.dark}}/>}
                                                onClick={() => {
                                                    const newArray = [...caseSteps];
                                                    [newArray[index + 1], newArray[index]] = [newArray[index], newArray[index + 1]]
                                                    setCaseSteps(newArray)
                                                }}/>
                                            : null
                                    }
                                </Space>
                            </Col>
                        </RowC>
                    })
                }
            </Space></Col>
        </Row>
        <Row style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
        <Col flex="none">
            <label htmlFor="case_components" style={{ marginRight: '10px', minWidth: '70px', textAlign: 'right' }}>
                Components:
            </label>
            <Select
                mode="multiple"
                value={selectedComponents}
                size="large"
                onChange={(event) => {setSelectedComponents(event)}}
                style={{width: 200}}
                placeholder='Select Component'
                options={caseComponents}
            /></Col>
        </Row>
    </>
}