import React, {useEffect, useState} from 'react';
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import { FloatButton, Select, Space, Table } from 'antd';
import { ProvidePermissionsInterface } from '../../models/entities/permissions';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { getOrgUserInfo } from '../../redux/actions/user_actions';
import { PageResource } from '../../models/dtos/page_resource';
import { OrgUsersInfo } from '../../models/entities/user';
import { createEmptyPage } from '../../services/utils/PageResourceUtils';
import { useAppContext } from '../../AppContext';
import { getJobs } from '../../redux/actions/job_actions';
import { Job } from '../../models/entities/job';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const {Option} = Select;


function Permissions(): JSX.Element {

    const dispatch: AppDispatch = useDispatch();

    const {currentOrg} = useAppContext();

    const [userPage, setUsersPage] = useState<PageResource<OrgUsersInfo>>(createEmptyPage());
    const [jobPage, setJobPage] = useState<PageResource<Job>>(createEmptyPage());
    const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);
    const [selectedJobsIds, setSelectedJobsIds] = useState<number[]>([]);
    const [permissions, setPermissions] = useState<ProvidePermissionsInterface[]>([
        {
            name: "Manage Organization Users",
            users: ["Panos Pantazos", "Pantelis Patsos", "Christos Kampisios", "Stratoula Papadopoulou"],
            jobs: ["Director", "Lead Automation Engineer"]
        },
        {
            name: "Manage Organization Settings",
            users: ["Panos Pantazos", "Stratoula Papadopoulou"],
            jobs: ["Director"]
        },
        {
            name: "Create Templates",
            users: ["Panos Pantazos", "Pantelis Patsos"],
            jobs: ["Director", "Lead Automation Engineer"]
        },
        {
            name: "Manage Runs",
            users: ["Christos Kampisios", "Stratoula Papadopoulou"],
            jobs: ["Lead Automation Engineer"]
        },
        {
            name: "Manage Projects and Teams",
            users: ["Panos Pantazos", "Pantelis Patsos", "Christos Kampisios", "Stratoula Papadopoulou"],
            jobs: ["Director", "Lead Automation Engineer"]
        }
    ]);

    const columns = () => {
        let columns: object[] = 
        [{
            title: "Permission",
            dataIndex: "permission",
            key: "permission",
            ellipsis: true
        },{
            title: "Users",
            dataIndex: "users",
            key: "users",
            ellipsis: true
        },{
            title: "Jobs",
            dataIndex: "jobs",
            key: "jobs",
            ellipsis: true
        }]


        return columns
    }

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getOrgUserInfo(currentOrg.id, '')).then(foundPage => {
                setUsersPage(foundPage)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch, currentOrg])

    useEffect(() => {
        const fetchData = async () => {
                dispatch(getJobs(currentOrg.id)).then(foundPage => {
                    setJobPage(foundPage)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })

        }
        fetchData();
    }, [dispatch])

    const handleUsersChange = (event: number[]) => {
        setSelectedUsersIds(event);
    };

    const handleJobsChange = (event: number[]) => {
        setSelectedJobsIds(event);
    };

    const data = () => {
        let data: any = [];
        if(permissions){
            permissions.forEach((permission)=> {
                data.push({
                    key: `${permission.name}`,
                    permission: permission.name,
                    users: <Select
                                key='select-users_perms'
                                mode="multiple"
                                placeholder="Select Users"
                                value={permission.users}
                                // onChange={handleUsersChange}
                                optionLabelProp="label"
                            >
                                {
                                    userPage.content.map(user=>{
                                        return <Option key={user.user_info.id} value={user.user_info.id} label={user.user_info.name + " " + user.user_info.lastname}>
                                            <Space>{user.user_info.name + " " + user.user_info.lastname}</Space>
                                        </Option>
                                    })
                                }
                            </Select>,
                    jobs: <Select
                                key='select-jobs_perms'
                                mode="multiple"
                                placeholder="Select Jobs"
                                value={permission.jobs}
                                // onChange={handleJobsChange}
                                optionLabelProp="label"
                            >
                                {
                                    jobPage.content.map(job=>{
                                        return <Option key={job.id} value={job.id} label={job.name}>
                                            <Space>{job.name}</Space>
                                        </Option>
                                    })
                                }
                            </Select>,
                });
            });
        }
        return data;
    } 

    return (
        <Page title="Permissions">
            <PageHeader title={'Permissions'}/>
            <PageContent>
            <Table 
            columns={columns()} 
            dataSource={data()} 
            pagination={false}
            />        
            <FloatButton.BackTop/>
            </PageContent>
        </Page>
    )
}

export default Permissions;
