import React from 'react';
import { Anchor } from 'antd';
import SettingSection from "./setting_row";
import { setting_sections } from '../models/entities/settings';

type SettingsPropsType = {
    setting_sections: setting_sections
}

function Settings({setting_sections}:SettingsPropsType): JSX.Element {
    const anchor_items:any[] = setting_sections.map((section, key)=>{
        return {
            key: key,
            title: section.title,
            href: `#${section.attr_id}`,
            children: section.children? section.children.map((c_value, key)=> {
                return {
                    key: key,
                    title: c_value.title,
                    href: `#${c_value.attr_id}`
                }
            }):[]
        }
    })

    return <>
        <div style={{width:200, float:'left'}}>
            <Anchor items={anchor_items} />
        </div>
        <div style={{width: 'calc(100% - 200px)', float:'left', marginTop: -40, paddingLeft: 25}} >
            {
                setting_sections.map( (section, key) => {
                    return (
                        <SettingSection
                            key={key}
                            section_id={section.attr_id}
                            divider_title={section.title}
                            settings={section.children? section.children.map( (sub_section, key) => {return {
                                    key: `se-${key}`,
                                    title: sub_section.title,
                                    description: sub_section.description,
                                    value: <SettingSection key={key} section_id={sub_section.attr_id} settings={sub_section.settings}/>
                                }})
                                :section.settings}/>
                    )
                })
            }
        </div>
    </>

}
export default Settings;