import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import RowC, {RowSplitHalf} from "../../components/row";
import {Col, Card} from "antd";
import {colors} from "../../assets/data/colors";
import DemoLine from "../../assets/demo/ste_line_multiple_plot";
import {segment_period} from "../../models/entities/time";
import {getCasesStats, getNewCasesStats} from "../../redux/actions/cases";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {useAppContext} from "../../AppContext";
import {BasicLine, MultiLine} from "../../components/chart/line";
import LcsManager from "../../assets/local_storage/lcs";
import { getAllRunsStats, getCurrentYearRunResults } from '../../redux/actions/run_actions';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';


function Overview(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {currentOrg, currentProject} = useAppContext()
    const lcs_manager = new LcsManager()

    const {project_key} = useParams();
    const isProjectOverview = Boolean(project_key)
    const user_prefs = lcs_manager.get_item_from_lcs(lcs_manager.Keys.user_prefs)

    // const [darkMode, setDarkMode] = useState<Boolean>(user_prefs?user_prefs.overview? user_prefs.overview.darkModeDashboard:false:false)
    // const [runResultsOption, setRunResultsOption] = useState<segment_period>(user_prefs?user_prefs.overview? user_prefs.overview.newRunResultsMode:'week':'week')
    const [runResultsData, setRunResultsData] = useState<{month: string, key: string, title: string, value: number}[]>([])

    const [newCasesOption, setNewCasesOption] = useState<segment_period>(user_prefs?user_prefs.overview? user_prefs.overview.newCasesMode:'week':'week')
    const [newCasesData, setNewCasesData] = useState<{ type: string, value: number }[]>([])
    const [runStats, setRunStats] = useState<{tests_in_queue: number, total_tests_executed: number, defects_found: number, unresolved_cases: number}[]>()

    const [casesData, setCasesData] = useState<{ total_cases: number, current_month_cases: number }>({total_cases:0, current_month_cases:0})

    const card_style: React.CSSProperties = {
        minWidth: 200,
        height: 180,
        textAlign: "center",
        // backgroundColor: '#000',
        // opacity: '0.8'
    }

    // const content_style = {color: '#fff', opacity: 0.8}
    // const header_style = {color: '#fff', opacity: 0.9}

    useEffect(() => {
        const element = document.querySelector('.site-layout-content') as HTMLElement;
        if (element) element.style.backgroundColor = colors.gray.background
        const id = isProjectOverview? currentProject.id:currentOrg.id
        const id_type = isProjectOverview? 'org':'project'
        dispatch(getNewCasesStats(id, id_type, newCasesOption))
            .then(foundPage=>setNewCasesData(foundPage)).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getCasesStats(id, id_type))
            .then(foundPage=>setCasesData(foundPage)).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getCurrentYearRunResults(currentOrg.id, currentProject.id))
            .then(foundPage => {
                setRunResultsData(foundPage.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getAllRunsStats(currentOrg.id, currentProject.id))
            .then(foundPage => setRunStats(foundPage.content)).catch((err) => {
                safeHandleErrorResponse(err)
            })

        // when unmount component -> restore background color to default
        return () => {
            element.style.backgroundColor = '#fff'
        }
    }, [dispatch, currentProject])

    // useEffect(() => {
    //     const element = document.querySelector('.site-layout-content') as HTMLElement;
    //     if (element) element.style.backgroundColor = colors.gray.background
    //
    //     // when unmount component -> restore background color to default
    //     return () => {
    //         element.style.backgroundColor = '#fff'
    //     }
    // }, [darkMode])

    const gutter = [24, 24]

    return (
        <Page title="Overview">
            <PageHeader title={
                <>Overview &nbsp;
                    {/* <Segmented
                        style={{backgroundColor: '#fff', color: colors.gray.shadow}}
                        options={
                            [
                                {icon: <LikeFilled/>, value: 'light'},
                                {icon: <DislikeFilled/>, value: 'dark'}
                            ]
                        }
                    /> */}
                </>
                // <RowC style={{marginTop:-25}}>
                //     <Col flex={'110px'}><h2>Overview</h2></Col>
                //     <Col style={{marginTop:15}}><Switch style={{marginLeft:8}} size={'small'}/>
                //         <p style={{fontSize:10}}>Dark Mode</p></Col>
                // </RowC>
            }
            />
            <PageContent style={{backgroundColor: colors.gray.background}}>
                <RowSplitHalf
                    gutter={gutter}
                    l_children={
                        <>
                            <Col flex={'auto'}>
                                <Card hoverable={true} style={card_style} title={<div>Tests in Queue</div>}>
                                    <h1>{runStats ? runStats[0].tests_in_queue : 0}</h1>
                                </Card>
                            </Col>
                            <Col flex={'auto'}>
                                <Card hoverable={true} style={card_style} title='Total Tests Executed'>
                                    <h1>{runStats ? runStats[0].total_tests_executed : 0}</h1>
                                </Card>
                            </Col>
                        </>
                    }
                    r_children={
                        <>
                            <Col flex={'auto'}>
                                <Card hoverable={true} style={card_style} title='Defects Found'>
                                    <h1>{runStats ? runStats[0].defects_found : 0}</h1>
                                </Card>
                            </Col>
                            <Col flex={'auto'}>
                                <Card hoverable={true} style={card_style} title='Unresolved Cases'>
                                    <h1>{runStats ? runStats[0].unresolved_cases : 0}</h1>
                                </Card>
                            </Col>
                        </>
                    }
                />
                <RowC gutter={gutter} style={{marginTop: 24}}>
                    <Col span={18}>
                        <Card
                            hoverable={true}
                            title={
                                <div>New Cases
                                    {/*<Segmented*/}
                                    {/*    style={{color: colors.gray.shadow, float: 'right'}}*/}
                                    {/*    options={[*/}
                                    {/*    {*/}
                                    {/*        label: 'Days',*/}
                                    {/*        value: 'day'*/}
                                    {/*    }, {*/}
                                    {/*        label: 'Weeks',*/}
                                    {/*        value: 'week'*/}
                                    {/*    }, {*/}
                                    {/*        label: 'Months',*/}
                                    {/*        value: 'month'*/}
                                    {/*    }*/}
                                    {/*]}*/}
                                    {/*    value={newCasesOption}*/}
                                    {/*    onChange={(value: any) => {*/}
                                    {/*        console.log('New Cases')*/}
                                    {/*        setNewCasesOption(value)*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </div>}
                            style={{minWidth: 600, height: 400, textAlign: 'center'}}>
                            <BasicLine data={newCasesData} style={{height: 320}} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card hoverable={true} title='Total Cases'
                              style={{minWidth: 200, height: 192, textAlign: 'center'}}>
                            <h1 style={{marginTop: -5}}>{casesData.total_cases}</h1>
                        </Card>

                        <Card hoverable={true} title='New Cases'
                              style={{minWidth: 200, marginTop: 16, height: 192, textAlign: 'center'}}>
                            <h1 style={{marginTop: -5}}>{casesData.current_month_cases}</h1>
                        </Card>
                    </Col>
                </RowC>
                <RowC gutter={gutter} style={{marginTop: 24}}>
                    <Col span={8}>
                        <Card title='Runs' hoverable={true} style={{height: 400, textAlign: 'center'}}>
                            <DemoLine/>
                        </Card>
                    </Col>
                    <Col span={16}>
                        <Card
                            hoverable={true}
                            title={
                                <div>Run Results
                                    {/*<Segmented*/}
                                    {/*    style={{color: colors.gray.shadow, float: 'right'}}*/}
                                    {/*    options={[*/}
                                    {/*    {*/}
                                    {/*        label: 'Days',*/}
                                    {/*        value: 'day'*/}
                                    {/*    }, {*/}
                                    {/*        label: 'Weeks',*/}
                                    {/*        value: 'week'*/}
                                    {/*    }, {*/}
                                    {/*        label: 'Months',*/}
                                    {/*        value: 'month'*/}
                                    {/*    }*/}
                                    {/*]}*/}
                                    {/*    value={runResultsOption}*/}
                                    {/*    onChange={(value: any) => setRunResultsOption(value)}*/}
                                    {/*/>*/}
                                </div>}
                            style={{minWidth: 550, height: 400, textAlign: 'center'}}>
                            <MultiLine 
                            data={runResultsData} 
                            style={{height: 320}} />
                        </Card>
                    </Col>
                </RowC>
            </PageContent>
        </Page>
    )
}

export default Overview;
