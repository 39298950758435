import React, { useEffect, useState } from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import { CardIcons } from '../../components/cards/card_icon';
import { getProjects } from '../../redux/actions/project_actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { PageResource } from '../../models/dtos/page_resource';
import { Project } from '../../models/entities/project';
import { createEmptyPage } from '../../services/utils/PageResourceUtils';
import { CardItem } from '../../models/entities/helpers/cardItem';
import { ProjectModal } from '../../components/modals/project_modal';
import {Divider, Row, Input} from 'antd';
import {useAppContext} from "../../AppContext";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const { Search } = Input;


function Projects(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const { currentOrg, loggedInUser, setAppContextProject } = useAppContext();
    const [ projectPage, setProjectPage ] = useState<PageResource<Project>>(createEmptyPage());
    const [ visible, setVisible ] = useState<boolean>(false);
    const [ search, setSearch ] = useState<string>('')

    useEffect(() => {
        dispatch(getProjects({org_id:currentOrg.id, user_id:loggedInUser.id}))
            .then(found_projects =>{
            setProjectPage(found_projects)
        })
        .catch((err) => {
            safeHandleErrorResponse(err)
        })

    }, [dispatch]);

    const constructCardData = () => {
        
        const cardItems:CardItem[] = []
        const projects:Project[] = projectPage.content
        
        projects.forEach(project=>{
            cardItems.push({
                id: project.id,
                link: `/${currentOrg.domain}/projects/${project.p_key}/overview`,
                icon: project.icon === null ? '/icons/default_project.png' : project.icon,
                name: project.name,
                description: `${project.test_cases} Cases`,
                onclick: ()=> setAppContextProject(project)
            })
        })
        return cardItems
    }
    
    return (
        <Page title="Projects">
        <PageHeader title="Projects" buttonHeader={{type: "create",
                                                    onClickFunction:()=>setVisible(true)}}/>
        <PageContent>
        <Search
                style={{width: 250}}
                // ref={searchInputRef}
                placeholder="Search for project"
                onSearch={() => dispatch(getProjects({org_id:currentOrg.id, user_id:loggedInUser.id, search: search})).then(foundPage => {
                    setProjectPage(foundPage)
              }).catch((err) => {
                safeHandleErrorResponse(err)
            })}
                value={search}
                onChange={(value)=>setSearch(value.target.value)}
                />
            <Row>
                <Divider orientation="left"> All Projects </Divider>
                {CardIcons(constructCardData())}
            </Row>
            <ProjectModal visible={visible}
                          setVisible={setVisible}
                          setProjectPage={setProjectPage}
                          projectPage={projectPage}/>
        </PageContent>
    </Page>
    )
}

export default Projects;