import { AppDispatch } from "../store";
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { PageResource } from "../../models/dtos/page_resource";
import { TestReview } from "../../models/entities/test_reviews";
import {CasesPreviewAction} from "../../models/entities/suite_section_props";
import {CasesPreview, Reviewer} from "../../models/entities/test_case_info";
import {get, post, put, deletion} from '../../services/axiosWrapper';
import axios from 'axios';

const api_path = '/api/run/';

interface getRunActionsParams {
    project_ids: number[],
    org_id: number,
    status?: string[],
    state?: number[],
    search?: string,
    created_from?: any,
    created_to?: any,
    assignees?: number[],
    current_user: number
}

interface getRunCasesParams {
    run_id: number,
    limit: number,
    offset: number,
    assignees?: number[],
    assignee?: number,
    search?: string,
    statuses?: number[]
}

export const getRuns = (params: getRunActionsParams) => (dispatch: AppDispatch): Promise<PageResource<TestReview>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getRuns`, params)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const viewCasesPerComponents = (components_ids_array: React.Key[], section_id_array?: React.Key[], case_id_array?: React.Key[], project_id?: number, org_id?: number) => (dispatch: AppDispatch): Promise<{component_title: string, component_cases: number}[]> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}viewCasesPerComponents?components_ids_array=${components_ids_array}
                                   &section_id_array=${section_id_array}&case_id_array=${case_id_array}&project_id=${project_id}&org_id=${org_id}`)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const createNewTestRun = (data:any) => (dispatch: AppDispatch): Promise<{}> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}create`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const calculateRunTime = (project_id:number, selection:CasesPreviewAction) => (dispatch: AppDispatch): Promise<CasesPreview> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}calculateRunTime?project_id=${project_id}`, selection)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const calculateReviewers = (project_id:number, selection:CasesPreviewAction) => (dispatch: AppDispatch): Promise<{total: number, reviewers: []}> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}calculateReviewers?project_id=${project_id}`, selection)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const archiveRun = (data:any) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}archiveRun?`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteRun = (run_id:number) => (dispatch: AppDispatch): Promise<any>  => {
    dispatch(fetchStartAction());
    const request: any = deletion(`${api_path}deleteRun?run_id=${run_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const startRun = (data:any) => (dispatch: AppDispatch): Promise<CasesPreview> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}editRunStatus?`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunCases = (params: getRunCasesParams)=> (dispatch: AppDispatch): Promise<any>  => {
    dispatch(fetchStartAction());
    const request: any = get(`${api_path}getRunCases`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteRunCases = (ids:number[]) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    // Create a request config object with data as a query parameter

    const request: any = deletion(`${api_path}deleteRunCases`, { ids } );

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
};

export const updateRunCaseStatus = (ids:number[], body:{
    status: number,
    resolved_by: number,
    resolved_comment: string
}) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    // Create a request config object with data as a query parameter

    const request: any = put(`${api_path}updateRunCaseStatus?ids=${ids}`, body);

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
};

export const getRunReviewers = (run_id:number, search:string, ignore_user?:number)  => (dispatch: AppDispatch): Promise<Reviewer[]> => {
    const request: any = get(`${api_path}getRunReviewers?run_id=${run_id}&search=${search}&ignore_user=${ignore_user}`);

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response.data; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
}

export const getRunCaseComments = (run_id:number, case_id:number, offset:number) => (dispatch: AppDispatch): Promise<any> => {

    const request: any = get(`${api_path}getRunCaseComments?run_id=${run_id}&case_id=${case_id}&offset=${offset}`);

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response.data; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
}

export const postRunCasesComment = (data:{run_id:number, case_ids:number[], user_id:number, comment:string, project_id: number, org_id: number}) => (dispatch: AppDispatch): Promise<any> =>  {

    const request: any = post(`${api_path}postRunCasesComment`, {data})
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteCommentRunCase = (comment_id:number) =>(dispatch: AppDispatch): Promise<any> =>  {

    const request: any = deletion(`${api_path}deleteCommentRunCase?comment_id=${comment_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunComponentCases = (params:{org_id: number, run_id:number, max_to_show?:number}) => (dispatch: AppDispatch): Promise<any> =>  {

    const request: any = get(`${api_path}getRunComponentCases?`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getGithubBranches = ()=> (dispatch: AppDispatch): Promise<any> => {
    const request: any = axios.get(`${api_path}getGithubBranches?`)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getAllRunsStats = (org_id: number, project_id: number) => (dispatch: AppDispatch): Promise<PageResource<{tests_in_queue: number, total_tests_executed: number, defects_found: number, unresolved_cases: number}>> =>  {

    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getAllRunsStats?org_id=${org_id}&project_id=${project_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getCurrentYearRuns = (org_id: number, project_id: number) => (dispatch: AppDispatch): Promise<PageResource<{month: string, key: string, value: number}>> =>  {

    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getCurrentYearRuns?org_id=${org_id}&project_id=${project_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getCurrentYearRunResults = (org_id: number, project_id: number) => (dispatch: AppDispatch): Promise<PageResource<{month: string, key: string, title: string, value: number}>> =>  {

    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getCurrentYearRunResults?org_id=${org_id}&project_id=${project_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunInfo = (org_id: number, run_id: any) => (dispatch: AppDispatch): Promise<{content: {state: string, created_at: string, branch: string}}> =>  {

    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getRunInfo?org_id=${org_id}&run_id=${run_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunTotalCasesStatuses = (run_id: number) => (dispatch: AppDispatch): Promise<{content: {type: string, value: number}[]}> =>  {

    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getRunTotalCasesStatuses?run_id=${run_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
