import {get, post, put, deletion} from '../../services/axiosWrapper';
import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import { DataNode } from 'antd/lib/tree';

const api_path = '/api/section/'

export const getSections = (org_id:number, project_id:number) => (dispatch: AppDispatch): Promise<DataNode[]> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getSections?org_id=${org_id}&project_id=${project_id}`)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data.content;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
} 

export const getSectionsSearch = (org_id:number, project_id:number, search?: string) => (dispatch: AppDispatch): Promise<DataNode[]> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getSections?org_id=${org_id}&project_id=${project_id}&search=${search}`)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data.content;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
} 

export const addSection = (data:{org_id:number, project_id:number, title:string, parent_id:number|null})=> (dispatch: AppDispatch): Promise<DataNode[]> => {
    dispatch(fetchStartAction());
    const request: any = post(`${api_path}addSection`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}


export const updateSection = (params:{id:number, project_id:number, title?:string}) => (dispatch: AppDispatch): Promise<DataNode[]> => {

    dispatch(fetchStartAction());
    const request: any = put(`${api_path}updateSection?`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}


export const deleteSection = (section_id:number)=> (dispatch: AppDispatch): Promise<DataNode[]> => {
    dispatch(fetchStartAction());
    const request: any = deletion(`${api_path}deleteSection?section_id=${section_id}`)
    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}