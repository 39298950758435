import React, { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import {useAppContext} from "../AppContext";
import {stringHasValue} from "../assets/helpers/helper";

interface WithAuthProps {
    // Define any additional props if needed
}

const withAuth = <P extends WithAuthProps>(WrappedComponent: ComponentType<P>): React.FC<P> => {
    const AuthComponent: React.FC<P> = (props: P) => {
        const { userToken } = useAppContext();
        const isAuthenticated = stringHasValue(userToken)

        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }

        return <WrappedComponent {...props} />;
    };

    return AuthComponent;
};

// Helper function to create a React element wrapped with the HOC
const withAuthElement = (Component: ComponentType<any>) => {
    const WrappedComponent = withAuth(Component);
    return <WrappedComponent />;
};

export default withAuthElement;
