import React, {Dispatch, SetStateAction} from "react";
import { Dayjs } from 'dayjs';
import {Col, InputNumber, Space, Switch, Segmented, Select, Tooltip, Mentions, TimePicker} from "antd";
import {
    CalendarOutlined,
    FormOutlined,
    CheckOutlined,
    CloseOutlined,
    ClusterOutlined, DesktopOutlined,
    GithubFilled, InfoCircleOutlined,
    RedoOutlined
} from "@ant-design/icons";
import InputC from "../../components/input";
import CheckboxC from "../../components/checkbox";
import RowC from "../../components/row";
import {colors} from "../../assets/data/colors";
import { MentionsOptionProps } from "antd/es/mentions";
import {FutureDatepicker} from "../../components/datepicker/datepicker";
import type { Moment } from 'moment';
import ButtonC from "../../components/button";
import {getGithubBranches} from "../../redux/actions/run_actions";
import {useDispatch} from "react-redux";


type RunConfigType={
    settings:{
        run: {
            runOption: 'Now'| 'Manually'| 'Scheduled',
            setRunOption: Dispatch<SetStateAction<'Now'| 'Manually'| 'Scheduled'>>,
            runDate: Dayjs | null,
            setRunDate: Dispatch<SetStateAction<Dayjs | null>>,
            endDate: Dayjs | null,
            setEndDate: Dispatch<SetStateAction<Dayjs | null>>,
            runRecurringDate: any | null,
            setRunRecurringDate: Dispatch<SetStateAction<Moment | null>>,
            runRecurringWeeklyDate: string | null,
            setRunRecurringWeeklyDate: Dispatch<SetStateAction<string | null>>,
            runRecurringMonthlyDate: string | null,
            setRunRecurringMonthlyDate: Dispatch<SetStateAction<string | null>>,
            runRecurringQuarterlyDate: string | null,
            setRunRecurringQuarterlyDate: Dispatch<SetStateAction<string | null>>,
            isRecurring: boolean,
            setIsRecurring: Dispatch<SetStateAction<boolean>>,
            endRecurring: boolean,
            setEndRecurring: Dispatch<SetStateAction<boolean>>,
            recurringOption: 'Day'| 'Week'| 'Month'| 'Quarter'| 'Custom',
            setRecurringOption: Dispatch<SetStateAction<'Day'| 'Week'| 'Month'| 'Quarter' | 'Custom'>>
        },
        runTitle: string,
        setRunTitle: Dispatch<SetStateAction<string>>,
        githubBranch: string,
        handleSetGithubBranch: (e:any)=>void,
        reruns: number,
        handleChangeReruns:(e:number|null)=>void,
        useMaxParallel: boolean,
        setUseMaxParallel: Dispatch<SetStateAction<boolean>>,
        handleSetMaxParallel: (e:number|null)=>void,
        maxParallel: number,
        browser:{
            selectedBrowsers: string[],
            handleBrowserSelect: (e:string[])=>void,
            sequence: 'Continuously' | 'In Parallel',
            setSequence: Dispatch<SetStateAction<'Continuously' | 'In Parallel'>>,
            runInterval: number,
            setRunInterval: Dispatch<SetStateAction<number>>,
        }
    }
}

const { Option } = Select;

function RunConfigSettings(props:RunConfigType):JSX.Element{
    const dispatch = useDispatch();

    const {
        run, browser,
        githubBranch, handleSetGithubBranch,
        reruns, handleChangeReruns,
        runTitle, setRunTitle,
        useMaxParallel, setUseMaxParallel,
        maxParallel, handleSetMaxParallel
        } = props.settings
    const label_column_width = "100px"
    const icon_column_width = "30px"
    const icon_style = {color: colors.green.bamboo, fontSize:18}
    const styleHiddenRow = { display: 'flex', marginLeft: `1${icon_column_width}` }

    const handleSetSequence = (e:any)=>{
        browser.setSequence(e)
    }
    const handleSetRunNow = (e:any) =>{
        run.setRunOption(e)
    }

    const handleSetRecurringOption = (e:any) =>{
        run.setRecurringOption(e)
    }

    const handleSetRunRecurringDate = (e: any) =>{
        run.setRunRecurringDate(e)
    }

    const handleSetRunRecurringWeeklyDate = (e: string) =>{
        run.setRunRecurringWeeklyDate(e)
    }

    const handleSetRunRecurringMonthlyDate = (e: string) =>{
        run.setRunRecurringMonthlyDate(e)
    }

    const handleSetRunRecurringQuarterlyDate = (e: string) =>{
        run.setRunRecurringQuarterlyDate(e)
    }

    const onSelect = (option: MentionsOptionProps) => {
        // console.log('select', option);
    };

    const quartelyMonthsOptions = () => {
        if (run.runRecurringQuarterlyDate === '1st') 
        {
            return [
                {
                    value: 'January',
                    label: 'January',
                },
                {
                    value: 'February',
                    label: 'February',
                },
                {
                    value: 'March',
                    label: 'March',
                }
            ]
        }
        if (run.runRecurringQuarterlyDate === '2nd') 
        {
            return [
                {
                    value: 'April',
                    label: 'April',
                },
                {
                    value: 'May',
                    label: 'May',
                },
                {
                    value: 'June',
                    label: 'June',
                }
            ]
        }
        if (run.runRecurringQuarterlyDate === '3rd') 
        {
            return [
                {
                    value: 'July',
                    label: 'July',
                },
                {
                    value: 'August',
                    label: 'August',
                },
                {
                    value: 'September',
                    label: 'September',
                }
            ]
        }
        if (run.runRecurringQuarterlyDate === '4th') 
        {
            return [
                {
                    value: 'October',
                    label: 'October',
                },
                {
                    value: 'November',
                    label: 'November',
                },
                {
                    value: 'December',
                    label: 'December',
                }
            ]
        }
    }

    return(
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <RowC>
                <Col flex={icon_column_width}> <FormOutlined style={icon_style} /></Col>
                <Col flex={label_column_width}>Title</Col>
                <Col flex="auto">
                    <Mentions
                        style={{ width: 250 }}
                        onChange={(e)=> {
                            setRunTitle(e)
                        }}
                        prefix='{{'
                        onSelect={onSelect}
                        value={runTitle}
                        options={[
                            {
                                value: 'BROWSER}}',
                                label: 'BROWSER',
                            },
                            {
                                value: 'DATE}}',
                                label: 'DATE',
                            },
                            {
                                value: 'BRANCH}}',
                                label: 'BRANCH',
                            },
                            {
                                value: 'USER}}',
                                label: 'USER',
                            },
                        ]}
                    />
                </Col>
            </RowC>
            <RowC>
                <Col flex={icon_column_width}> <GithubFilled style={icon_style} /></Col>
                <Col flex={label_column_width}>Github Branch
                    <br/> <
                    div>
                        <ButtonC onClick={()=>{dispatch(getGithubBranches())}} size={'small'} type={'text'} text={<div className='button-resize-small'>Fetch</div>}/> <ButtonC onClick={()=>{}} size={'small'} type={'text'} text={<div className='button-resize-small'>Test</div>}/>
                    </div>
                </Col>
                <Col flex="auto">
                    <InputC
                        value={githubBranch}
                        onChange={handleSetGithubBranch}
                        placeholder='Branch name'
                        style={{width: 250}}/>
                </Col>
            </RowC>
            <RowC>
                <Col flex={icon_column_width}> <DesktopOutlined style={icon_style} /></Col>
                <Col flex={label_column_width}>Browsers</Col>
                <Col flex="auto">
                    <Select
                        placeholder="Select Browsers"
                        style={{ width: 250 }}
                        value={browser.selectedBrowsers}
                        onChange={browser.handleBrowserSelect}
                        mode="multiple"
                    >
                        <Option value="Chrome">Chrome</Option>
                        <Option value="IE">Internet Explorer</Option>
                        <Option value="FF">FireFox</Option>
                    </Select>
                </Col>
            </RowC>
            {
                browser.selectedBrowsers.length>1?
                    <Space direction="vertical" size="middle" style={styleHiddenRow}>
                        <RowC>
                            <Col flex={label_column_width}>Sequence</Col>
                            <Col flex="auto">
                                <Segmented
                                    value={browser.sequence}
                                    onChange={handleSetSequence}
                                    options={['Continuously', 'In Parallel']} />
                                <Tooltip
                                    placement="right"
                                    title={
                                        <>
                                            <h3>{browser.sequence}</h3>
                                            {
                                                browser.sequence === 'Continuously' ?
                                                    <p>The run for <b>{browser.selectedBrowsers[1]} </b>
                                                        will start as soon as the <b>{browser.selectedBrowsers[0]} </b>
                                                        is completed </p> :
                                                    <p> Eg. The run for <b>{browser.selectedBrowsers[0]}</b> will
                                                        start {run.runOption ? 'now' : run.runDate ? 'run.runDate' : '{On Selected Day}'}.
                                                        The run for <b>{browser.selectedBrowsers[1]}</b> will
                                                        start {browser.runInterval} minutes later </p>
                                            }
                                        </>
                                    }
                                    color={colors.green.bamboo}
                                >
                                    <InfoCircleOutlined style={Object.assign({}, {marginLeft:15}, icon_style)}/>
                                </Tooltip>
                            </Col>
                        </RowC>
                        {
                            browser.sequence === 'Continuously'? null:
                                <RowC>
                                    <Col flex={label_column_width}>Interval</Col>
                                    <Col flex="auto">
                                        <InputNumber
                                            min={0}
                                            keyboard
                                            style={{width:70}}
                                            defaultValue={browser.runInterval}
                                            onChange={e=>browser.setRunInterval(e?e:0)}/> Minutes
                                    </Col>
                                </RowC>
                        }
                    </Space>
                    : null
            }
            <RowC>
                <Col flex={icon_column_width}> <RedoOutlined style={icon_style} /></Col>
                <Col flex={label_column_width}>Reruns</Col>
                <Col flex="auto">
                    <InputNumber min={0}
                                 keyboard
                                 style={{width:60}}
                                 defaultValue={reruns}
                                 onChange={handleChangeReruns}/>
                </Col>
            </RowC>
            <RowC>
                <Col flex={icon_column_width}> <ClusterOutlined style={icon_style}/></Col>
                <Col flex={label_column_width}>Parallel</Col>
                <Col flex="auto">
                    <CheckboxC checked={useMaxParallel}
                               onChange={()=>setUseMaxParallel(!useMaxParallel)}
                               label='max'/>
                    <InputNumber min={1}
                                 keyboard
                                 value={maxParallel<0? 1: maxParallel}
                                 onChange={(e)=>handleSetMaxParallel(e)}
                                 disabled={useMaxParallel}
                                 defaultValue={maxParallel<0? 1: maxParallel} />
                </Col>
            </RowC>
            <RowC>
                <Col flex={icon_column_width}> <CalendarOutlined style={icon_style} /></Col>
                <Col flex={label_column_width}>Run</Col>
                <Col flex="auto">
                    <Segmented
                        options={['Now', 'Manually', 'Scheduled']}
                        value={run.runOption}
                        onChange={handleSetRunNow}
                    />
                <Tooltip
                placement="right"
                title={
                        <>
                            <h3>{run.runOption}</h3>
                            {
                                run.runOption === 'Now' ?  <p>Starts the execution immediately after the Run Creation.</p> : null  
                            }
                            {
                                run.runOption === 'Manually' ?  <p>Saves the Run after the Run Creation but the execution will not start.
                                You should start the execution manually when you desire.</p> : null  
                            }
                            {
                                run.runOption === 'Scheduled' ?  <p>Provides the desired execution Schedule Plan.</p> : null  
                            }
                        </>
                        }
                color={colors.green.bamboo}
                                >
                    <InfoCircleOutlined style={Object.assign({}, {marginLeft:15}, icon_style)}/>
                </Tooltip>
                </Col>
            </RowC>
            {run.runOption === 'Now' && 
            <Space direction="vertical" size="large" style={styleHiddenRow}>
            </Space>}
            {run.runOption === 'Manually' && 
            <Space direction="vertical" size="large" style={styleHiddenRow}>
            </Space>}
            {
                run.runOption === 'Scheduled'?
                    <Space direction="vertical" size="large" style={styleHiddenRow}>
                        <RowC>
                            <Col flex={label_column_width}>Select Date:</Col>
                            <Col flex="auto">
                                <FutureDatepicker
                                    selectedDate={run.runDate}
                                    disabled={run.isRecurring && run.recurringOption !== 'Day'}
                                    setSelectedDate={run.setRunDate}/>
                            </Col>
                        </RowC>
                        <RowC>
                            <Col flex={label_column_width}>Recurring?</Col>
                            <Col flex="auto">
                                <Switch
                                    onChange={()=>run.setIsRecurring(!run.isRecurring)}
                                    checked={run.isRecurring}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Col>
                        </RowC>
                        {
                            run.isRecurring?
                                <>
                                    <RowC>
                                        <Col flex={label_column_width}>Run Every</Col>
                                        <Col flex="auto">
                                            <Segmented
                                                value={run.recurringOption}
                                                onChange={handleSetRecurringOption}
                                                options={['Day', 'Week', 'Month', 'Quarter']} />
                                        </Col>
                                    </RowC>
                                    {run.recurringOption === 'Day' && 
                                    <RowC>
                                    <Col flex={label_column_width}>Daily on: </Col>
                                    <span>{run.runDate ? run.runDate.format('HH:mm'): `N / A`}</span>
                                    </RowC>}
                                    {run.recurringOption === 'Week' && 
                                    <RowC>
                                    <Col flex={label_column_width}>Weekly on: </Col>
                                    <Select
                                    showSearch
                                    placeholder="Select day"
                                    optionFilterProp="children"
                                    value={run.runRecurringWeeklyDate}
                                    onChange={handleSetRunRecurringWeeklyDate}
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                    {
                                        value: 'Sunday',
                                        label: 'Sunday',
                                    },
                                    {
                                        value: 'Monday',
                                        label: 'Monday',
                                    },
                                    {
                                        value: 'Tuesday',
                                        label: 'Tuesday',
                                    },
                                    {
                                        value: 'Wednesday',
                                        label: 'Wednesday',
                                    },
                                    {
                                        value: 'Thursday',
                                        label: 'Thursday',
                                    },
                                    {
                                        value: 'Friday',
                                        label: 'Friday',
                                    },
                                    {
                                        value: 'Saturday',
                                        label: 'Saturday',
                                    }
                                    ]}
                                     />
                                     <TimePicker value={run.runRecurringDate} onChange={handleSetRunRecurringDate} style={{marginLeft: 10}}/>
                                    </RowC>}
                                    {run.recurringOption === 'Month' && 
                                    <RowC>
                                    <Col flex={label_column_width}>Monthly at the first: </Col>
                                      <Select
                                    style={{marginLeft: 10}}
                                    showSearch
                                    placeholder="Select day"
                                    optionFilterProp="children"
                                    value={run.runRecurringWeeklyDate}
                                    onChange={handleSetRunRecurringWeeklyDate}
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                    {
                                        value: 'Sunday',
                                        label: 'Sunday',
                                    },
                                    {
                                        value: 'Monday',
                                        label: 'Monday',
                                    },
                                    {
                                        value: 'Tuesday',
                                        label: 'Tuesday',
                                    },
                                    {
                                        value: 'Wednesday',
                                        label: 'Wednesday',
                                    },
                                    {
                                        value: 'Thursday',
                                        label: 'Thursday',
                                    },
                                    {
                                        value: 'Friday',
                                        label: 'Friday',
                                    },
                                    {
                                        value: 'Saturday',
                                        label: 'Saturday',
                                    }
                                    ]}
                                     />
                                     <Col style={{marginLeft: 10, marginTop: 1}}>at: </Col>
                                     <TimePicker value={run.runRecurringDate} onChange={handleSetRunRecurringDate} style={{marginLeft: 10}}/>
                                    </RowC>}
                                    {run.recurringOption === 'Quarter' && 
                                    <RowC>
                                    <Col flex={label_column_width}>Quarterly on: </Col>
                                    <Select
                                    showSearch
                                    placeholder="Select quarter"
                                    optionFilterProp="children"
                                    value={run.runRecurringQuarterlyDate}
                                    onChange={handleSetRunRecurringQuarterlyDate}
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                    {
                                        value: '1st',
                                        label: '1st',
                                    },
                                    {
                                        value: '2nd',
                                        label: '2nd',
                                    },
                                    {
                                        value: '3rd',
                                        label: '3rd',
                                    },
                                    {
                                        value: '4th',
                                        label: '4th',
                                    }
                                    ]}
                                     />
                                     <Col style={{marginLeft: 10, marginTop: 1}}>on : </Col>
                                    <Select
                                    style={{marginLeft: 10}}
                                    showSearch
                                    placeholder="Select month"
                                    optionFilterProp="children"
                                    value={run.runRecurringMonthlyDate}
                                    onChange={handleSetRunRecurringMonthlyDate}
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={quartelyMonthsOptions()}
                                     />
                                      <Col style={{marginLeft: 10, marginTop: 1}}>at the first: </Col>
                                      <Select
                                    style={{marginLeft: 10}}
                                    showSearch
                                    placeholder="Select day"
                                    optionFilterProp="children"
                                    value={run.runRecurringWeeklyDate}
                                    onChange={handleSetRunRecurringWeeklyDate}
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                    {
                                        value: 'Sunday',
                                        label: 'Sunday',
                                    },
                                    {
                                        value: 'Monday',
                                        label: 'Monday',
                                    },
                                    {
                                        value: 'Tuesday',
                                        label: 'Tuesday',
                                    },
                                    {
                                        value: 'Wednesday',
                                        label: 'Wednesday',
                                    },
                                    {
                                        value: 'Thursday',
                                        label: 'Thursday',
                                    },
                                    {
                                        value: 'Friday',
                                        label: 'Friday',
                                    },
                                    {
                                        value: 'Saturday',
                                        label: 'Saturday',
                                    }
                                    ]}
                                     />
                                     <Col style={{marginLeft: 10, marginTop: 1}}>at: </Col>
                                     <TimePicker value={run.runRecurringDate} onChange={handleSetRunRecurringDate} style={{marginLeft: 10}}/>
                                    </RowC>}
                                    <RowC>
                                        <Col flex={label_column_width}>End Recurring?</Col>

                                        <Col flex="auto">
                                            <Switch
                                                onChange={()=>{run.setEndRecurring(!run.endRecurring); if (run.endRecurring || run.endRecurring === undefined) {run.setEndDate(null)}}}
                                                checked={run.endRecurring}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                            />
                                        </Col>
                                    </RowC>
                                    {run.endRecurring && 
                                        <RowC>
                                            <Col flex={label_column_width}>Select Date:</Col>
                                            <Col flex="auto">
                                                <FutureDatepicker
                                                    selectedDate={run.endDate}
                                                    setSelectedDate={run.setEndDate}/>
                                            </Col>
                                        </RowC>}
                                </>

                                :null
                        }
                    </Space>
                    :null
            }
        </Space>
    )
}

export default RunConfigSettings