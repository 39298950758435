import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from 'antd';
import { FormInstance } from "antd/lib/form";
import { Job } from "../../models/entities/job";
import { PageResource } from "../../models/dtos/page_resource";
import { InputGenerateColor } from "../form/generate_color";
import { createJob, updateJob, deleteJob } from "../../redux/actions/job_actions";
import { deleteFromArray } from '../../assets/helpers/helper';
import { empty_job } from "../../services/EmptyEntities/EmptyJob";
import ModalC from "../modal";
import ButtonC from "../button";
import InputC from "../input";
import {useAppContext} from "../../AppContext";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";


type JobProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    setJobsPage: Dispatch<SetStateAction<PageResource<Job>>>;
    selectedJob: Job;
    setJobSelected: Dispatch<SetStateAction<Job>>;
    jobPage: PageResource<Job>;
}

export function JobModal(props: JobProps):JSX.Element{
    //props    
    const {setVisible, visible, setJobsPage, selectedJob, setJobSelected, jobPage} = props;
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg} = useAppContext();
    // form state
    const [componentSize, setComponentSize] = useState();
    const [form] = Form.useForm();
    const [name, setName] = useState<string>("");


    const [description, setDescription] = useState<string>("");
    const [color, setColor] = useState<string>("");

    const org_id = currentOrg.id

    const onCreate = (values:any) => {
        if(values.color[0] !== "#"){ values.color=`#${values.color}`}

        if (!selectedJob.id){
            const new_job:Omit<Job, 'id'> = {
                name: values.name, 
                description: values.description, 
                org_id: org_id, 
                color: values.color}
            dispatch(createJob(new_job, jobPage.content))
            .then(foundPage => {jobPage.content=foundPage
                setJobsPage(jobPage)}).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }else{
            const new_job:Job = {
                id: selectedJob.id, 
                name:values.name, 
                description:values.description, 
                org_id: org_id, 
                color:values.color}
            dispatch(updateJob(new_job, jobPage.content))
            .then(foundPage => {jobPage.content=foundPage
                 setJobsPage(jobPage)}).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
        setName('');
        setDescription('');
        setColor('');
        setTimeout(() => {
            setVisible(false)
        }, 500);
    }

    const handleOk = (form:FormInstance<any>) => {
        form
            .validateFields()
            .then(values => {
                // form.resetFields();
                onCreate(values)
        })
        .catch(info => {
            // console.log('Validate Failed:', info);
        });
    }

    useEffect(() => {
        setName(selectedJob.name)
        setDescription(selectedJob.description)
        setColor(selectedJob.color)
    }, [selectedJob])

    useEffect(()=>{
        form.setFieldsValue({
            name: name,
            description: description,
            color: color
        })
        
    })

    const handleCancel = () => {
        setVisible(false)
        setJobSelected(empty_job)
    };

    const handleDelete = (job_id:number) => {
        dispatch(deleteJob(job_id))
            .then(()=>{
                const jobs:Job[] = jobPage.content
                const job_to_delete = jobs.find(job => job.id === job_id);
                if(job_to_delete){
                    const index = jobs.indexOf(job_to_delete)
                    jobPage.content = deleteFromArray(jobs, index);
                    setJobsPage(jobPage)
                }
                setVisible(false)
                setName('')
                setDescription('')
                setColor('#fff')
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

    }

    const onFormLayoutChange = ({ size } :any) => {
    setComponentSize(size);
    };

    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>
        ,
        <ButtonC key="submit_ok" type="primary" onClick={()=>handleOk(form)} text={selectedJob===empty_job? 'Create' : 'Save'}/>
        ]

    return <ModalC 
            open={visible}
            title={selectedJob===empty_job? 'Add New Job' : 'Edit Job'}
            onOk={() => handleOk(form)}
            onCancel={handleCancel}
            footer={selectedJob===empty_job? footer_buttons:[
                <ButtonC key="submit_delete_job" type="primary" style={{float:'left'}} danger onClick={()=>handleDelete(selectedJob.id)} text="Delete"/>
                , ...footer_buttons]}>
                    {<Form form={form}
                            name="createJob"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            initialValues={{ size: componentSize }}
                            onValuesChange={onFormLayoutChange}
                            size={componentSize}
                        >
                        <Form.Item name="name" label="Job Title" >
                            <InputC value={name} 
                                            onChange={(event) => setName(event.target.value)} 
                                            placeholder="Enter Job Title"/>                    
                        </Form.Item>
                        <Form.Item name="description" label="Description">
                            <InputC value={description} 
                                            onChange={(event) => setDescription(event.target.value)} 
                                            placeholder="Enter A Short Job Description"/>   
                        </Form.Item>
                        <InputGenerateColor form_id="createJob"
                                            setColor={setColor}
                                            color={color}
                                            infoText= "This will be used in charts"
                                            />
                        
                    </Form>}
                </ModalC>
            
    
}