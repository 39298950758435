import React from "react";
import {CopyOutlined} from "@ant-design/icons";
import ButtonC from "../button";
import {notification} from "antd";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";

type CopyToClipboardProps = {
    text: string,
    style?: React.CSSProperties,
    type?: 'text' | 'icon'
};

export function copyToClipboard(text:string) {
    navigator.clipboard.writeText(text)
        .then(() => {
            notification.success({
                message: 'Text Copied',
                placement: 'bottomLeft',
                duration: 0.8
            })
        })
        .catch(error => {
            safeHandleErrorResponse(error)
        });

}

function CopyToClipboard({text, style, type}:CopyToClipboardProps): JSX.Element {

    return type && type==='text'?
        <ButtonC style={style}
                 onClick={()=>copyToClipboard(text)}
                 text={'copy'}
                 type={'text'}
        />
        :<ButtonC style={style}
                  onClick={()=>copyToClipboard(text)}
                  icon={<CopyOutlined />}
        />

}
export default CopyToClipboard;

