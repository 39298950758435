import React, { useEffect, useState } from 'react';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import { TestCase } from '../../models/entities/test_case_info';
import { useParams } from 'react-router-dom';
import { empty_test_case } from '../../services/EmptyEntities/EmptyTestCase';
import { getCases} from '../../redux/actions/cases';
import TestSuiteInfo from "./test_suite_info";
import {hasOnlyNumbers} from "../../assets/helpers/helper";
import {useAppContext} from "../../AppContext";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';


function TestCaseInfo(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const [test_case_info, setTest_case_info] = useState<TestCase>(empty_test_case);
    let { case_id } = useParams();
    const {currentProject} = useAppContext()

    useEffect(() => {
        if (case_id && hasOnlyNumbers(case_id)){
            dispatch(getCases({project_id: currentProject.id, case_id: case_id as unknown as number}))
                .then(t_cases=>{
                    if(t_cases.content.length>0){
                        setTest_case_info(t_cases.content[0])
                    }else {
                        // console.log('err', t_cases)
                    }
                })
                .catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
        else{
            // console.log('Go to 404 error page')
        }

    }, [dispatch]);

    return(
        <Page title="Test Case Info">
            <PageHeader title={`Title: ${test_case_info.title}`}/>
            <PageContent>
                <TestSuiteInfo openedInfo={test_case_info} setOpenedInfo={setTest_case_info}/>
            </PageContent>
        </Page>
    )
}

export default TestCaseInfo;
