import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppContext} from '../../AppContext';
import {AppDispatch} from '../../redux/store';
import './login.css';
import {Button, Card, Col, Divider, Steps} from 'antd';
import RowC from '../../components/row';
import {Navigate, useNavigate} from 'react-router-dom';
import {LogoutOutlined, SwapRightOutlined} from '@ant-design/icons';
import {createOrganization} from '../../redux/actions/org_actions';
import {logoutUser} from '../../redux/actions/user_actions';
import ButtonC from "../../components/button";
import {stringHasValue} from "../../assets/helpers/helper";
import InputC from "../../components/input";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import { empty_project } from '../../services/EmptyEntities/EmptyProject';

const CreateOrg: React.FC = () => {
    const [step, setStep] = useState<number>(0);
    const [orgTitle, setOrgTitle] = useState<string>('');
    const [orgKey, setOrgKey] = useState<string>('');
    const [projectTitle, setProjectTitle] = useState<string>('');
    const [projectKey, setProjectKey] = useState<string>('');
    const [projectTeams, setProjectTeams] = useState<string>('');
    const dispatch: AppDispatch = useDispatch();

    const {loggedInUser, currentOrg, resetApp} = useAppContext();

    const {
        userToken,
        setAppContextOrg,
        setAppContextProject,
    } = useAppContext();
    const navigate = useNavigate();
    const isAuthenticated = stringHasValue(userToken);

    if (!isAuthenticated) {
        return <Navigate to='/'/>
    }
    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const onCreate = () => {
        type Params = { name: string, domain: string, user_id: number, project_name?: string, project_key?: string, teams?: string[] }
        let params: Params = {name: orgTitle, domain: orgKey, user_id: loggedInUser.id}
        if (projectTitle.trim() !== '') {
            params.project_name = projectTitle;
        }
        if (projectKey.trim() !== '') {
            params.project_key = projectKey;
        }
        if (projectTeams.length > 0) {
            params.teams = projectTeams.split(',').map(item => item.trim());
        }
        dispatch(createOrganization(params))
            .then((found) => {
                setAppContextOrg(found.organization);
                if (projectTitle.trim() !== '') {
                    setAppContextProject(found.project)
                }
                navigate(`/${projectKey}/overview`);
            }).catch((err) => {
                setAppContextProject(empty_project)
                safeHandleErrorResponse(err)
            });
    };

    const all_steps: any = {
        0: {
            description: 'Organization',
            childes: step === 0 && <>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <InputC
                        value={orgTitle}
                        placeholder="Please, provide the title of your organization..."
                        onChange={(e) => {
                            setOrgTitle(e.target.value);
                            setOrgKey(e.target.value.toLocaleUpperCase().replace(/\s/g, ''))
                        }}
                        style={{width: '40%', textAlign: 'center'}}
                    />
                    <InputC
                        value={orgKey}
                        placeholder="Organization KEY"
                        onChange={(e) => setOrgKey(e.target.value)}
                        style={{width: '40%', marginTop: 10, textAlign: 'center'}}
                    />
                    {orgTitle === '' ?
                        <p style={{color: '#F6C324'}}>Organization Title is required!</p> : null}
                </div>
                <Button type="primary" onClick={nextStep}
                        style={{marginRight: 20, color: 'white', background: 'grey', float: 'right'}}
                        disabled={orgTitle === ''}>
                    Next Step
                </Button></>
        },
        1: {
            description: 'Project',
            disabled: orgTitle === '',
            childes: step === 1 && <>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <InputC
                        value={projectTitle}
                        placeholder="Please, provide the title of your organization project..."
                        onChange={(e) => {
                            setProjectTitle(e.target.value);
                            setProjectKey(e.target.value.split(' ').map(word => word.charAt(0).toUpperCase()).join(''))
                        }}
                        style={{width: '40%', textAlign: 'center'}}
                    />
                    <InputC
                        value={projectKey}
                        placeholder="Project KEY"
                        onChange={(e) => setProjectKey(e.target.value)}
                        style={{width: '40%', marginTop: 10, textAlign: 'center'}}
                    />
                </div>
                <div>
                    <Button style={{marginLeft: 20, color: 'white', background: 'grey', float: 'left'}}
                            onClick={prevStep}>
                        Previous Step
                    </Button>
                    <Button type="primary" onClick={nextStep}
                            style={{marginRight: 20, color: 'white', background: 'grey', float: 'right'}}>
                        Next Step
                    </Button></div>
            </>
        },
        2: {
            description: 'Teams',
            disabled: orgTitle ==='',
            childes: step === 2 && <>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <InputC
                        value={projectTeams}
                        placeholder="Please, provide the titles of your organization teams...(comma separated)"
                        onChange={(e) => setProjectTeams(e.target.value)}
                        style={{width: '40%', textAlign: 'center'}}
                    />
                </div>
                <div>
                    <Button style={{marginLeft: 20, color: 'white', background: 'grey', float: 'left'}}
                            onClick={prevStep}>
                        Previous Step
                    </Button>
                    <Button type="primary" onClick={nextStep}
                            style={{marginRight: 20, color: 'white', background: 'grey', float: 'right'}}>
                        Next Step
                    </Button></div>
            </>
        },
        3: {
            description: 'Preview',
            disabled: orgTitle === '',
            childes:
                step === 3 && <div>
                    <RowC gutter={25} style={{paddingTop: 25}}>
                        <Col span={8}>
                            <Card style={{height: 'auto', marginLeft: 50}}
                                  hoverable={true}
                                  title={<div style={{textAlign: 'center', color: 'grey'}}>Organization</div>}
                            >
                                <h4 style={{textAlign: 'center', color: 'grey'}}> Title: {orgTitle} </h4>
                                <h4 style={{textAlign: 'center', color: 'grey'}}> KEY: {orgKey} </h4>
                            </Card>
                        </Col>
                        <Col span={7}>
                            <Card hoverable={true} title={<div style={{textAlign: 'center', color: 'grey'}}>Project</div>}
                                  style={{height: 'auto'}}>
                                <h4 style={{textAlign: 'center', color: 'grey'}}> Title: {projectTitle} </h4>
                                <h4 style={{textAlign: 'center', color: 'grey'}}> KEY: {projectKey} </h4>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={<div style={{textAlign: 'center', color: 'grey'}}>Teams</div>}
                                  style={{height: 'auto'}} hoverable={true}>
                                {
                                    projectTeams.split(',').map((item, index) => {
                                        const temp_team = item.trim()
                                        return <h4 style={{textAlign: 'center', color: 'grey'}} key={index}>{temp_team}</h4>
                                    })
                                }
                            </Card>
                        </Col>
                    </RowC>
                    <div>
                        <Button style={{marginLeft: 20, color: 'white', background: 'grey', float: 'left'}}
                                onClick={prevStep}>
                            Previous Step
                        </Button>
                        <Button htmlType='submit' onClick={onCreate} disabled={orgTitle === ''}
                                style={{marginRight: 20, color: 'white', background: 'grey', float: 'right'}}>
                            Create
                        </Button>
                    </div>
                </div>
        }
    }

    const get_step_items = (steps: any): any[] => {
        return Object.keys(steps).map((step_item: any, key: any) => {
            return {
                title: `Step ${key + 1}`,
                description: steps[step_item].description,
                disabled: steps[step_item].disabled
            }
        })
    }


    return (
        <div>
            <div style={{marginLeft: '-27%', width: '100%'}}>
                <ButtonC text='Logout'
                         onClick={() => {
                             dispatch(logoutUser(loggedInUser.id))
                                 .then(() => {
                                     resetApp()
                                     navigate('/login')
                                 }).catch((err) => {
                                    safeHandleErrorResponse(err)
                                })
                         }} icon={<LogoutOutlined/>}
                         size='large'
                         style={{float: 'right', marginRight: 20, marginBottom: 20}}/>
                {
                    !!currentOrg.id ? <ButtonC
                        text='Back to Home'
                        onClick={() => {
                            navigate('/')
                        }}
                        icon={<SwapRightOutlined/>}
                        size='large'
                        style={{float: 'right', marginRight: 20, marginBottom: 20}}
                    /> : null
                }
            </div>
            <Divider/>
            <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Steps
                    current={step}
                    onChange={setStep}
                    percent={all_steps[step].percent}
                    direction="horizontal"
                    items={get_step_items(all_steps)}
                    style={{width: '110%'}}
                />
            </Col>
            <Divider/><br/>
            <Col flex='auto'>
                {all_steps[step].childes}
            </Col>
        </div>
    );
};

export default CreateOrg;
