import { Component } from "../../models/entities/component";

export const empty_component:Component = {
    id: -1,
    title: "",
    org_id: -1,
    description: "",
    project_id: -1,
    cases: 0,
    owner: {
        id: -1,
        fullname: ""
    },
    created_at: "",
    creator: {
        id: -1,
        fullname: ""
    }
}