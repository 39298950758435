import React from 'react';
import { Table } from 'antd';

type TestReviewsProps = {
    data: any[]
}

function TestReviewsTable({data}:TestReviewsProps): JSX.Element {
    const header_style:React.CSSProperties = {textAlign: 'center'}
    const columns: object[] = [{
        title: <b>Runs</b>,
        dataIndex: "runs",
        align: 'center',
        key: "runs",
        width: "5%"
    },{
        title: <div style={header_style}><b>Info</b></div>,
        dataIndex: "title",
        key: "title",
        width: "50%"
    },{
        title: <b>Reviewers</b>,
        dataIndex: "reviewers",
        align: 'center',
        key: "created",
        width: "12%"
    },{
        title: <b>Progress</b>,
        dataIndex: "progress",
        key: "progress",
        align: 'center',
        width: "30%"
    },{
        dataIndex: "actions",
        key: "actions",
        align: 'center',
        width: "3%"
    }]

    const rowClassName = (record:any) => {
        return record.system_status === -10? 'error-run-row': ''
    }

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowClassName={rowClassName}
        />
    )
}

export default TestReviewsTable;
