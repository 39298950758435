import React, { useState } from 'react';
import { Input, Typography, Space, Button, DatePicker, TimePicker } from 'antd';
import { Dayjs } from 'dayjs';

const { TextArea } = Input;
const { Text } = Typography;

const TimestampConverter: React.FC = () => {
    const [timestampInput, setTimestampInput] = useState<string>('');
    const [convertedDateTime, setConvertedDateTime] = useState<string>('');
    const [date, setDate] = useState<Dayjs | null>(null);
    const [time, setTime] = useState<Dayjs | null>(null);
    const [convertedTimestamp, setConvertedTimestamp] = useState<string>('');

    const handleConvert = (value: string) => {
        const timestamp = parseInt(value, 10);
        if (!isNaN(timestamp)) {
            const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
            setConvertedDateTime(date.toLocaleString());
        } else {
            setConvertedDateTime('Invalid timestamp.');
        }
    };

    const handleGetCurrentTimestamp = () => {
        const currentTimestamp = Math.floor(Date.now() / 1000); // Get current timestamp in seconds
        setTimestampInput(currentTimestamp.toString());
        handleConvert(currentTimestamp.toString());
    };

    const handleConvertToTimestamp = () => {
        if (date && time) {
            const combined = date
                .set('hour', time.hour())
                .set('minute', time.minute())
                .set('second', time.second())
                .unix(); // Get UNIX timestamp
            setConvertedTimestamp(combined.toString());
        } else {
            setConvertedTimestamp('Please select both date and time.');
        }
    };

    return (
        <Space direction="vertical" size="large" style={{ width: '97%' }}>
            <Text strong>Timestamp to DateTime Converter</Text>
            <TextArea
                rows={4}
                value={timestampInput}
                onChange={(e) => {
                    setTimestampInput(e.target.value);
                    handleConvert(e.target.value);
                }}
                placeholder="Enter UNIX timestamp here"
            />
            <Button type="primary" onClick={handleGetCurrentTimestamp}>
                Get Current Timestamp
            </Button>
            <Text strong>Converted Date and Time:</Text>
            <TextArea
                rows={4}
                value={convertedDateTime}
                readOnly
                style={{ backgroundColor: '#f5f5f5' }}
            />

            <Text strong>Convert Date and Time to Timestamp</Text>
            <Space>
                <DatePicker
                    style={{ width: '100%' }}
                    onChange={(value) => setDate(value)}
                    placeholder="Select date"
                />
                <TimePicker
                    style={{ width: '100%' }}
                    onChange={(value) => setTime(value)}
                    placeholder="Select time"
                />
            </Space>

            <Button type="primary" onClick={handleConvertToTimestamp}>
                Convert to Timestamp
            </Button>
            <Text strong>Converted Timestamp:</Text>
            <TextArea
                rows={4}
                value={convertedTimestamp}
                readOnly
                style={{ backgroundColor: '#f5f5f5' }}
            />
        </Space>
    );
};

export default TimestampConverter;
