import React, {useEffect, useState} from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {useAppContext} from "../../AppContext";
import {PageResource} from "../../models/dtos/page_resource";
import {Organization} from "../../models/entities/organization";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";
import {getMyOrgs} from "../../redux/actions/user_actions";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import { CardIcons } from '../../components/cards/card_icon';
import { CardItem } from '../../models/entities/helpers/cardItem';
import { Divider, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";


function MyOrgs(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();
    
    const { loggedInUser, setAppContextOrg } = useAppContext();

    const [orgPage, setOrgPages] = useState<PageResource<Organization>>(createEmptyPage())
    const [orgOriginalPage, setOrgOriginalPages] = useState<PageResource<Organization>>(createEmptyPage())
    const [search, setSearch] = useState<string>('')

    useEffect(() => {
        dispatch(getMyOrgs({user_id:loggedInUser.id}))
            .then(foundPage => {
            setOrgPages(foundPage)
            setOrgOriginalPages(foundPage)
        })
            .catch(err=> {
                safeHandleErrorResponse(err)
                // console.log(err)
            })

    }, [dispatch])

    useEffect(() => {
        if (search === '') {
            setOrgPages(orgOriginalPage)
        }
        else 
            {setOrgPages(prevOrgPage => ({
              ...prevOrgPage, // Preserve other properties of the PageResource object
              content: prevOrgPage.content.filter(org =>
                org.name.toLowerCase().includes(search.toLowerCase())
              )
            }));}
      }, [dispatch, search]);

    const constructCardData = () => {
        
        const cardItems:CardItem[] = []
        const orgs:Organization[] = orgPage.content
        
        orgs.forEach(org=>{
            cardItems.push({
                id: org.id,
                link: `/${org.domain}/overview`,
                icon: org.icon === null ? 
                <img 
                    src={'/icons/org_icon.png'} 
                    alt="Organization Icon" 
                    style={{ width: '150px', height: '150px', display: 'block', margin: 'auto', marginTop: 10 }}
                /> : 
                <img 
                    src={org.icon} 
                    alt="Organization Icon" 
                    style={{ width: '150px', height: '150px' , display: 'block', margin: 'auto', marginTop: 10}}
                />,
                name: org.name,
                description: '',
                onclick: ()=> setAppContextOrg(org)
            })
        })
        return cardItems
    }

    return (
        <Page title="My Organizations">
            <PageHeader title={`My Organizations`}
            buttonHeader={{ type: "create", onClickFunction: () => navigate('/create_org') }}/>
            <PageContent>
            <Input
                style={{ width: 200 }}
                placeholder="Search for organization"
                prefix={<SearchOutlined />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <Row>
            <Divider orientation="left"> All Organizations </Divider>
            {CardIcons(constructCardData())}
            </Row>
            </PageContent>
        </Page>
    )
}

export default MyOrgs;
