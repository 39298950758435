import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Form, Menu, MenuProps} from 'antd';
import {FormInstance} from "antd/lib/form";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import ModalC from "../modal";
import ButtonC from "../button";
import {InputOnClick} from "../input";
import {AddSection} from "../../models/entities/menu";
import {DataNode} from "antd/lib/tree";
import CheckboxC from "../checkbox";
import {addSection, deleteSection, updateSection} from "../../redux/actions/section_actions";
import {addCase, getCaseComponents, updateCase, updateCaseComponents} from "../../redux/actions/cases";
import {DownloadOutlined} from "@ant-design/icons";
import UploadDNDC from "../uploaders/drag_n_drop";
import {useAppContext} from "../../AppContext";
import {getComponents} from "../../redux/actions/component_actions";
import {colors} from "../../assets/data/colors";
import {CaseForm, SectionForm} from "../form/addSectionForm";
import { TestCase } from "../../models/entities/test_case_info";
import { empty_test_case } from "../../services/EmptyEntities/EmptyTestCase";
import { ComponentDD } from "../../models/entities/component";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";


type NewFileProps = {
    visible: boolean,
    setVisible: Dispatch<SetStateAction<boolean>>,
    createType: AddSection,
    setCreateType?: Dispatch<SetStateAction<AddSection>>,
    setSelectedSection?: Dispatch<SetStateAction<DataNode>>,
    selectedSection?: DataNode,
    treeData?: DataNode[],
    setTreeData?: Dispatch<SetStateAction<DataNode[]>>,
    selectedSections?: DataNode[],
    setSelectedSections?: Dispatch<SetStateAction<DataNode[]>>,
    selectedCase?: TestCase,
    setSelectedCase?: Dispatch<SetStateAction<TestCase>>,
    copy?: boolean;
    setCopy?: Dispatch<SetStateAction<boolean>>;
    cases?: TestCase[],
    setCases?: Dispatch<SetStateAction<TestCase[]>>
}


export function NewFolderModal(props: NewFileProps): JSX.Element {
    //props
    const {createType, setCreateType, setVisible, visible, selectedSection, setSelectedSection,
        treeData, setTreeData, selectedSections, setSelectedSections, selectedCase, setSelectedCase, copy, setCopy, cases, setCases} = props;

    const dispatch: AppDispatch = useDispatch();
    const {loggedInUser, currentProject, currentOrg} = useAppContext()
    const [form] = Form.useForm();

    // Modal state
    const [keepWindowOpen, setKeepWindowOpen] = useState<boolean>(false);
    const [editSectionTitle, setEditSectionTitle] = useState<string>('');
    const [hasModalBeenOpened, setHasModalBeenOpened] = useState(false);

    // const [sectionTags, setSectionTags] = useState<Component[]>([]);

    // File form state
    const [caseTitle, setCaseTitle] = useState<string>("");
    const [caseDescription, setCaseDescription] = useState<string>("");
    const [casePriority, setCasePriority] = useState<number>(4);
    const [caseSteps, setCaseSteps] = useState<string[]>([""]);
    const [caseComponents, setCaseComponents] = useState<ComponentDD[]>([]);
    const [selectedComponents, setSelectedComponents] = useState<ComponentDD[]>([]);
    const [subMenuItems, setSubMenuItems] = useState<MenuProps['items']>([{ label: 'Section', key: 'Section' }]);

    // const
    let parent_id = selectedSection ? parseInt(selectedSection.key.toString()) : -1

    const resetState = () => {
        setVisible(false)
        setCopy && setCopy(false)
        setCaseTitle('')
        setCaseDescription('')
        setCasePriority(4)
        setCaseSteps([""])
        setSelectedComponents([])
        setSelectedCase && setSelectedCase(empty_test_case)
    }

    useEffect(() => {
        setCaseTitle(selectedCase?.title || "");
        setCaseDescription(selectedCase?.description || "");
        setCasePriority(selectedCase?.priority || 4);
    
        try {
            const steps = selectedCase?.steps && typeof(selectedCase.steps) === 'string' ? JSON.parse(selectedCase.steps) : [];
            setCaseSteps(Array.isArray(steps) && steps.length > 0 ? steps : [""]);
        } catch (e) {
            // console.error('Error parsing steps JSON', e);
            setCaseSteps([""]);
        }
    }, [selectedCase]);

    useEffect(() => {
        if (selectedCase && selectedCase.id > 0){
            dispatch(getCaseComponents(selectedCase.id))
                .then(components => {
                    const comps = components.map((component) => {
                        return {
                            id: component.id,
                            title: component.title,
                            value: component.id,
                            label: component.title
                        }
                    })
                    setSelectedComponents(comps)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        } else{
            setSelectedComponents([])
        }
     }
    , [selectedCase]);

    // construct menu. Dont show Case on Project Root Folder
    useEffect(() => {
        if ((selectedSection !== undefined ? selectedSection.key as number : -1) > 0) {
            setSubMenuItems(prevItems => {
                const items = prevItems ?? []; // Default to an empty array if prevItems is undefined
                // Ensure 'Case' is added only once
                if (!items.some(item => item?.key === 'Case')) {
                    return [...items, { label: 'Case', key: 'Case' }];
                }
                return items;
            });
        }
    }, [selectedSection]);
    
    useEffect(() => {
        if (selectedCase !== empty_test_case && selectedCase !== null && selectedCase !== undefined) {
            setSubMenuItems([{ label: 'Case', key: 'Case' }]);
        }
    }, [selectedCase]);

    useEffect(() => {
        if (visible && !hasModalBeenOpened) {
            setHasModalBeenOpened(true); // Mark that the modal has been opened
        }
    }, [visible, hasModalBeenOpened]);

    // load components
    useEffect(() => {
        if(hasModalBeenOpened){
            const params = {
            org_id: currentOrg.id,
            project_id: currentProject.id
            }
            dispatch(getComponents(params))
                .then(foundPage => {
                        const components = foundPage.content.map((component) => {
                        return {
                            id: component.id,
                            title: component.title,
                            value: component.id,
                            label: component.title
                        }
                    })
                    setCaseComponents(components)
                })
                .catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
    }, [dispatch, hasModalBeenOpened])

    // handle Modal
    const onCreate = (values: any) => {
        switch (createType) {
            case 'Case':
                const file_data = {
                    section_id: selectedSection ? selectedSection.key : -1,
                    title: caseTitle,
                    priority: casePriority,
                    components: (selectedCase !== empty_test_case && selectedCase !== null && selectedCase !== undefined) ? selectedComponents.map(item => item.id) : selectedComponents,
                    steps: caseSteps.filter((step)=>step!==''),
                    project_id: currentProject.id,
                    org_id: currentOrg.id,
                    created_by: loggedInUser.id,
                    current_owner: loggedInUser.id,
                    description: caseDescription
                }
                
                if ((selectedCase !== empty_test_case && selectedCase !== null && selectedCase !== undefined && !copy))
                    dispatch(updateCase({project_id: currentProject.id, id: selectedCase.id, title: caseTitle, priority: casePriority, description: caseDescription, 
                                         steps: caseSteps.length === 1 && caseSteps[0] === '' ? [''] : caseSteps.filter((step) => step !== ''),
                                         components: selectedComponents
            }))
                        .then((updatedCase) => {
                            dispatch(updateCaseComponents({
                                project_id: currentProject.id,
                                id: selectedCase.id,
                                org_id: currentOrg.id,
                                components_ids: selectedComponents
                            })).then(() => {
                            if (cases)
                            {
                            // const new_cases = [...cases].map(t_case => {
                            //     if (t_case.id === selectedCase.id) {
                            //         // Overwrite selectedCase with updated t_case
                            //         return { ...t_case, ...selectedCase };
                            //     }
                            //     return t_case;
                            // })
                            setCases && setCases((prevItems) =>
                                prevItems.map((item) =>
                                  item.id === updatedCase.data.content[0].id ? updatedCase.data.content[0] : item
                                ))
                        }
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })})
                else {
                    dispatch(addCase(file_data))
                    .then(newCase => {
                        setCaseSteps([''])
                        if (setCases && cases)
                            {setCases([...cases, newCase[0]])}
                    }).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
                    
                }
                break;
            case 'Section':
                const data = {
                    org_id: currentProject.org_id,
                    project_id: currentProject.id,
                    title: values.section_title,
                    parent_id: parent_id > 0 ? parent_id : null
                }
                dispatch(addSection(data))
                    .then(newSection => {
                        // const updateTitleRecursively:any = (nodes:DataNode[])=>
                        //     nodes.map((node) => {
                        //         if (node.key === key) {
                        //             return { ...node, title: newTitle };
                        //         }
                        //
                        //         if (node.children && node.children.length > 0) {
                        //             return { ...node, children: updateTitleRecursively(node.children) };
                        //         }
                        //
                        //         return node;
                        //     });
                        //
                        // // Update the title and set the new state
                        // const updatedTree = updateTitleRecursively(tree);
                        //
                        // setTreeData?.(updatedTree);
                        // console.log('must update the sections tree with: ', newSection)
                    }).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
                break;
            case 'Upload File':
                // console.log('I m gonna Upload a file and create Folders/Files with values:', values)
                break;
        }

        if (!keepWindowOpen) {
            setVisible(false)
        }
    }

    const handleOk = (form: FormInstance) => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                if (keepWindowOpen) {
                    form.setFieldsValue({
                        continue: true
                    })
                }
                onCreate(values)
                resetState()
            })
            .catch(() => {
                // console.log('Validate Failed:', info);
            })
    }

    const handleCloseModal = () => {
        resetState()
    }

    const handleDeleteSection = () => {
        if (selectedSection !== undefined){
            dispatch(deleteSection(selectedSection.key as number))
                .then(() => {}).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
    }

    const get_footer_buttons = () => {
        let footer_buttons = [
            <ButtonC key="submit_close" onClick={handleCloseModal} text="Close"/>,
            <ButtonC key="submit_create" type="primary" onClick={() => handleOk(form)}
                     disabled={((caseTitle === '' || caseTitle === undefined || caseTitle === null) && createType === 'Case')}
                     text={copy ? 'Copy' : createType !== 'Upload File' ? (selectedCase !== empty_test_case && selectedCase !== null && selectedCase !== undefined) ? 'Update' : 'Create' :'Upload'}/>
        ]

        if (selectedSection && parseInt(selectedSection.key.toString()) > 0) {
            footer_buttons.push(
                <ButtonC key="delete_section"
                         danger
                         style={{float: 'left'}}
                         onClick={handleDeleteSection}
                         text="Delete Section"/>)
        }
        return footer_buttons
    }


    const handleChangeMenu = (e: any) => {
        if (setCreateType !== undefined)
        {
        setCreateType(e.key)}
    }

    const updateTitleByKey = (tree:DataNode[], key:number, newTitle:string) => {
        // Helper function to recursively update the title

        const updateTitleRecursively:any = (nodes:DataNode[])=>
            nodes.map((node) => {
                if (node.key === key) {
                    return { ...node, title: newTitle };
                }

                if (node.children && node.children.length > 0) {
                    return { ...node, children: updateTitleRecursively(node.children) };
                }

                return node;
            });

        // Update the title and set the new state
        const updatedTree = updateTitleRecursively(tree);

        setTreeData?.(updatedTree);
    };


    const handleEditTitle = () => {
        if (selectedSection !== undefined)
        {
        const id = selectedSection.key as number
        const params = {
            id,
            project_id: currentProject.id,
            title: editSectionTitle
        }
        dispatch(updateSection(params))
            .then(() => {
                let temp_section: DataNode = {...selectedSection}
                temp_section.title = editSectionTitle
                if (setSelectedSection !== undefined)
                {setSelectedSection(temp_section)}

                if(treeData){
                    updateTitleByKey(treeData, id, editSectionTitle);
                }

                if(selectedSections && setSelectedSections){
                    const open_sections = [...selectedSections].map((section)=>{
                        if(section.key === id){
                            section.title = editSectionTitle
                        }
                        return section
                    })
                    setSelectedSections(open_sections)
                }

            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })}
    }

    return (
        <ModalC
            open={visible}
            maskClosable={false}
            onCancel={handleCloseModal}
            width={750}
            title={<InputOnClick
                input={{
                    value: editSectionTitle,
                    setValue: setEditSectionTitle,
                    handleOk: handleEditTitle,
                    style: {width: 300}
                }}
                text={{
                    value: selectedSection ? selectedSection.title ? selectedSection.title.toString() : '' : ''
                }}
            />
            }
            footer={get_footer_buttons()}
        >
            <div style={{height: 500, overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
                <Menu onClick={handleChangeMenu}
                      mode="horizontal"
                      selectedKeys={[createType]}
                      items={subMenuItems}
                      style={{backgroundColor: colors.gray.default, marginBottom: 20}}/>
                <Form form={form}
                      style={{flex: '1'}}
                      name="createUser"
                      labelCol={{span: 4}}
                      wrapperCol={{span: 18}}
                      layout="horizontal"
                >
                    {
                        createType === 'Section' ?
                            <SectionForm />
                            : createType === 'Case' ?
                                <CaseForm
                                    caseTitle={caseTitle}
                                    setCaseTitle={setCaseTitle}
                                    caseDescription={caseDescription}
                                    setCaseDescription={setCaseDescription}
                                    casePriority={casePriority}
                                    setCasePriority={setCasePriority}
                                    caseComponents={caseComponents}
                                    setCaseComponents={setCaseComponents}
                                    selectedComponents={selectedComponents}
                                    setSelectedComponents={setSelectedComponents}
                                    setCaseSteps={setCaseSteps}
                                    caseSteps={caseSteps}
                                />

                                : <>
                                    <ButtonC text='Download Template'
                                             onClick={() => {
                                                 // console.log('Downloading', e)
                                             }}
                                             type='primary'
                                             shape='round'
                                             size='small'
                                             icon={<DownloadOutlined/>}

                                    /><br/><br/><UploadDNDC/>
                                </>
                    }

                </Form>
                {
                    createType !== 'Upload File' && (selectedCase === empty_test_case || selectedCase === null || selectedCase === undefined) ?
                    <CheckboxC
                        style={{marginTop: 'auto', marginBottom: 15}}
                        label='Keep Window Open'
                        checked={keepWindowOpen}
                        onChange={(e) => {
                            setKeepWindowOpen(e.target.checked)
                        }}/>
                    : null
                }
            </div>
        </ModalC>
    )
}