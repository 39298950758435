import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { Link} from 'react-router-dom';
import {Layout, Menu} from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
// assets
import LcsManager from "../../assets/local_storage/lcs";
// data
import { sideMenuItems } from './menu_items/sidebar_menu_items';
import {colors} from "../../assets/data/colors";
import CircularMaskImage from "../../components/img";

type SideMenuProps = {
    menu_items: sideMenuItems[],
    root: string,
    selectedSideMenu: any,
    setSelectedSideMenu: Dispatch<SetStateAction<any>>
}

function SideMenu({ menu_items, root, selectedSideMenu, setSelectedSideMenu }: SideMenuProps): JSX.Element {
    const lcs_manager = new LcsManager()
    /*
        For access with hard url
        if (org_domain != currentOrg.domain){ check if valid, get new domain and set it}
        if (project_key != currentProject.p_key){ check if valid, get new domain and set it}
        if (currentTeam.id != currentTeam.id){ check if valid, get new domain and set it}
    * */
    const isCollapsed = ():boolean => {
        const is_collapsed = lcs_manager.get_item_from_lcs(lcs_manager.Keys.is_sidemenu_collapsed)
        if(is_collapsed === null){
            lcs_manager.set_item_to_lcs(lcs_manager.Keys.is_sidemenu_collapsed, false)
            return false
        }
        return is_collapsed
    }

    const [collapsed, setCollapsed] = useState(isCollapsed)
    const [menu, setMenu] = useState<ItemType[]>([])

    useEffect(()=>{
        let items:ItemType[] = [];
        menu_items.map((item, key) => {
            if(item.has_access){
                let children = item.children? item.children.map((child, sub_key)=>{
                    return {
                        label: child.href?<Link key={child.href} to={`${root}${child.href}`}>{child.label}</Link>:child.label,
                        icon: child.icon? child.icon: item.icon,
                        key: `${key}-${sub_key}`
                    }
                }) : null

                let temp_item = {
                    label: item.href?<Link key={item.href} to={`${root}${item.href}`}>{item.label}</Link>:item.label,
                    icon: item.icon,
                    key: `${key}`,
                    children: children
                }

                items.push(temp_item)
            }
        })
        setMenu(items)
    }, [menu_items, root])


    const toggle = () => {
        setCollapsed(!collapsed)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.is_sidemenu_collapsed, !collapsed)
    }

    return(
        <Layout.Sider style={{backgroundColor: colors.gray.dark, color: '#fff'}} trigger={null} collapsible collapsed={collapsed}>
            <div className='site-layout-sidebar'>
                <div className="logo" onClick={toggle} > {
                    collapsed? <CircularMaskImage width={40} height={37} gradientFromCenter={10} imageUrl='/icons/tp_logo.png' />
                        :<img style={{borderRadius: '0.8em'}} alt='Logo' src='/icons/TestingPandaWordLogo.png'/>

                } </div>
                <Menu mode='inline'
                      style={{backgroundColor: 'inherit', color: 'inherit', fontSize:15}}
                      items={menu}
                      selectedKeys={selectedSideMenu}
                      onSelect={(e)=>setSelectedSideMenu(e.selectedKeys)}
                      theme='dark'
                      className='custom-menu'
                />
            </div>
        </Layout.Sider>
    )
}

export default SideMenu;
