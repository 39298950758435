import {TestReview} from "../../models/entities/test_reviews";
import {empty_project} from "./EmptyProject";

export const empty_run:TestReview = {
    id: 0,
    runs: {
        current: 0,
        total: 0,
    },  // default 0
    info: {
        name:'-',
        created_at: '-',
        created_by: {
            id: 0,
            fullname: ''
        },
        scheduled_for: ''
    },
    status: 0,
    system_status: 0,
    failed_reason: '',
    state: 0,
    branch: '-',
    has_to_review: true,
    project: empty_project,  // not sure if needed
    progress: {
        passed: 0,
        failed: 0,
        untested: 0,
        unresolved: 0,
        total: 0,
        new_cases: 0
    },
    disabled_button: 0,
    reviewers: [], // max 5, sorted by with most cases
    total_reviewers: 0,  // all reviewers\
}