/*
    https://4x.ant.design/components/input/ 
*/
import React from 'react';
import {Col, Row} from 'antd';

interface rowProps {
    style?: React.CSSProperties,
    gutter?: any
}

interface SingleChildProps extends rowProps{
    children: React.ReactNode,
}

interface TwoChildrenProps extends rowProps{
    l_children: React.ReactNode,
    r_children: React.ReactNode,
}
// interface threeChildrenProps extends rowProps{
//     l_children: React.ReactNode,
//     m_children: React.ReactNode,
//     r_children: React.ReactNode,
// }

function RowC(props: SingleChildProps): JSX.Element {

    return <Row gutter={props.gutter} style={props.style}> {props.children} </Row>

}

export const RowSplitHalf = ({gutter, style, l_children, r_children}: TwoChildrenProps): JSX.Element => {
    return(
        <Row style={style} gutter={gutter}>
            <Col flex={'auto'}>
                <Row gutter={gutter}>
                    {l_children}
                </Row>
            </Col>
            <Col flex={'auto'}>
                <Row gutter={gutter}>
                    {r_children}
                </Row>
            </Col>
        </Row>
    )
}
export default RowC;