import React, { useState } from 'react';
import {Button} from "antd";
import CusArgs from '../../components/tables/custom_args_table';
import SysArgs from '../../components/tables/system_args_table';
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import SysScripts from '../../components/tables/system_scripts_table';


function ArgsAndScripts(): JSX.Element{

    const [activeTab, setActiveTab] = useState(1);

    return (
        <Page title="Arguments & Scripts">
            <PageHeader title="Arguments & Scripts"/>
            <PageContent>
            <div>
      <div>
      <Button type='default' onClick={() => setActiveTab(1)} style={{ marginRight: 10, backgroundColor: activeTab === 1 ? 'whitesmoke' : 'inherit' }}>Custom Arguments</Button>
      <Button type='default' onClick={() => setActiveTab(2)} style={{ marginRight: 10, backgroundColor: activeTab === 2 ? 'whitesmoke' : 'inherit' }}>System Arguments</Button>
      <Button type='default' onClick={() => setActiveTab(3)} style={{ backgroundColor: activeTab === 3 ? 'whitesmoke' : 'inherit' }}>System Scripts</Button>
      </div>
      <div style={{marginTop: 20}}>
        {activeTab === 1 && <CusArgs />}
        {activeTab === 2 && <SysArgs />}
        {activeTab === 3 && <SysScripts />}
      </div>
    </div>
            </PageContent>
        </Page>
        
    )
}

export default ArgsAndScripts;
