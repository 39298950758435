import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type ChildComponentProps = {
    label: React.ReactNode;
    onChange:  (e: CheckboxChangeEvent) => void | undefined;
    style?: React.CSSProperties;
    defaultChecked?: boolean;
    checked?: boolean
  };


function CheckboxC(props: ChildComponentProps): JSX.Element {
    const {label, style, onChange, defaultChecked, checked} = props

    return <Checkbox
        style={style}
        value={defaultChecked}
        checked={checked}
        onChange={onChange}>{label}
    </Checkbox>

}
export default CheckboxC;