import React, {Dispatch, SetStateAction, useState} from 'react';
import ButtonC from "../button";
import {Checkbox, Dropdown, Row, Space, Table, Tooltip} from "antd";
import SlackMessage from '../modals/slack_message_modal';
import {DeleteOutlined, DownOutlined, EditOutlined, InfoCircleOutlined} from '@ant-design/icons';
import CancelAction from '../notification/cancel_req';
import {RunSlacks} from '../../models/entities/run_script_notifications';
import {empty_slack_run} from '../../services/EmptyEntities/EmptyRunSlacks';

interface SlackProps {
    slackNotifications: RunSlacks[],
    setSlackNotifications: Dispatch<SetStateAction<RunSlacks[]>>
}

function TableRunSlack(props: SlackProps): JSX.Element{
    const icon_style = {color: 'grey', fontSize:18}

    const [visible, setVisible] = useState<boolean>(false);
    const [message, setMessage] = useState<any>();

    // const [runSlacks, setRunSlacks] = useState<RunSlacks[]>([]);
    const [checkedSlacks, setCheckedSlacks] = useState<any[]>([])
    const [selectedSlack, setSelectedSlack] = useState<RunSlacks>(empty_slack_run);

    const {slackNotifications, setSlackNotifications} = props

    const selected_slacks: number = checkedSlacks ? checkedSlacks.length : 0

    const deleteSelectedCases = () => {
        let new_table = Object.values(slackNotifications);
    
        new_table = new_table.filter(item => !checkedSlacks.includes(item.id))
    
        setSlackNotifications(new_table);
        setCheckedSlacks([]);
    };

    const columns = [
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: 'channel'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Before',
            children: [
                {
                    title: 'Run Starts',
                    dataIndex: 'run_starts',
                    key: 'run_starts'
                },
                {
                    title: 'Each Run',
                    dataIndex: 'b_each_run',
                    key: 'b_each_run'
                }
            ]
        },
        {
            title: 'After',
            children: [
                {
                    title: 'Each Run',
                    dataIndex: 'a_each_run',
                    key: 'a_each_run'
                },
                {
                    title: 'Run Ends',
                    dataIndex: 'completion',
                    key: 'completion'
                }
            ]
        },
        {
            title: 
                <>  
                <Dropdown
                    disabled={selected_slacks === 0}
                    menu={{
                        items: [{
                            key: `mass-delete`,
                            label: <CancelAction secs={2} action={deleteSelectedCases}><DeleteOutlined /> Delete</CancelAction>,
                        }]
                    }}
                >
                    <a>
                        <Space>
                            Actions <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                <div style={{fontSize: 11}}>selected {selected_slacks}</div></>,
            dataIndex: 'actions',
            key: 'actions'
        }
    ];

    const handleBRSCheckboxChange = (id: number | undefined) => {
        setSlackNotifications(slackNotifications.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        brs: !script.notify?.brs
                    }
                };
            }
            return script;
        }));
    };

    const handleBERCheckboxChange = (id: number | undefined) => {
        setSlackNotifications(slackNotifications.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        ber: !script.notify?.ber
                    }
                };
            }
            return script;
        }));
    };

    const handleAERCheckboxChange = (id: number | undefined) => {
        setSlackNotifications(slackNotifications.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        aer: !script.notify?.aer
                    }
                };
            }
            return script;
        }));
    };

    const handleARECheckboxChange = (id: number | undefined) => {
        setSlackNotifications(slackNotifications.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        are: !script.notify?.are
                    }
                };
            }
            return script;
        }));
    };

    return (
        <>
            <ButtonC key={'clear_all'} type={"primary"} onClick={()=>setSlackNotifications([])} text={'Clear All'} style={{float:'right', marginBottom: 10}}/>
            <Table
                pagination={{pageSize: 10, hideOnSinglePage:true}}
                rowSelection={{
                    selectedRowKeys: checkedSlacks,
                    onChange: (keys, rows) => {
                        setCheckedSlacks(keys)
                    }
                }}
                dataSource={slackNotifications && slackNotifications.map((slack, index) => ({
                    channel: slack.channel,
                    title: <Row><div>{slack.title}</div>
                    <Tooltip
                                placement="bottom"
                                title={
                                    <>
                                    <h4>Message</h4><div dangerouslySetInnerHTML={{ __html: slack.message}} /></>
                                        }
                                        color={'grey'}
                                    >
                                        <InfoCircleOutlined style={Object.assign({}, {marginLeft:15}, icon_style)}/>
                                    </Tooltip>
                    </Row>,
                    key: slack.id,
                    run_starts: <Checkbox checked={slack.notify.brs} onChange={() => handleBRSCheckboxChange(slack.id)}/>,
                    b_each_run: <Checkbox checked={slack.notify.ber} onChange={() => handleBERCheckboxChange(slack.id)}/>,
                    a_each_run: <Checkbox checked={slack.notify.aer} onChange={() => handleAERCheckboxChange(slack.id)}/>,
                    completion: <Checkbox checked={slack.notify.are} onChange={() => handleARECheckboxChange(slack.id)}/>,
                    actions: <><EditOutlined onClick={() => {setVisible(true); setSelectedSlack(slack)}}/> Edit </>
                }))}
                key='slack_notifications'
                columns={columns}
            />
            <ButtonC type={"primary"} onClick={()=>setVisible(true)} text={'Add New'} style={{marginTop: 10}}/>
            <SlackMessage visible={visible} setVisible={setVisible} 
                          message={message} setMessage={setMessage}
                          runSlacks={slackNotifications} setRunSlacks={setSlackNotifications}
                          selectedSlack={selectedSlack} setSelectedSlack={setSelectedSlack}/>
        </>
    )
}

export default TableRunSlack;