import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Spin } from 'antd';

const { Text } = Typography;

const FindIpTool: React.FC = () => {
    const [ipv4, setIpv4] = useState<string>('');
    const [ipv6, setIpv6] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchIps = async () => {
            try {
                const ipv4Response = await axios.get('https://api.ipify.org?format=json');
                const ipv6Response = await axios.get('https://api64.ipify.org?format=json');

                setIpv4(ipv4Response.data.ip);
                setIpv6(ipv6Response.data.ip);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch IP addresses');
                setLoading(false);
            }
        };

        fetchIps();
    }, []);

    if (loading) {
        return <Spin tip="Loading..." />;
    }

    return (
        <div>
            {error ? (
                <Text type="danger">{error}</Text>
            ) : (
                <div>
                    <Text>Your IPv4 Address: {ipv4}</Text>
                    <br />
                    <Text>Your IPv6 Address: {ipv6}</Text>
                </div>
            )}
        </div>
    );
};

export default FindIpTool;
