import { ProjectRunSettings } from "../../models/entities/project";

export const empty_project_settings: ProjectRunSettings = {
    run_settings: {
    project_id: 0,
    branch: "",
    runs_number: 20,
    parallels: 20
    },
    args: {
        system: [{title: ""}],
        org: [{title: ""}],
        custom: [{title: ""}]
    }
}
