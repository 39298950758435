import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import {get, post, put, deletion} from '../../services/axiosWrapper';

// models
import {
    User,
    OrgUsersInfo,
    UserProfileInfo,
    UserProfileJobInfo,
    UserNotifications,
    Tool
} from "../../models/entities/user";
import {PageResource} from "../../models/dtos/page_resource";

//data
import {Organization} from "../../models/entities/organization";
import { Job } from "../../models/entities/job";
import { Team } from "../../models/entities/team";
import { PermissionsInterface } from "../../models/entities/permissions";

const api_path = "/api/users/"

interface updateToolParams{
    user_id: number,
    tool_id: number
}

interface getAddUsersActionsParams {
    project_id: number,
    org_id: number
}

interface createOrgUserParams {
    org_id: number;
    name: string;
    lastname: string;
    email: string;
    password: string;
    color: string;
    role: boolean;
    jobs: Job[];
    teams: Team[]
}


export const getUsers = (params: getAddUsersActionsParams) => (dispatch: AppDispatch): Promise<PageResource<User>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getUsers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getUserOrgRole = (user_id: number, org_id: number) => (dispatch: AppDispatch): Promise<{'role': number}> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getUserOrgRole?user_id=${user_id}&org_id=${org_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}


export const getOrgUserInfo = (org_id:number, search?: string) => (dispatch: AppDispatch): Promise<PageResource<OrgUsersInfo>> => {


    const request: any = get(`${api_path}getOrgUsersInfo?`, {org_id, search})
    
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const getUser = (user_id:number) => (dispatch: AppDispatch): Promise<User> =>{
    const request: any = get(`${api_path}getUser?user_id=${user_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getUserInfo = (params: {user_id:number}) => (dispatch: AppDispatch): Promise<UserProfileInfo> =>{
    const request: any = get(`${api_path}getUserInfo`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const userCasesMaintain = (params: {user_id:number, org_id: number, current: boolean | null}) => (dispatch: AppDispatch): Promise<{user_tests: number}> =>{
    const request: any = get(`${api_path}userCasesMaintain`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const userRunsCreated = (params: {user_id:number, org_id: number, current: boolean | null}) => (dispatch: AppDispatch): Promise<{runs_created: number}> =>{
    const request: any = get(`${api_path}userRunsCreated`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const userReliabilityAction = (params: {user_id:number, org_id: number}) => (dispatch: AppDispatch): Promise<{user_reliability: string}> =>{
    const request: any = get(`${api_path}userReliability`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getUserProfileTeams = (params: {loginUserId:number, targetUserId:number, org_id: number}) => (dispatch: AppDispatch): Promise<{content: {id: number, name: string}[]}> =>{
    const request: any = get(`${api_path}getUserProfileTeams`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getUserProfileJobs  = (user_id: number, org_id: number) => (dispatch: AppDispatch): Promise<PageResource<UserProfileJobInfo>> =>{
    const request: any = get(`${api_path}getUserProfileJobs?user_id=${user_id}&org_id=${org_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getNotifications  = (params: {user_id: number, org_id: number, viewed?: boolean | number, offset?: number, notification_type?: number, entity_id?: number}) => (dispatch: AppDispatch): Promise<PageResource<UserNotifications>> =>{
    const request: any = get(`${api_path}getNotifications`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const markAsRead  = (params: {user_id: number, org_id: number, notification_type?: number, notification_id?: number, mark_read?: boolean}) => (dispatch: AppDispatch): Promise<boolean> =>{
    const request: any = put(`${api_path}markAsRead`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getUnreadNotifications  = (params: {user_id: number, org_id: number}) => (dispatch: AppDispatch): Promise<{unread_notifications: number}> =>{
    const request: any = get(`${api_path}getUnreadNotifications`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const deleteNotification = (notification_id: number, notification_type: number) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deleteNotification?id=${notification_id}&notification_type=${notification_type}`)
    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const getUserProfileProjects = (params: {loginUserId:number, targetUserId:number, org_id: number}) => (dispatch: AppDispatch): Promise<{content: {id: number, name: string, p_key: string}[]}> =>{
    const request: any = get(`${api_path}getUserProfileProjects`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const logoutUser  = (user_id:number) => (dispatch: AppDispatch): Promise<boolean> => {
    const request: any = post(`${api_path}logoutUser?user_id=${user_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

// refactor
export const deleteOrgUser = (org_id:number, user_ids:number[]) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deleteOrgUser?org_id=${org_id}&user_ids=${user_ids}`)
    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const registerUser = (data:Omit<User, 'id'>) => (dispatch: AppDispatch): Promise<User> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}register`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data.user;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })

}

export const createOrgUser = (data: createOrgUserParams) => (dispatch: AppDispatch): Promise<OrgUsersInfo> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}createOrgUser`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })

}

export const updateOrgUser = (new_user:OrgUsersInfo) => (dispatch: AppDispatch): Promise<OrgUsersInfo[]> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateOrgUser`, new_user)    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const updateOrgRole = (params: {org_id: number, id: number}) => (dispatch: AppDispatch): Promise<{"new_role": number, "new_label": string, permissions: PermissionsInterface}> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateOrgRole`, params)    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const getMyOrgs = (params: { user_id: number, domain?:string }) => (dispatch: AppDispatch): Promise<PageResource<Organization>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}myOrgs?`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateUserInfo = (params: {user_id: number, name: string, lastname: string, description: string, email: string}) => (dispatch: AppDispatch): Promise<PageResource<UserProfileInfo>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateUserInfo`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updatePassword = (params: {user_id: number, password: string, new_password: string, repeat_password: string}) => (dispatch: AppDispatch): Promise<PageResource<boolean>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updatePassword`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getTools = (params: { user_id: number , filter?: 'all'| 'myTools'| 'newTools'}) => (dispatch: AppDispatch): Promise<Tool[]> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getTools?`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const addTool = (data:updateToolParams) => (dispatch: AppDispatch): Promise<boolean> => {
    const request: any = post(`${api_path}addTool`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const deleteTool = (params:updateToolParams) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteTool?`, params)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}