import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {AppDispatch} from '../../redux/store';
import {TestCase} from '../../models/entities/test_case_info';
import {TestSuiteCasesProps} from '../../models/entities/suite_section_props';
import {empty_test_case} from '../../services/EmptyEntities/EmptyTestCase';
import {getCaseComponents, getCases, updateCase} from '../../redux/actions/cases';
import TestSuiteCasesTable from './test_suite_cases_table';
import TestSuiteInfo from './test_suite_info';
import DrawerC from '../../components/drawer';
import {useAppContext} from "../../AppContext";
import {InputOnClick} from "../../components/input";
import { noop } from 'lodash';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';


function TestSuiteCases(props: TestSuiteCasesProps): JSX.Element {

    const {casesOptions, setIsExpandedTree, modal, sections, sectionOptions, cases, setCases} = props
    const dispatch: AppDispatch = useDispatch();

    const {currentProject} = useAppContext()
    // unpack caseOptions
    const {selectedSections} = casesOptions

    const [openedInfoWidth, setOpenedInfoWidth] = useState<boolean>(false)
    const [caseInfo, setCaseInfo] = useState<TestCase>(empty_test_case)
    // const [cases, setCases] = useState<TestCase[]>([])

    // edit mode
    const [editCaseTitle, setEditCaseTitle] = useState<string>('');

    // useEffect(() => {
    //     try {
    //         let new_cases: TestCase[] = []

    //         const section_ids = selectedSections ? selectedSections.map(section => {
    //             return section.key
    //         }) : []

    //         if (section_ids.length > 0) {
    //             dispatch(getCases({project_id:currentProject.id, section_ids}))
    //                 .then(found_page => {
    //                     new_cases = found_page.content
    //                     setCases(new_cases)
    //                 })
    //         } else {
    //             setCases([])
    //         }
    //     } catch (err) {
    //     }
    // }, [selectedSections]);

    const handleHideTestCaseInfo = (to_hide: boolean, test_case: TestCase) => {

        dispatch(getCaseComponents(test_case.id)).then(components => {
            test_case.components = components
            setCaseInfo(test_case)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
        setOpenedInfoWidth(true)
    }

    const handleCloseDrawer = () => {
        setOpenedInfoWidth(false)
        setCaseInfo(empty_test_case)
    }
    const handleEditTitle = () => {
        const data = {project_id: currentProject.id, id: caseInfo.id, title: editCaseTitle}

        dispatch(updateCase(data))
            .then(() => {
                if (cases) {
                const new_cases = [...cases].map(t_case =>{
                    if (t_case.id === caseInfo.id){
                        t_case.title = editCaseTitle
                    }
                    return t_case
                })
                setCases && setCases(new_cases)
                let new_case = {...caseInfo}
                new_case.title = editCaseTitle
                setCaseInfo(new_case)

            }})
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    return (
        <>
            <TestSuiteCasesTable
                sectionOptions={sectionOptions}
                cases={cases ? cases : []}
                sections={sections}
                setCases={setCases ? setCases : noop}
                handleHideTestCaseInfo={handleHideTestCaseInfo}
                casesOptions={casesOptions}
                setIsExpandedTree={setIsExpandedTree}
                modal={modal}/>
            <DrawerC
                open={openedInfoWidth}
                size='large'
                title={<InputOnClick
                    input={{
                        value: editCaseTitle,
                        setValue: setEditCaseTitle,
                        handleOk: handleEditTitle,
                        style: {width: 300}
                    }}
                    text={{
                        value: caseInfo.title
                    }}
                />}
                onClose={handleCloseDrawer}
            >
                <TestSuiteInfo
                    openedInfo={caseInfo}
                    setOpenedInfo={setCaseInfo}
                    cases={cases}
                    setCases={setCases}
                />
            </DrawerC>
        </>
    )
}

export default TestSuiteCases;
