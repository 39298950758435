import React, {useState} from 'react';
import {Button, Popover} from 'antd';
import { CloseOutlined, CommentOutlined} from "@ant-design/icons";
import TPBadge from "../sds/badge";
import {colors} from "../assets/data/colors";

interface ButtonPropsType {
    key?: string,
    onClick?: React.MouseEventHandler<HTMLInputElement> | undefined,
    text?: React.ReactNode,
    type?: "link" | "text" | "default" | "primary" | "dashed" | undefined,
    style?: React.CSSProperties,
    danger?: boolean,
    disabled?: boolean,
    icon?: React.ReactNode,
    shape?: "circle" | "default" | "round" | undefined,
    size?: 'small' | 'middle' | 'large' | undefined,
    htmlType?: "button" | "submit" | "reset" | undefined
}


interface CommentButtonOnlyProps extends ButtonPropsType {
    onClick: React.MouseEventHandler<HTMLInputElement>,
    content: React.ReactNode
}


interface commentsProps extends CommentButtonOnlyProps {
    comments: number,
}


function ButtonC(props: ButtonPropsType): JSX.Element {
    return <Button {...props}>
        {props.text}
    </Button>

}

function CommentButtonOnly({onClick, content}: CommentButtonOnlyProps): JSX.Element {
    const [visible, setVisible] = useState<boolean>(false);

    const handleButtonClick: React.MouseEventHandler<HTMLInputElement> = (event) => {
        // Set visible to true
        setVisible(!visible);

        // Call the provided onClick function
        if (onClick) onClick(event)
    };

    return (
        <Popover
            content={content}
            style={{overflowY: 'visible'}}
            open={visible}
            title={<>Comments <CloseOutlined onClick={() => {
                setVisible(false)
            }} style={{float: "right"}}/></>}
            trigger="click"
            placement="right">
            <ButtonC
                size={'small'}
                shape={'circle'}
                icon={<CommentOutlined/>}
                onClick={handleButtonClick}/>
        </Popover>
    )
}

export function CommentButton({comments, onClick, content}: commentsProps): JSX.Element {
    return <TPBadge size={'small'} count={comments} color={colors.green.bamboo}>
                <CommentButtonOnly onClick={onClick} content={content}/>
            </TPBadge>

}


export default ButtonC;