/*
    https://4x.ant.design/components/input/ 
*/
import React, {Dispatch, KeyboardEventHandler, SetStateAction, useState, useEffect} from 'react';
import {Input} from 'antd';
import ButtonC from "./button";
import ButtonsSaveOnEdit from "./buttons/saveButtons";
import { AutoComplete } from 'antd';
import Dropdown from "antd/lib/dropdown";
import {FileSyncOutlined, CheckOutlined, PropertySafetyOutlined, CloudSyncOutlined} from '@ant-design/icons';

const {TextArea} = Input;

type ButtonPropsType = {
    value?: string,
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined,
    placeholder?: string,
    style?: React.CSSProperties
    onPressEnter?: KeyboardEventHandler<HTMLInputElement> | undefined
}

type InputBranchProps = {
    state?: {
        value: string,
        setValue: Dispatch<SetStateAction<string>>
    },
    style?: React.CSSProperties,
    placeholder?: string
}

function InputC(button_props: ButtonPropsType): JSX.Element {
    const {value, onChange, placeholder, style, onPressEnter} = button_props;

    return <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onPressEnter={onPressEnter}
        style={style}/>

}

type InputOnClickProps = {
    input: {
        value: string,
        setValue: Dispatch<SetStateAction<string>>,
        handleOk: (...args: any[]) => any,
        style?: React.CSSProperties
    },
    text: {
        value: string
        style?: React.CSSProperties
    }
}

export const InputOnClick = ({input, text}: InputOnClickProps): JSX.Element => {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [isDirty, setIsDirty] = useState<boolean>(false)

    const reset = () => {
        setEditMode(false)
        input.setValue('')
        setIsDirty(false)
    }

    const handleOk = () => {
        try {
            input.handleOk()
        } catch (err) {
            // console.log(err)
        }
        reset()
    }

    return (
        <div style={{display: 'inline-block'}}>
            {
                editMode ?
                    <>
                        <InputC
                            style={input.style}
                            onChange={(e) => {
                                input.setValue(e.target.value)
                                setIsDirty(true)
                            }}
                            onPressEnter={handleOk}
                            value={input.value || isDirty ? input.value : text.value}/>
                        <ButtonsSaveOnEdit
                            onClick={handleOk}
                            disableSave={input.value.length === 0}
                            reset={reset}
                        />
                    </>
                    : <ButtonC onClick={() => {
                        setEditMode(true)
                    }}
                               type={'text'}
                               text={text.value}
                               style={{display: 'inline-block', ...text.style}}/>
            }

        </div>
    )
}

export const TextareaOnClick = ({input, text}: InputOnClickProps): JSX.Element => {
    const [editMode, setEditMode] = useState<boolean>(false)

    const reset = () => {
        setEditMode(false)
    }

    const handleOk = () => {
        try {
            input.handleOk()
        } catch (err) {
            // console.log(err)
        }
        reset()
    }

    return (
        <div style={{width: 'inline-block'}}>
            {
                editMode ?
                    <>
                        <TextArea
                            autoSize={{minRows: 2, maxRows: 4}}
                            autoFocus={true}
                            style={input.style}
                            onChange={(e) => {
                                input.setValue(e.target.value)
                            }}
                            value={input.value}/>
                        <ButtonsSaveOnEdit
                            onClick={handleOk}
                            reset={reset}
                        />
                    </>
                    : <ButtonC
                        onClick={() => {
                            setEditMode(true)
                        }}
                        type={'text'}
                        text={text.value}
                        style={{whiteSpace: 'pre-line', ...text.style}}/>

            }
        </div>
    )
}

export const InputBranch = (props:InputBranchProps) => {
    let {state, style, placeholder} = props
    const [value, setValue] = useState<string>('')
    const [options, setOptions] = useState<{ value: string }[]>([{value: 'panos' }]);

    state = state? state: {value, setValue}
    placeholder = placeholder? placeholder: 'Add your branch'

    useEffect(()=>{
        // fetch branches from github
    }, [])

    const getPanelValue = (searchText: string) =>
        !searchText ? [] : options;

    const onSelect = (data: string) => {
        // console.log('onSelect', data);
    };

    const items = [
        {
            key: 1,
            label: <><CheckOutlined /> Set</>,
            // onClick: () =>{console.log('1')}
        },
        {
            key: 2,
            disabled: true,
            label: <><FileSyncOutlined /> Sync</>,
            // onClick: () =>{console.log('2')}
        },
        {
            key: 3,
            label: <><PropertySafetyOutlined /> Test</>,
            // onClick: () =>{console.log('3')}
        },
        {
            key: 4,
            label: <><CloudSyncOutlined /> Fetch</>,
            // onClick: () =>{console.log('3')}
        }
    ]

    return <>
        <AutoComplete
            defaultValue={state.value}
            options={options}
            style={{ width: 200 }}
            onSelect={onSelect}
            onSearch={(text) => setOptions(getPanelValue(text))}
            placeholder={placeholder}
        />
        <Dropdown.Button
            style={{width: 90}}
            menu={{ items }}>Set
        </Dropdown.Button><br/>
    </>
}

export default InputC;