import React from 'react';
import { Drawer } from 'antd';

type drawerOptionsType = {
    open: boolean,
    children: React.ReactNode,
    title?: React.ReactNode,
    size?: 'default' | 'large',
    onClose?: any,
    footer?: any,
    key?: string
}


function DrawerC(drawer_options:drawerOptionsType): JSX.Element {
    return(
    <Drawer open={drawer_options.open}
            title={drawer_options.title}
            footer={drawer_options.footer}
            size={drawer_options.size}
            onClose={drawer_options.onClose}
            key={drawer_options.key}      
          >
            {drawer_options.children}
    </Drawer>)

}
export default DrawerC;