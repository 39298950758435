import React, { useEffect, useState } from 'react';
import {Dropdown, Input, Space, Table,} from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import ButtonC from '../../components/button';
import CancelAction from '../../components/notification/cancel_req';
import { ArgumentScriptSetting } from '../../models/entities/settings';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { deleteSystemArgument, getProjectSystemArguments } from '../../redux/actions/project_actions';
import { useAppContext } from '../../AppContext';
import { empty_argument } from '../../services/EmptyEntities/EmptyArgs';
import SystemArgument from '../../components/modals/system_arg_modal';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const { Search } = Input;


function SysArgs(): JSX.Element{
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg, currentProject, currentProjectSettings, setAppContextProjectSettings} = useAppContext();
    const org_id = currentOrg.id
    const project_id = currentProject.id

    const params = {org_id, project_id}

    const [visible, setVisible] = useState<boolean>(false);
    const [checkedProjectSystArgs, setCheckedProjectSystArgs] = useState<any[]>([])
    const [savedProjectSystArgs, setSavedProjectSystArgs] = useState<ArgumentScriptSetting[]>([]);
    const [selectedSystArg, setSelectedSystArg] = useState<ArgumentScriptSetting | undefined>(empty_argument);
    const [search, setSearch] = useState<string>('')

    const selected_slacks: number = checkedProjectSystArgs ? checkedProjectSystArgs.length : 0

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getProjectSystemArguments(params)).then(args => {
                setSavedProjectSystArgs(args.content);
                let newTitle: {title: string}[] = [];
                let arg_statuses: any[] = [];
                args.content.forEach(setting => {
                    setting.title === undefined ? setAppContextProjectSettings(currentProjectSettings) : newTitle.push({ title: setting.title})
                    {setting.status !== undefined && arg_statuses.push(setting.status)}
                });
                currentProjectSettings.args.system = newTitle;
                setAppContextProjectSettings(currentProjectSettings);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            });
        }
        fetchData();
    }, [dispatch]);
    
    const handleDeleteDefaultProjectSystArgs = () => {
    
        const params = {
            project_id: currentProject.id,
            org_id: org_id,
            ids: checkedProjectSystArgs}
    
            dispatch(deleteSystemArgument(params)).then(() => {
                let updatedList = savedProjectSystArgs.filter((item => !checkedProjectSystArgs.includes(item.id)));
                setSavedProjectSystArgs(updatedList);
                let newTitle: {title: string}[] = [];
                updatedList.forEach(arg => {
                    arg.title === undefined ? setAppContextProjectSettings(currentProjectSettings) : newTitle.push({ title: arg.title})
                });
                currentProjectSettings.args.system = newTitle;
                setAppContextProjectSettings(currentProjectSettings);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: "label",
        },
        {
            title: 'Default',
            dataIndex: 'default',
            key: "default",
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: "active",
        },
        {
            title: 
                <>  
                <Dropdown
                    disabled={selected_slacks === 0}
                    menu={{
                        items: [{
                            key: `mass-delete`,
                            label: <CancelAction secs={2} action={handleDeleteDefaultProjectSystArgs}><DeleteOutlined /> Delete</CancelAction>,
                        }]
                    }}
                >
                    <a>
                        <Space>
                            Actions <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                <div style={{fontSize: 11}}>selected {selected_slacks}</div></>,
            dataIndex: 'actions',
            key: 'actions'
        }
    ];

    const fetchData = async () => {
        dispatch(getProjectSystemArguments({org_id, project_id, search})).then(args => {
            setSavedProjectSystArgs(args.content);
    }).catch((err) => {
        safeHandleErrorResponse(err)
    })}

    return (
        <>
            <Search
                style={{marginBottom: 10, width: 250}}
                placeholder="Search for system argument"
                onSearch={fetchData}
                value={search}
                onChange={(value)=>setSearch(value.target.value)}
                />
            <Table
                pagination={{pageSize: 10, hideOnSinglePage:true}}
                rowSelection={{
                    selectedRowKeys: checkedProjectSystArgs,
                    onChange: (keys, rows) => {
                        setCheckedProjectSystArgs(keys)
                    }
                }}
                dataSource={savedProjectSystArgs && savedProjectSystArgs.map((arg, index) => ({
                    key: arg.id,
                    name: arg.title,
                    label: arg.label,
                    default: arg.default_value,
                    active: arg.status === 1 || arg.status === true ? 'Yes' : 'No',
                    actions: <><EditOutlined onClick={() => {setVisible(true); setSelectedSystArg(arg)}}/> Edit </>
                }))}
                key='system_arguments'
                columns={columns}
            />
            <ButtonC type={"primary"} onClick={()=>setVisible(true)} text={'Add New'} style={{marginTop: 10}}/>
            <SystemArgument visible={visible} setVisible={setVisible} 
                            sysArgs={savedProjectSystArgs} setRunSystArgs={setSavedProjectSystArgs}
                            selectedArg={selectedSystArg} setSelectedArg={setSelectedSystArg}/>
        </>
    )
}

export default SysArgs;
