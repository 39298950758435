import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import "./post_popup.css";
import ModalC from "../modal";
import {Form, Input, Select, Space} from 'antd';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import 'quill-image-upload';
import RichEditor from "../rich_editor/rich_editor";
import {useAppContext} from "../../AppContext";
import {AppDispatch} from "../../redux/store";
import {getProjectSlackIntegrations} from "../../redux/actions/project_actions";
import {RunSlacks} from "../../models/entities/run_script_notifications";
import ButtonC from "../button";
import {empty_slack_run} from "../../services/EmptyEntities/EmptyRunSlacks";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";

type PostProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    message: any;
    setMessage: Dispatch<any>;
    runSlacks?: RunSlacks[];
    setRunSlacks?: Dispatch<RunSlacks[]>;
    selectedSlack?: RunSlacks;
    setSelectedSlack?: Dispatch<RunSlacks>

}

export default function SlackMessage(props: PostProps): JSX.Element {
    const {Option} = Select;

    const dispatch: AppDispatch = useDispatch();
    const {currentOrg, currentProject} = useAppContext();

    const org_id = currentOrg.id
    const project_id = currentProject.id
    const {visible, setVisible, message, setMessage, runSlacks, setRunSlacks, selectedSlack, setSelectedSlack} = props;
    const handleEditorChange = (content: any) => {
        setMessage(content);
    };

    const [slackTitle, setSlackTitle] = useState<string>('');
    const [selectedChannel, setSelectedChannel] = useState<string>('');
    const [runSlackInt, setRunSlackInt] = useState<string[]>([]);

    useEffect(() => {
        const fetchData = () => {
            dispatch(getProjectSlackIntegrations({org_id, project_id, only_visible: true})).then(slacks => {
                let slack_int_titles: string[] = [];
                slacks.content.forEach(slack => {
                    slack.title === undefined ? slack_int_titles.push("undefined") : slack_int_titles.push(slack.title)
                });
                setRunSlackInt(slack_int_titles);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch, currentOrg])

    const handleSlackTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSlackTitle(event.target.value);
    };

    const handleChannelChange = (event: string) => {
        setSelectedChannel(event);
    };

    const handleCancel = () => {
        setVisible(false)
        setMessage('')
        if (setSelectedSlack) {
            setSelectedSlack(empty_slack_run)
        }
    };

    useEffect(() => {
        setSlackTitle(selectedSlack ? selectedSlack?.title : '')
        setSelectedChannel(selectedSlack ? selectedSlack?.channel : '')
        setMessage(selectedSlack?.message)
    }, [selectedSlack])

    const handleOk = () => {
        if (runSlacks !== undefined && setRunSlacks !== undefined) {
            if (selectedSlack !== empty_slack_run) {
                const updatedSlacks: RunSlacks[] = (runSlacks ?? []).map(item => {
                    if (item.id === selectedSlack?.id) {
                        return {
                            ...item,
                            title: slackTitle,
                            channel: selectedChannel || '', // Providing a default empty string if selectedChannel is undefined
                            message: message,
                            notify: {brs: false, ber: false, aer: false, are: false},
                            id: selectedSlack.id
                        };
                    }
                    return item;
                });

                setRunSlacks(updatedSlacks);

            } else {
                {
                    setRunSlacks([...runSlacks,
                        {
                            channel: selectedChannel ? selectedChannel : '',
                            title: slackTitle ? slackTitle : '',
                            message: message,
                            notify: {brs: false, ber: false, aer: false, are: false},
                            id: runSlacks.length + 1
                        }])
                }
            }
        }
        setVisible(false)
        setSelectedChannel('')
        setSlackTitle('')
        setMessage('')
        if (setSelectedSlack) {
            setSelectedSlack(empty_slack_run)
        }
    };

    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
        <ButtonC key="submit_ok" type="primary" onClick={() => handleOk()}
                 text={selectedSlack === empty_slack_run ? 'Create' : 'Save'}
                 disabled={slackTitle.length < 4 || message.length < 1 || selectedChannel.length < 1 || (runSlacks?.some(item => item.title === slackTitle) && selectedSlack?.title !== slackTitle)}/>
    ];


    return (
        <ModalC open={visible} title={'Slack Message'} onOk={handleOk} onCancel={handleCancel}
                footer={footer_buttons}>
            <Form.Item>
                <Space.Compact>
                    <div>
                        <h4>Title:</h4>
                        {runSlacks?.some(item => item.title === slackTitle) && selectedSlack?.title !== slackTitle &&
                        <p style={{color: '#F6C324'}}>The specific Slack Title already exists!</p>}
                        <Input placeholder='Slack Message Title'
                               value={slackTitle}
                               onChange={handleSlackTitleChange}
                               style={{width: 200, marginLeft: 20}}/>
                        <h4>Message:</h4>
                        <RichEditor value={message} onChange={handleEditorChange}/>
                        <h4>Channel:</h4>
                        <Select
                            style={{width: 200, marginLeft: 20}}
                            placeholder="Select Channel"
                            value={selectedChannel}
                            onChange={handleChannelChange}
                            optionLabelProp="label">
                            {runSlackInt.map((channel) => (
                                <Option key={channel} label={channel}>
                                    <Space>{channel}</Space>
                                </Option>
                            ))}
                        </Select>
                    </div>
                </Space.Compact>
            </Form.Item>
        </ModalC>
    );
}
