import React, {Dispatch, SetStateAction, useState} from 'react';
import TestSuiteCaseStats from './test_suite_statistics';
import TestSuiteCaseInfoTable from './test_suite_info_table';
import TestSuiteLogs from './test_suite_logs';
import {Menu, Row, MenuProps, Divider} from 'antd';
import {TestCase} from '../../models/entities/test_case_info';
import { colors } from '../../assets/data/colors';


type TestSuiteInfoProps = {
    openedInfo: TestCase,
    setOpenedInfo: Dispatch<SetStateAction<TestCase>>,
    cases?: TestCase[],
    setCases?: Dispatch<SetStateAction<TestCase[]>>
}


function TestSuiteInfo(props: TestSuiteInfoProps): JSX.Element {

    const [currentSubMenuItems, setSubMenuItemsCurrent] = useState('steps');

    const subMenuItems: MenuProps['items'] = [
        {label: 'Steps', key: 'steps'},
        {label: 'Info', key: 'info'},
        {label: 'Logs', key: 'logs'}
    ]

    const onClick: MenuProps['onClick'] = e => {
        setSubMenuItemsCurrent(e.key);
    };

    const renderSteps = () => {
        try {
            const steps = (props.openedInfo.steps && typeof(props.openedInfo.steps) === 'string') ? JSON.parse(props.openedInfo.steps) : [];
            if (Array.isArray(steps)) {
                return steps.map((step, index) => {
                    if (typeof step === 'string') {
                        return (
                            <React.Fragment key={index}>
                                <span style={{color: colors.green.bamboo}}>Step {index + 1}</span>
                                <div style={{ marginLeft: 15, paddingTop: 15 }} dangerouslySetInnerHTML={{ __html: step }} />
                                <Divider />
                            </React.Fragment>
                        );
                    } else {
                        // console.error('Step is not a string:', step);
                    }
                });
            }
        } catch (error) {
            // console.error('Invalid JSON:', error);
        }
        return null;
    };

    return <div style={{width: '100%'}}>
        <Row>
            <TestSuiteCaseInfoTable {...props}/>
        </Row>
        <Menu onClick={onClick} defaultSelectedKeys={[currentSubMenuItems]} mode="horizontal"
              items={subMenuItems}/>
        <div className='test_case_sub_menu_container'>
            {
                currentSubMenuItems === 'steps' ?
                <div style={{backgroundColor: "#f5f5f5", marginLeft: 20, minHeight: 300}}>
                {renderSteps()}
                </div>
                    : currentSubMenuItems === "info" ?
                        <TestSuiteCaseStats {...props}/>
                        : <TestSuiteLogs/>
            }
        </div>
    </div>
}

export default TestSuiteInfo;