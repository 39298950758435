import React from 'react';
import {notification} from 'antd';

export function handleErrorResponse(err:string, title?: string, seconds?: number) {
    const key = Date.now();

    notification.warning({
        key,
        placement: 'topRight',
        message: title? title: 'An Error Has Occurred',
        description: err
        ,
        duration: seconds? seconds: 4
    })
}

export function safeHandleErrorResponse(err:string, title?: string){
    const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    if(isLocal && err){
        handleErrorResponse(err.toString(), title, 300)
    }
}