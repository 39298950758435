import { SlackIntSetting } from "../../models/entities/settings";

export const empty_slack: SlackIntSetting = {
    title: '', 
    url: '', 
    status: false,
    org_id: -1,
    project_id: -1,
    id: -1,
    channel_id: ''
}
