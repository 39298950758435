import React from 'react';
import {CasesPreview, TestCase} from '../../models/entities/test_case_info';
import { empty_user } from './EmptyUser';

export const empty_test_case:TestCase = {
    id: 0,
    project_cid: 0,
    title: "",
    owner: empty_user,
    creator: empty_user,
    description: "",
    section_id: -1,
    priority: 3,
    pass_rate: -1,
    estimation: -1,
    steps: "",
    createAt: "",
    defects: 0,
    components: []
}

export const empty_cases_preview:CasesPreview = {
    estimation: 0, // in minutes
    total_cases: 0,
    reviewers: [], // max 5, sorted by with most cases
    total_reviewers: 0,  // all reviewers
    new_cases: 0
}