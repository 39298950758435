import React, { useState } from 'react';
import { Input, Button, Typography, Space } from 'antd';
import { diffWords } from 'diff';

const { TextArea } = Input;
const { Paragraph } = Typography;

interface Diff {
    value: string;
    added?: boolean;
    removed?: boolean;
}

const HighlightDifferences: React.FC = () => {
    const [text1, setText1] = useState<string>('');
    const [text2, setText2] = useState<string>('');
    const [diffs, setDiffs] = useState<Diff[]>([]);

    const handleCompare = () => {
        const differences = diffWords(text1, text2);
        setDiffs(differences);
    };

    const renderDiff = (diffs: Diff[]) =>
        diffs.map((part: Diff, index: number) => (
            <span
                key={index}
                style={{
                    backgroundColor: part.added ? 'lightgreen' : part.removed ? 'salmon' : 'transparent',
                    textDecoration: part.removed ? 'line-through' : 'none',
                }}
            >
                {part.value}
            </span>
        ));

    return <>
        <Space direction="horizontal" size="large" style={{ width: '100%', paddingLeft: '20px' }}>
            <TextArea
                style={{width: 300}}
                rows={10}
                value={text1}
                onChange={(e) => setText1(e.target.value)}
                placeholder="Enter the original text"
            />
            <TextArea
                style={{width: 300}}
                rows={10}
                value={text2}
                onChange={(e) => setText2(e.target.value)}
                placeholder="Enter the modified text"
            />
        </Space>
        <Button type="primary" style={{marginLeft: '40%', marginTop: 30}} onClick={handleCompare}>
            Compare Texts
        </Button>
        <div style={{padding: 25}}>
            <Paragraph style={{ whiteSpace: 'pre-wrap' }}>{renderDiff(diffs)}</Paragraph>
        </div>
    </>
};

export default HighlightDifferences;
