import React from 'react';
import {Table, Tag} from 'antd';
import {test_case_logs} from '../../assets/data/test_case_logs';
import type {ColumnsType} from 'antd/es/table';
import RowC from '../../components/row';
import {secondsToTime} from '../../assets/helpers/helper';

interface DataType {
    key: number;
    log: string;
    run_at: React.ReactNode;
    owner: string;
    status: React.ReactNode;
    filters?: { text: string, value: string }[]
    onFilter?: any
}

function TestSuiteLogs(): JSX.Element {
    const columns: ColumnsType<DataType> = [
        // {
        //   title: 'Run ID',
        //   dataIndex: 'run_id',
        //   key: 'run_id',
        // },
        {
            title: 'Run At',
            dataIndex: 'run_at',
            key: 'run_at',
            colSpan: 1
        },
        {
            title: 'Output',
            dataIndex: 'log',
            key: 'log',
            colSpan: 1
        },
        // {
        //   title: 'Excecution Time',
        //   dataIndex: 'execution_time',
        //   key: 'execution_time',
        // },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            colSpan: 1
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [{text: 'Passed', value: 'Passed'}, {text: 'Failed', value: 'Failed'}],
            // onFilter: (value: string | number | boolean, record: any) => record.status.props.children[1] === value,
            colSpan: 1
        }];

    const convert_ts_to_date = (ts: number): Date => {
        let dateFormat = new Date(ts);
        return dateFormat
    }

    const data: DataType[] = test_case_logs.logs.map(log => {
        return {
            key: log.log_id,
            log: log.log,
            run_at: <>
                {convert_ts_to_date(log.logged_at).toString()} <p
                style={{fontSize: 12}}>for: {secondsToTime(log.execution_time)}</p>
            </>,
            owner: log.owner,
            status: <Tag color={log.status === 'Passed' ? 'green' : 'red'}> {log.status} </Tag>
        }
    })

    return (
        <RowC>
            <Table columns={columns} style={{width: "100%"}} dataSource={data}/>
        </RowC>
    )

}

export default TestSuiteLogs;