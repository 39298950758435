// forgot.tsx
import React, {useState} from 'react';
import {resetPassword} from '../../redux/actions/auth_actions';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux/store';
import {useNavigate} from 'react-router';
import {Button, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';


const Reset: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const [form] = Form.useForm();
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const lowercaseRegex = /^(?=.*[a-z])/;
    const uppercaseRegex = /^(?=.*[A-Z])/;
    const specialCharRegex = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const navigate = useNavigate();


    const handleReset = () => {
        form
            .validateFields()
            .then(values => {
                if(values.repeat_password === values.password){
                    dispatch(resetPassword({
                        email: values.email,
                        temporary_password: values.temporary_password,
                        password: values.password,
                        repeat_password: values.repeat_password,
                    }))
                    .then(() => {
                        navigate(`/login`)
                    })
                    .catch(()=>{
                        setError(`Email '${values.email}' Doesn't Match Temporary Password`)
                    })
                    form.resetFields();
                }else{
                    setError("Repeat Password Doesn't Match Password")
                }
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    return (
        <Form
            form={form}
            style={{ width: 400 }} // Ensure the form takes the full width
        >
            <Form.Item
                name="email"
                validateTrigger={false}
                rules={[{ required: true, message: 'Please input your Email!' },
                    {
                        validator(_, value) {
                            if (emailRegex.test(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Invalid email format'));
                        },
                    }]}
            >
                <Input
                    size={'large'}
                    prefix={<UserOutlined />}
                    placeholder="Email"
                />
            </Form.Item>
            <Form.Item
                name="temporary_password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
            >
                <Input.Password
                    size={'large'}
                    prefix={<LockOutlined />}
                    placeholder="Temporary Password"
                />
            </Form.Item>
            <Form.Item
                name="password"
                validateTrigger={false}
                rules={[
                    { required: true, message: 'Password is Required' },
                    { min: 8, message: 'Password must be at least 8 characters long' },
                    () => ({
                        validator(_, value) {
                            if (!value || lowercaseRegex.test(value.trim())) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password must contain at least one lowercase letter'));
                        },
                    }),
                    () => ({
                        validator(_, value) {
                            if (!value || uppercaseRegex.test(value.trim())) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password must contain at least one uppercase letter'));
                        },
                    }),
                    () => ({
                        validator(_, value) {
                            if (!value || specialCharRegex.test(value.trim())) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password must contain at least one special character'));
                        },
                    })
                ]}
            >
                <Input.Password
                    size={'large'}
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    prefix={<LockOutlined />}
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item
                validateTrigger={false}
                name="repeat_password"
                rules={[{ required: true, message: 'Please input your Password!' },
                    () => ({
                        validator(_, value) {
                            if (!value || value === password) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Repeat Password doesn't match Password" ));
                        },
                    })]}
            >
                <Input.Password
                    size={'large'}
                    prefix={<LockOutlined />}
                    placeholder="Repeat Password"
                />
            </Form.Item>
            {error !== '' ? <p style={{ color: 'red' }}>{error}</p> : null}
            <Form.Item>
                <Button type="default" onClick={handleReset}>Reset</Button>
            </Form.Item>
        </Form>
    );
};

export default Reset;
