import React, { useEffect, useRef } from 'react';

interface CircularMaskImageProps {
    imageUrl: string,
    width: number,
    height: number,
    gradientFromCenter: number
}

const CircularMaskImage: React.FC<CircularMaskImageProps> = ({ imageUrl, width, height , gradientFromCenter}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const img = new Image();

        img.onload = () => {
            canvas.width = width;
            canvas.height = height;

            // Calculate the aspect ratio and scaled dimensions
            const aspectRatio = img.width / img.height;
            let scaledWidth = width;
            let scaledHeight = height;

            if (aspectRatio > 1) {
                // Landscape orientation
                scaledHeight = width / aspectRatio;
            } else {
                // Portrait orientation
                scaledWidth = height * aspectRatio;
            }

            // Center the image
            const offsetX = (width - scaledWidth) / 2;
            const offsetY = (height - scaledHeight) / 2;

            ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

            const gradient = ctx.createRadialGradient(
                width / 2,
                height / 2,
                gradientFromCenter,  // Start the gradient 100 pixels from the center
                width / 2,
                height / 2,
                Math.min(width, height) / 2
            );

            gradient.addColorStop(0, 'rgba(0, 0, 0, 1)');
            gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

            ctx.globalCompositeOperation = 'destination-in';
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, width, height);
        };

        img.src = imageUrl;
    }, [imageUrl, width, height]);

    return <canvas ref={canvasRef}/>;
};

export default CircularMaskImage;
