import {DataNode} from "antd/lib/tree";
import {Organization} from "../../models/entities/organization";
import {Project} from "../../models/entities/project";
import LcsManager from "../local_storage/lcs";

export function generateColor() {
    let result = '#';
    let characters = 'ABCDEF0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result
}

export function deleteFromArray(array: any[], index: number) {
    /*
        delete an item from an array, return the rest of the array
    */

    return [...array.splice(0, index), ...array.splice(1)]
}

export function removeCommonItems(array1: any[], array2: any[]) {
    /*
    * the 2 arrays must be the same type
    * */
    return array1.filter(item => !array2.includes(item));
}

export const getCurrentTimestamp = (): number => {
    const currentTime = new Date();
    return Math.floor(currentTime.getTime() / 1000);
}

export function convertToLocalTime(timestamp: number): number {
    const now = new Date();
    return timestamp - now.getTimezoneOffset() * 60 * 1000;
}

export function secondsToTime(seconds_to_convert: number) {

    const date = new Date(seconds_to_convert * 1000);

    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    return hours + ':' + minutes + ':' + seconds;
}

export function hasOnlyNumbers(text:string) {
    return /^\d+$/.test(text)
}

export function remove_spaces(text: string): string {
    return text.replace(/\s/g, "")
}


export function change_project_url(new_path: string, project_key?:string): string {
    const lcs_manager = new LcsManager()

    const org: Organization = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_org)
    const org_domain = org.domain
    if(!project_key){
        const project: Project = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_project)
        project_key = project.p_key
    }


    return `/${org_domain}/projects/${project_key}/${new_path}`
}


export function searchFolders(folders: DataNode[], keyword: string): DataNode[] {
    // Read the contents of the folder

    const data_found: DataNode[] = [];

    // Loop through each file/directory
    for (let i = 0; i < folders.length; i++) {
        let current_folder = folders[i];
        let temp_title = current_folder.title ? current_folder.title.toString() : '';

        if (current_folder.children) {
            let nodes = searchFolders(current_folder.children, keyword)
            nodes.forEach(node => {
                if (!data_found.includes(node)) {
                    data_found.push(node)
                }
            });
        }
        if (temp_title.toLowerCase().includes(keyword.toLowerCase())) {
            data_found.push(current_folder)
        }
    }
    return data_found
}

export function getFirstLetter(text: string): string {
    const parts = text.split(' ')
    let initials = ''
    parts.forEach(part => {
        initials += part[0]
    })
    return initials
}

export function stringHasValue(text:string | null) :Boolean{
    return !!text
}