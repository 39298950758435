import {get, post, deletion} from '../../services/axiosWrapper';
import { PageResource } from "../../models/dtos/page_resource";
import { AppDispatch } from "../store";
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { NewReaction, Reactions } from "../../models/entities/reactions";

const api_path = "/api/org/"

interface params {
    post_id?: number,
    react_id?: number,
    user_id?: number
}

interface comment_params {
    comment_id?: number,
    react_id?: number,
    user_id?: number
}

export const getReactions = (params: {org_id:number}) => (dispatch: AppDispatch): Promise<PageResource<Reactions>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getReactions`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const createPostReaction = (data: params, reactions: NewReaction[]) => (dispatch: AppDispatch): Promise<NewReaction[]> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}reactPost`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            value.data = [...reactions, value.data.new_reactions]
            return value.data;

        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}

export const createCommentReaction = (data: comment_params, reactions: NewReaction[]) => (dispatch: AppDispatch): Promise<NewReaction[]> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}reactComment`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            value.data = [...reactions, value.data.new_reactions]
            return value.data;

        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}

export const deletePostReaction = (data: params) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deleteReactPost`, data)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const deleteCommentReaction = (data: comment_params) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deleteReactComment`, data)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}
