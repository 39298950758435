import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import "./post_popup.css";
import React from "react";
import ModalC from "../modal";
import {Form, Input} from 'antd';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import 'quill-image-upload';
import {useAppContext} from "../../AppContext";
import {AppDispatch} from "../../redux/store";
import ButtonC from "../button";
import {SlackIntSetting} from "../../models/entities/settings";
import {getProjectSlackIntegrations, saveProjectSlackIntegration} from "../../redux/actions/project_actions";
import {empty_slack} from "../../services/EmptyEntities/EmptySlack";
import SwitchC from "../switch";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";

type SlackstProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    slacks?: SlackIntSetting[];
    setSlacks?: Dispatch<SlackIntSetting[]>;
    selectedSlack?: SlackIntSetting;
    setSelectedSlack?: Dispatch<SetStateAction<SlackIntSetting | undefined>>;
}

export default function SlackChannels(props: SlackstProps): JSX.Element {

    const dispatch: AppDispatch = useDispatch();
    const {currentOrg, currentProject} = useAppContext();

    const org_id = currentOrg.id
    const project_id = currentProject.id
    const params = {org_id, project_id}

    const {visible, setVisible, slacks, setSlacks, selectedSlack, setSelectedSlack} = props;

    const [runSlackInt, setRunSlackInt] = useState<string>('');
    const [runSlackIntURL, setRunSlackIntURL] = useState<string>('');
    const [runSlackIntChannel, setRunSlackIntChannel] = useState<string>('');
    const [runSlackIntSwitch, setRunSlackIntSwitch] = useState<boolean>(true);

    useEffect(() => {
        setRunSlackInt(selectedSlack?.title || '');
        setRunSlackIntURL(selectedSlack?.url || '');
        setRunSlackIntChannel(selectedSlack?.channel_id || '');
        setRunSlackIntSwitch(selectedSlack?.status || true);
    }, [selectedSlack]);


    useEffect(() => {
        const fetchData = () => {
            dispatch(getProjectSlackIntegrations(params)).then(slacks => {
                if (setSlacks) {
                    setSlacks(slacks.content)
                }
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])


    const handleSlackName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        // If the input value passes validation, update the state
        setRunSlackInt(inputValue);
    };

    const handleSlackURL = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        // If the input value passes validation, update the state
        setRunSlackIntURL(inputValue);
    };

    const handleSlackChannel = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setRunSlackIntChannel(inputValue)
    };

    const handleSlackSwitch = (event: boolean) => {
        setRunSlackIntSwitch(event)
    };

    const handleCancel = () => {
        setVisible(false)
        setRunSlackInt('')
        setRunSlackIntURL('')
        setRunSlackIntChannel('')
        setRunSlackIntSwitch(false)
        if (selectedSlack && setSelectedSlack) {
            setSelectedSlack(empty_slack)
        }
    };

    const handleUpdateDefaultProjectCusSlacks = async () => {
        const params = {
            project_id: project_id,
            org_id: org_id,
            title: runSlackInt,
            url: runSlackIntURL,
            channel_id: runSlackIntChannel,
            status: runSlackIntSwitch
        }

        const update_params = {
            project_id: project_id,
            org_id: org_id,
            id: selectedSlack?.id,
            title: runSlackInt,
            url: runSlackIntURL,
            channel_id: runSlackIntChannel,
            status: runSlackIntSwitch
        }

        dispatch(saveProjectSlackIntegration(selectedSlack === empty_slack ? params : update_params))
            .then(foundPage => {
                if (selectedSlack === empty_slack || selectedSlack === undefined) {
                    if (setSelectedSlack) {
                        const updated_slacks: any = slacks?.push(foundPage.content[0]);
                        if (setSlacks) {
                            setSlacks(updated_slacks)
                        }
                    }
                }
                if (selectedSlack !== empty_slack || selectedSlack !== undefined) {
                    setSlacks && setSlacks(slacks ? slacks.map(slack => slack.id === selectedSlack?.id ? update_params : slack) : []);
                }
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        setVisible(false)
        setRunSlackInt('')
        setRunSlackIntURL('')
        setRunSlackIntChannel('')
        setRunSlackIntSwitch(false)
        if (selectedSlack && setSelectedSlack) {
            setSelectedSlack(empty_slack)
        }
    };

    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
        <ButtonC key="submit_ok" type="primary" onClick={handleUpdateDefaultProjectCusSlacks}
                 text={selectedSlack === empty_slack ? 'Create' : 'Save'}
                 disabled={runSlackInt.length < 2 || (slacks?.some(item => item.title === runSlackInt) && selectedSlack?.title !== runSlackInt)}
        />
    ];


    return (
        <ModalC open={visible} title={selectedSlack !== empty_slack ? 'Edit Slack Message' : 'Create Slack Message'}
                onOk={handleUpdateDefaultProjectCusSlacks} onCancel={handleCancel}
                footer={footer_buttons}>
            <Form layout="vertical">
                <Form.Item label="Name">
                    {slacks?.some(item => item.title === runSlackInt) && selectedSlack?.title !== runSlackInt &&
                    <p style={{color: '#F6C324'}}>The specific Slack already exists!</p>}
                    <Input
                        placeholder='Provide name'
                        onChange={handleSlackName}
                        value={runSlackInt}/>
                </Form.Item>
                <Form.Item label="URL">
                    <Input
                        placeholder='Provide url'
                        onChange={handleSlackURL}
                        value={runSlackIntURL}/>
                </Form.Item>
                <Form.Item label="Channel">
                    <Input
                        placeholder='Provide channel'
                        onChange={handleSlackChannel}
                        value={runSlackIntChannel}/>
                </Form.Item>
                <Form.Item>
                    <SwitchC label='Active' onChange={handleSlackSwitch} defaultChecked={runSlackIntSwitch}/>
                </Form.Item>
            </Form>
        </ModalC>
    );
}
