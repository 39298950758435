import { User, OrgUsersInfo, TeamUsersInfo } from "../../models/entities/user";

export const empty_user:User = {
    id: 0,
    name: "",
    lastname: "",
    email: "",
    password: ""
}

export const empty_org_user_info:OrgUsersInfo = {
    org_id: 1,
    user_info: empty_user,
    role: 1,  // admin
    color: '#fff',
    user_teams_info: [],
    user_jobs_info: []
}

export const empty_team_user_info:TeamUsersInfo = {
    role: 1,  // admin
    color: '#fff',
    teams_info: [],
    user_info: empty_user
}