import React, { useEffect, useState } from 'react';
import { Calendar, Badge, CalendarProps, Row, Col, Select } from 'antd';
import moment, { Moment } from "moment";
import Page from '../../components/page_structure/page';
import PageContent from '../../components/page_structure/page_content';
import PageHeader from '../../components/page_structure/page_header';
import { useAppContext } from '../../AppContext';
import { getCalendarMonthRuns } from '../../redux/actions/calendar_actions';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { CalendarRuns } from "../../models/entities/calendar";
import { Dayjs } from 'dayjs';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

function MyCalendar(): JSX.Element {
  const dispatch: AppDispatch = useDispatch();
  const {currentProject, currentOrg} = useAppContext();
  const [monthRuns, setMonthRuns] = useState<CalendarRuns[]>();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<string>((new Date().getMonth() + 1).toString().padStart(2, '0'));

  useEffect(() => {
    const fetchData = () => {
        dispatch(getCalendarMonthRuns({project_id: currentProject.id, year: year, month: month})).then(found => {
            setMonthRuns(found.content)}).catch((err) => {
              safeHandleErrorResponse(err)
          })
    }
      fetchData()
  }, [dispatch, year, month]);
  
    function getListData(value: Moment) {
      const filteredItems = filterItemsBasedOnDate(monthRuns, value);

      return filteredItems || [];
      }

      function filterItemsBasedOnDate(items: CalendarRuns[] | undefined, date: Moment) {
        return items?.filter((item) => {
          // Implement your filtering condition based on the date
          // For example, compare with the date property of the item
          return moment(item.calendar_day).isSame(date.format("YYYY-MM-DD"), 'day');
        });
      }      

      function dateCellRender(date: any):any {
        const listData = getListData(date);
        return (
          <ul className="events" style={{listStyle:'none'}}>
            {listData.map(item => (
              <li key={item.title} title={item.title}>
                {/* <Badge status={item.type} text={item.content} /> */}
                  <a className="black_a_tag" target={'_blank'} href={`/${currentOrg.domain}/projects/${currentProject.p_key}/test-review/${item.id}`}><Badge status="success" /> {item.title}</a>
              </li>
            ))}
          </ul>
        );
      }
      
      function getMonthData(value:any) {
        if (value.month() === 8) {
          return 1394;
        }
      }
      
      function monthCellRender(value:any) {
        const num = getMonthData(value);
        return num ? (
          <div className="notes-month">
            <section>{num}</section>
            <span>Backlog number</span>
          </div>
        ) : null;
      }
      const cellRender: any['cellRender'] = (current: any, info: any) => {
        if (info.type === 'date') return dateCellRender(current);
        if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
      };

      const handleDateSelect = (date: any) => {
        // Handle the selected date
        setYear(date.year())
        setMonth((date.month() + 1).toString().padStart(2, '0'))
      };

      const customHeaderRender: CalendarProps<Dayjs>['headerRender'] = ({ value, type, onChange, onTypeChange }) => {
        const year = value.year();
        const month = value.month();
      
        const monthOptions = [];
        for (let i = 0; i < 12; i++) {
          monthOptions.push(
            <option key={i} value={i}>
              {value.clone().month(i).format('MMMM')}
            </option>
          );
        }
      
        const yearOptions = [];
        for (let i = year - 10; i < year + 10; i++) {
          yearOptions.push(
            <option key={i} value={i}>
              {i}
            </option>
          );
        }
      
        return (
          <div style={{ padding: '10px 0', textAlign: 'center' }}>
            <Row justify="center" gutter={8}>
              <Col>
                <Select
                  value={month}
                  onChange={(newMonth) => {
                    const newValue = value.clone().month(newMonth);
                    onChange(newValue);
                  }}
                  style={{ width: 120 }}
                >
                  {monthOptions}
                </Select>
              </Col>
              <Col>
                <Select
                  value={year}
                  onChange={(newYear) => {
                    const newValue = value.clone().year(newYear);
                    onChange(newValue);
                  }}
                  style={{ width: 100 }}
                >
                  {yearOptions}
                </Select>
              </Col>
            </Row>
          </div>
        );
      };
      

    return (
        <>
          <Page title="Calendar">
            <PageHeader title="Calendar"/>
            <PageContent>
               <Calendar cellRender={cellRender} onSelect={handleDateSelect} mode="month" headerRender={customHeaderRender}/>
                  {/* <div className="calendar_cell_options"> some options here</div> */}
            </PageContent>
          </Page>
            
        </>
    )
}

export default MyCalendar;
