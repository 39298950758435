import React, { useState } from 'react';
import { Input, Button, Typography, Space, Alert } from 'antd';

const { TextArea } = Input;
const { Text } = Typography;

const quoteKeys = (input: string): string => {
    // Regular expression to find unquoted keys in the JSON-like input
    return input.replace(/([{,]\s*)([A-Za-z_]\w*)(\s*:)/g, '$1"$2"$3');
};

const JsonValidatorFormatter: React.FC = () => {
    const [jsonInput, setJsonInput] = useState<string>('');
    const [formattedJson, setFormattedJson] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const handleValidateAndFormat = () => {
        try {
            // Quote the keys in the input before attempting to parse
            const quotedInput = quoteKeys(jsonInput);
            const parsedJson = JSON.parse(quotedInput);
            const formatted = JSON.stringify(parsedJson, null, 4); // Format JSON with 4-space indentation
            setFormattedJson(formatted);
            setError(null); // Clear any previous errors
        } catch (err) {
            if (err instanceof SyntaxError) {
                const errorMessage = extractErrorMessage(err.message, jsonInput);
                setError(`Invalid JSON: ${errorMessage}`);
            } else {
                setError('An unexpected error occurred.');
            }
            setFormattedJson('');
        }
    };

    const extractErrorMessage = (message: string, input: string): string => {
        const match = message.match(/position (\d+)/);
        if (match) {
            const position = parseInt(match[1], 10);
            const snippet = input.slice(Math.max(0, position - 10), position + 10);
            return `Syntax error near "${snippet}" at position ${position}`;
        }
        return message;
    };

    return (
        <Space direction="vertical" size="large" style={{ width: '97%'}}>
            <Text strong>JSON Validator and Formatter (with optional key quotes)</Text>
            <TextArea
                rows={10}
                value={jsonInput}
                onChange={(e) => setJsonInput(e.target.value)}
                placeholder="Enter or paste your JSON here"
            />
            <Button style={{marginLeft: '35%'}} type="primary" onClick={handleValidateAndFormat}>
                Validate and Format JSON
            </Button>
            {error && <Alert message={error} type="error" />}
            {!error && formattedJson && (
                <TextArea
                    rows={10}
                    value={formattedJson}
                    readOnly
                    style={{ backgroundColor: '#f5f5f5' }}
                />
            )}
        </Space>
    );
};

export default JsonValidatorFormatter;
