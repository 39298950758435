import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import ModalC from "../modal";
import { Form, Input, Select, Space } from 'antd';
import { useAppContext } from "../../AppContext";
import { AppDispatch } from "../../redux/store";
import { PageResource } from "../../models/dtos/page_resource";
import { createEmptyPage } from "../../services/utils/PageResourceUtils";
import { getTeamStats } from "../../redux/actions/team_actions";
import { Team } from "../../models/entities/team";
import RichEditor from "../rich_editor/rich_editor";
import { RunTeams } from "../../models/entities/run_script_notifications";
import ButtonC from "../button";
import { empty_team_run } from "../../services/EmptyEntities/EmptyRunTeams";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";

const {Option} = Select;

type PostProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  message: any;
  setMessage: Dispatch<any>;
  runTeams?: RunTeams[];
  setRunTeams?: Dispatch<any>;
  selectedTeam?: RunTeams;
  setSelectedTeam?: Dispatch<RunTeams>
}

export default function ShareWallNotificationPopup(props: PostProps):JSX.Element {
  const dispatch: AppDispatch = useDispatch();
  const {currentOrg, loggedInUser} = useAppContext();

  const {visible, setVisible, message, setMessage, runTeams, setRunTeams, selectedTeam, setSelectedTeam} = props;
  const [defaultTeams, setDefaultTeams] = useState<PageResource<Team>>(createEmptyPage());
  const [teamTitle, setTeamTitle] = useState<string>('');
  const [selectedTeams, setSelectedTeams] = useState<string[]>()
  const [editFlag, setEditFlag] = useState<boolean>(false)

  useEffect(() => {
      const fetchData = () => {
          dispatch(getTeamStats(currentOrg.id, loggedInUser.id, '')).then(teams => {
            setDefaultTeams(teams) 
          }).catch((err) => {
            safeHandleErrorResponse(err)
        })
      }
      fetchData()
  }, [dispatch])

  const handleEditorChange = (content: any) => {
    setMessage(content);
  };

  const handleCancel = () => {
    setVisible(false)
    setSelectedTeams([])
    setTeamTitle('')
    setMessage('')
    setEditFlag(false)
    if (setSelectedTeam)
      {setSelectedTeam(empty_team_run)}
};

const handleOk = () => {
  if (runTeams !== undefined && setRunTeams !== undefined) {
  if (selectedTeam !== empty_team_run)
  {
    const updatedTeams: RunTeams[] = (runTeams ?? []).map(item => {
      if (item.id === selectedTeam?.id) {
        return {
          ...item,
          team: selectedTeam.team,
          title: teamTitle || '', // Providing a default empty string if selectedChannel is undefined
          message: message,
          notify: { brs: false, ber: false, aer: false, are: false },
          id: selectedTeam.id
        };
      }
      return item;
    });
    
    setRunTeams(updatedTeams);
    
  }
  else {

    {selectedTeams && selectedTeams.forEach((selectedTeam) => {
      setRunTeams((prevRunTeams: RunTeams[]) => [
          ...prevRunTeams,
          {
              team: selectedTeam,
              title: teamTitle ? teamTitle : '',
              message: message,
              notify: { brs: false, ber: false, aer: false, are: false },
              id: prevRunTeams.length + 1 // Ensure unique id
          }
      ]);
  })}  
}}
    setVisible(false)
    setSelectedTeams([])
    setTeamTitle('')
    setMessage('')
    setEditFlag(false)
    if (setSelectedTeam)
      {setSelectedTeam(empty_team_run)}
};

useEffect(() => {
  setTeamTitle(selectedTeam ? selectedTeam?.title : '')
  if (selectedTeam && selectedTeam.team) {
    setSelectedTeams([selectedTeam.team]);
    setEditFlag(true)
  }
  setMessage(selectedTeam?.message)
}, [selectedTeam])


const handleTeamTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setTeamTitle(event.target.value);
};
    
    const handleTeamsChange = (event: string[]) => {
      setSelectedTeams(event);
  };


  const footer_buttons = [
    <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
    <ButtonC key="submit_ok" type="primary" onClick={() => handleOk()} text={selectedTeam === empty_team_run ? 'Create' : 'Save'} 
             disabled={teamTitle.length < 4 || message.length < 1 || selectedTeams && selectedTeams.length < 1 || (runTeams?.some(item => item.title === teamTitle) && selectedTeam?.title !== teamTitle)}/>
  ];
    
  return (
    <ModalC open={visible} title='Share to Team(s) Wall' onCancel={handleCancel} footer={footer_buttons}>
      <Form.Item>   
        <h4>Title:</h4>
        {runTeams?.some(item => item.title === teamTitle) && selectedTeam?.title !== teamTitle && <p style={{color: '#F6C324'}}>The specific Title already exists!</p>}
        <Input placeholder='Team(s) Wall Message Title'
                            value={teamTitle}
                            onChange={handleTeamTitleChange}
                            style={{width: 200, marginLeft: 20}}/>
        <h4>Message:</h4>
        <RichEditor value={message} onChange={handleEditorChange}/>
        <h4>Team(s):</h4>
        <Select
          mode="multiple"
          disabled={editFlag}
          style={{ width: 200, marginLeft: 20 }}
          placeholder="Select Team(s)"
          value={selectedTeams}
          onChange={handleTeamsChange}
          optionLabelProp="label">
          {defaultTeams.content.map((team) => (
            <Option key={team.name} label={team.name}>
              <Space>{team.name}</Space>
            </Option>
          ))}
        </Select>
      </Form.Item>
    </ModalC>
  );
}
