import React from 'react';
import { Badge } from 'antd';
import {BadgeProps} from "antd/es/badge";

interface badgeProps extends BadgeProps {
    maxNumber?: number,
    count: number
}

export function TPBadge(props: badgeProps): JSX.Element {
    let {color, count} = props

    return (
        <Badge offset={count > 9 ? [5, 0] : [0, 0]} color={color} {...props}>
            {props.children}
        </Badge>
    )
}

export default TPBadge