import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ButtonC from "../button";
import {Checkbox, Dropdown, Space, Table} from "antd";
import { ArgumentScriptSetting } from '../../models/entities/settings';
import { getProjectRunScripts } from '../../redux/actions/project_actions';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../AppContext';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import CancelAction from '../notification/cancel_req';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

interface ScriptsProps {
    scripts: ArgumentScriptSetting[],
    setScripts: Dispatch<SetStateAction<ArgumentScriptSetting[]>>
}

function TableRunScripts(props: ScriptsProps): JSX.Element{
    const dispatch: AppDispatch = useDispatch();
    const {currentOrg, currentProject} = useAppContext();

    const org_id = currentOrg.id
    const project_id = currentProject.id

    // const [runScripts, setRunScripts] = useState<ArgumentScriptSetting[]>([empty_script_run]);
    const [checkedScripts, setCheckedScripts] = useState<any[]>([])

    const {scripts, setScripts} = props

    const selected_scripts: number = checkedScripts ? checkedScripts.length : 0
    
    useEffect(() => {
        const fetchData = async () => {
            dispatch(getProjectRunScripts({org_id, project_id, only_visible: true})).then(scripts =>{
                // let active_scripts: ArgumentScriptSetting[] = [];
                // scripts.content.forEach(setting => {
                //     if (setting.status) {active_scripts.push(setting)}
                // ;
                setScripts(scripts.content.map(item => ({
                    ...item,
                    notify: {brs: false, ber: false, aer: false, are: false}
                  })))
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    const deleteSelectedCases = () => {
        let new_table = Object.values(scripts);
    
        new_table = new_table.filter(item => !checkedScripts.includes(item.id))
    
        setScripts(new_table);
        setCheckedScripts([]);
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Before',
            children: [
                {
                    title: 'Run Starts',
                    dataIndex: 'run_starts',
                    key: 'run_starts'
                },
                {
                    title: 'Each Run',
                    dataIndex: 'b_each_run',
                    key: 'b_each_run'
                }
            ]
        },
        {
            title: 'After',
            children: [
                {
                    title: 'Each Run',
                    dataIndex: 'a_each_run',
                    key: 'a_each_run'
                },
                {
                    title: 'Run Ends',
                    dataIndex: 'completion',
                    key: 'completion'
                }
            ]
        },
        {
            title: 
                <>  
                <Dropdown
                    disabled={selected_scripts === 0}
                    menu={{
                        items: [{
                            key: `mass-delete`,
                            label: <CancelAction secs={2} action={deleteSelectedCases}><DeleteOutlined /> Delete</CancelAction>,
                        }]
                    }}
                >
                    <a>
                        <Space>
                            Actions <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                <div style={{fontSize: 11}}>selected {selected_scripts}</div></>,
            dataIndex: 'actions',
            key: 'actions'
        }
    ];

    const handleBRSCheckboxChange = (id: number | undefined) => {
        setScripts(scripts.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        brs: !script.notify?.brs
                    }
                };
            }
            return script;
        }));
    };

    const handleBERCheckboxChange = (id: number | undefined) => {
        setScripts(scripts.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        ber: !script.notify?.ber
                    }
                };
            }
            return script;
        }));
    };

    const handleAERCheckboxChange = (id: number | undefined) => {
        setScripts(scripts.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        aer: !script.notify?.aer
                    }
                };
            }
            return script;
        }));
    };

    const handleARECheckboxChange = (id: number | undefined) => {
        setScripts(scripts.map(script => {
            if (script.id === id) {
                return { 
                    ...script, 
                    notify: {
                        ...script.notify,
                        are: !script.notify?.are
                    }
                };
            }
            return script;
        }));
    };

    return (
        <>
        <ButtonC key={'clear_all'} type={"primary"} onClick={()=>setScripts([])} text={'Clear All'} style={{float:'right', marginBottom: 10}}/>
        <Table
            pagination={{ pageSize: 10, hideOnSinglePage: true }}
            rowSelection={{
                selectedRowKeys: checkedScripts,
                onChange: (keys, rows) => {
                    setCheckedScripts(keys)
                }
            }}
            dataSource={scripts && scripts.map(script => ({
                title: script.title,
                key: script.id,
                run_starts: <Checkbox checked={script.notify?.brs} onChange={() => handleBRSCheckboxChange(script.id)}/>,
                b_each_run: <Checkbox checked={script.notify?.ber} onChange={() => handleBERCheckboxChange(script.id)}/>,
                a_each_run: <Checkbox checked={script.notify?.aer} onChange={() => handleAERCheckboxChange(script.id)}/>,
                completion: <Checkbox checked={script.notify?.are} onChange={() => handleARECheckboxChange(script.id)}/>,
                actions: <div style={{ display: 'flex', alignItems: 'center' }} />
            }))}
            columns={columns}
        />
        </>
    );
};

export default TableRunScripts;