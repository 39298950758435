import React, {useEffect, useState} from 'react';
import Page from "../../components/page_structure/page";
import PageContent from "../../components/page_structure/page_content";
import {Avatar, Card, Col, Divider, Empty, Row, Timeline} from 'antd';
import {useAppContext} from "../../AppContext";
import {useParams} from "react-router-dom";
import {UserProfileInfo, UserProfileJobInfo} from "../../models/entities/user";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {
    getUserInfo,
    getUserProfileJobs,
    getUserProfileProjects,
    getUserProfileTeams,
    userCasesMaintain,
    userReliabilityAction,
    userRunsCreated
} from "../../redux/actions/user_actions";
import PageHeader from '../../components/page_structure/page_header';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';


function UserProfile(): JSX.Element {
    /*
      get user ID, check if 
        - user in Organization.
        - user exists
            - both cases -> error page.
    */

    // borderRadius:'0.4em'
    const dispatch: AppDispatch = useDispatch();
    const {currentOrg, loggedInUser} = useAppContext()
    const {user_id} = useParams();

    const [userInfo, setUserInfo] = useState<UserProfileInfo>()
    const [userCases, setUserCases] = useState<number>()
    const [userMonthCases, setUserMonthCases] = useState<number>()
    const [userRuns, setUserRuns] = useState<number>()
    const [userMonthRuns, setUserMonthRuns] = useState<number>()
    const [userReliability, setUserReliability] = useState<string>()
    const [userProjects, setUserProjects] = useState<{ id: number, name: string, p_key: string }[]>([])
    const [userTeams, setUserTeams] = useState<{ id: number, name: string }[]>([])
    const [userJobs, setUserJobs] = useState<UserProfileJobInfo[]>()

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getUserInfo({user_id: user_id ? parseInt(user_id) : 0})).then(info => {
                setUserInfo(info)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(userCasesMaintain({
                user_id: user_id ? parseInt(user_id) : 0,
                org_id: currentOrg.id,
                current: true
            })).then(info => {
                setUserMonthCases(info.user_tests)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(userRunsCreated({
                user_id: user_id ? parseInt(user_id) : 0,
                org_id: currentOrg.id,
                current: true
            })).then(info => {
                setUserMonthRuns(info.runs_created)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(userCasesMaintain({
                user_id: user_id ? parseInt(user_id) : 0,
                org_id: currentOrg.id,
                current: null
            })).then(info => {
                setUserCases(info.user_tests)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(userRunsCreated({
                user_id: user_id ? parseInt(user_id) : 0,
                org_id: currentOrg.id,
                current: null
            })).then(info => {
                setUserRuns(info.runs_created)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(userReliabilityAction({
                user_id: user_id ? parseInt(user_id) : 0,
                org_id: currentOrg.id
            })).then(info => {
                setUserReliability(info.user_reliability)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getUserProfileProjects({
                loginUserId: loggedInUser.id,
                targetUserId: user_id ? parseInt(user_id) : 0,
                org_id: currentOrg.id
            })).then((info) => {
                setUserProjects(info.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getUserProfileTeams({
                loginUserId: loggedInUser.id,
                targetUserId: user_id ? parseInt(user_id) : 0,
                org_id: currentOrg.id
            })).then((info) => {
                setUserTeams(info.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getUserProfileJobs(user_id ? parseInt(user_id) : 0, currentOrg.id)).then((info) => {
                setUserJobs(info.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])


    return (
        <Page title="User Profile">
            <PageHeader title={`Profile`}/>
            <PageContent>
                <Col span={16} offset={4}>
                    <Card title={<div><Avatar
                        src={userInfo?.img === undefined || userInfo?.img === null ? '/icons/default_user.png' : userInfo?.img}
                        style={{width: 200, height: 'auto', textAlign: 'center', marginBottom: 10}}/>
                        <h3 style={{float: 'right', color: 'grey'}}>{currentOrg.name}</h3>
                        <img alt='Org Img' style={{float: 'right', color: 'grey', width: 80}}
                             src='/user_images/logo.jpeg'/></div>}
                          style={{marginRight: 25, background: 'white', border: 'none'}}/>
                    <Row>
                        <Col flex="1 1 200px">
                            <Card hoverable={true}
                                  style={{marginTop: 20, width: 'auto', height: 'auto', background: 'whitesmoke'}}
                                  title={<h3 style={{textAlign: 'center', color: 'grey'}}>Personal Information</h3>}>
                                <div>
                                    <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <h4 style={{color: 'grey', marginLeft: 10}}>{userInfo?.fullname}</h4>
                                        <h4 style={{
                                            color: 'grey',
                                            marginLeft: 10,
                                            marginRight: 10
                                        }}>{userInfo?.email}</h4></Row>
                                    <p style={{color: 'grey', marginLeft: 10}}>{userInfo?.description}</p>
                                    <Divider/>
                                </div>
                            </Card>
                            <Card hoverable={true}
                                  style={{marginTop: 20, width: 'auto', height: 'auto', background: 'whitesmoke'}}
                                  title={<h3 style={{textAlign: 'center', color: 'grey'}}>Organization Experience</h3>}>
                                {userJobs && userJobs?.length > 0 ?
                                    <Timeline mode={'left'} style={{float: 'left', width: 300, marginTop: 30}}>
                                        {userJobs?.map((job) => (
                                            <Timeline.Item label={`${job.start_date} - ${job.end_date}`}
                                                           key={job.id}>{job.name}</Timeline.Item>
                                        ))}
                                    </Timeline> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                                <Divider/>
                            </Card>
                            <Card hoverable={true}
                                  style={{marginTop: 20, width: 'auto', height: 'auto', background: 'whitesmoke'}}
                                  title={<h3 style={{textAlign: 'center', color: 'grey'}}>Analytics</h3>}>
                                <div>
                                    <div>
                                        <div style={{display: 'flex'}}><h4 style={{color: 'grey', marginLeft: 10}}>Total
                                            Test Cases: </h4>
                                            <h4 style={{
                                                color: 'grey',
                                                marginLeft: 10,
                                                fontWeight: 'normal'
                                            }}>{userCases}</h4>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <h4 style={{color: 'grey', marginLeft: 10}}>Current Month Test Cases: </h4>
                                            <h4 style={{
                                                color: 'grey',
                                                marginLeft: 10,
                                                fontWeight: 'normal'
                                            }}>{userMonthCases}</h4>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <h4 style={{color: 'grey', marginLeft: 10}}>Reliability: </h4>
                                            <h4 style={{
                                                color: 'grey',
                                                marginLeft: 10,
                                                fontWeight: 'normal'
                                            }}>{userReliability}</h4>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <h4 style={{color: 'grey', marginLeft: 10}}>Total Runs Created: </h4>
                                            <h4 style={{
                                                color: 'grey',
                                                marginLeft: 10,
                                                fontWeight: 'normal'
                                            }}>{userRuns}</h4>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <h4 style={{color: 'grey', marginLeft: 10}}>Current Month Runs
                                                Created: </h4>
                                            <h4 style={{
                                                color: 'grey',
                                                marginLeft: 10,
                                                fontWeight: 'normal'
                                            }}>{userMonthRuns}</h4>
                                        </div>
                                        <Divider/>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col flex="0 1 200px">
                            <Card hoverable={true} style={{
                                marginTop: 20,
                                marginLeft: 20,
                                width: 'auto',
                                height: 'auto',
                                background: 'whitesmoke'
                            }}
                                  title={<h3 style={{textAlign: 'center', color: 'grey'}}>Projects</h3>}>
                                {userProjects.length > 0 ? userProjects?.map((project) => (
                                    <div key={project.id}>
                                        <h4 style={{marginLeft: 10}}>
                                            <a href={`/${currentOrg.domain}/projects/${project.p_key}/overview`}
                                               style={{color: 'grey', textDecoration: 'none'}}
                                               onMouseOver={(e: any) => e.target.style.color = 'black'}
                                               onMouseOut={(e: any) => e.target.style.color = 'grey'}>
                                                {project.name}
                                            </a>
                                        </h4>
                                    </div>
                                )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                            </Card>
                            <Card hoverable={true} style={{
                                marginTop: 20,
                                marginLeft: 20,
                                width: 'auto',
                                height: 'auto',
                                background: 'whitesmoke'
                            }}
                                  title={<h3 style={{textAlign: 'center', color: 'grey'}}>Teams</h3>}>
                                {userTeams.length > 0 ? userTeams?.map((team) => (
                                    <div key={team.id}>
                                        <h4 style={{marginLeft: 10}}>
                                            <a href={`/${currentOrg.domain}/teams/${team.id}/${team.name}/wall`}
                                               style={{color: 'grey', textDecoration: 'none'}}
                                               onMouseOver={(e: any) => e.target.style.color = 'black'}
                                               onMouseOut={(e: any) => e.target.style.color = 'grey'}>
                                                {team.name}
                                            </a>
                                        </h4>
                                    </div>
                                )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                            </Card>
                        </Col></Row>
                </Col>
            </PageContent>
        </Page>
    )
}

export default UserProfile;