import { AppDispatch } from "../store";
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { Organization, OrgOwners, OrgSetting } from "../../models/entities/organization";
import { LandingProject, Project } from "../../models/entities/project";
import {get, post, put} from '../../services/axiosWrapper';
import { PageResource } from "../../models/dtos/page_resource";

const api_path = '/api/org/';

export const getTotalOrgUsers = (id:number) => (dispatch: AppDispatch): Promise<{total_users: number}> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getTotalOrgUsers?id=${id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const createOrganization  = (params: {name: string, domain: string, user_id: number, project_name?: string, project_key?: string, teams?: string[]}) => (dispatch: AppDispatch): Promise<{organization: Organization, project: Project}> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}/createOrganization`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateOrg = (params: {org_id: number, name?: string, domain?: string}) => (dispatch: AppDispatch): Promise<PageResource<LandingProject>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateOrg`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getOrgSettings = (org_id:number) => (dispatch: AppDispatch): Promise<{content: OrgSetting}> => {
    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getOrgSettings?org_id=${org_id}`)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const updateOrgOwner = (params: {org_id: number, owner_id: number}) => (dispatch: AppDispatch): Promise<PageResource<OrgOwners>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateOrgOwner`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
