const dark = '#004777'
const blue_default = '#1677ff'
const bamboo = '#006442'
const moss = '#addfad'

export const colors:any = {
    blue:{
        dark,
        default: blue_default,
        gradient: `linear-gradient(45deg, ${dark}, ${blue_default})`
    },
    green:{
        forest: '#228B22',  // Clickable Texts
        bamboo,  // Info
        default: '#51C615',
        gradient: `linear-gradient(45deg, ${bamboo}, ${moss})`,
        tea: '#d0f0c0',
        moss
    },
    red:{
        vermilion: '#D22B2B',  // Clickable Texts
    },
    gray:{
        default: '#f1f1f1',
        shadow: '#b1b1b1',  // Descriptions
        dark: '#111111',
        submenu: '#1b1b1b',
        background: "#f0f2f5",  // Default Background Color
        signup: '#E9E8E4',
        login: '#DEE1DE',
        forgot: '#E5E3E1',
        underConstruction: '#E7E4DC',
        createOrg: '#E3E1CF'
    },
    yellow:{
        default: '#F7B801'
    },
    orange:{
        default: '#e56131'  // Called pandas fabric
    }
}