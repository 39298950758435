import { Team, TeamStats } from "../../models/entities/team";

export const empty_team:Team = {
    id: 0,
    name: "",
    description: "",
    org_id: 0
}

export const empty_team_stats:TeamStats =  {
    id: 0,
    name: "",
    description: "",
    org_id: 0,
    jobs: []
}