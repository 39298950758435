import React, { useEffect, useState } from 'react';
import './emoji_picker.css'; // Assuming you have the corresponding CSS file
import { useAppContext } from '../../AppContext';
import { getReactions } from '../../redux/actions/reaction_actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { NewReaction, Reactions } from '../../models/entities/reactions';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

interface EmojiPickerProps {
  onSelect: (emojis: NewReaction | NewReaction[]) => void;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onSelect }) => {
  const [emojiData, setEmojiData] = useState<Reactions[]>([]);
  const emojisByCategory: Record<string, NewReaction[]> = {};

  emojiData.forEach((emoji) => {
    if (!emojisByCategory[emoji.category]) {
      emojisByCategory[emoji.category] = [];
    }
    emojisByCategory[emoji.category].push({reaction_icons: emoji.name, reaction_id: emoji.id}); 
  });

  const [selectedCategory, setSelectedCategory] = useState(Object.keys(emojisByCategory)[0]);
  const {currentOrg} = useAppContext();
  const dispatch: AppDispatch = useDispatch();

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const fetchData = () => {
        dispatch(getReactions({ org_id: currentOrg.id })).then((found) => {
            // console.log(found); // Add a meaningful message or data to log
            setEmojiData(found.content)
        }).catch((err) => {
          safeHandleErrorResponse(err)
      });
    };

    fetchData();
}, [dispatch, EmojiPicker]);

const handleEmojiClick = (emoji: any) => {
  onSelect(emoji);
};

  const filteredEmojis = emojisByCategory[selectedCategory]

  return (
    <div className="emoji-picker-container" style={{float: 'right', marginTop: 5, zIndex: 999, position: 'absolute', backgroundColor: 'whitesmoke'}}>
      <div className="emoji-picker-header">
      </div>
      <div className="emoji-picker-categories">
        {Object.keys(emojisByCategory).map((category) => (
          <button
            key={category}
            className={selectedCategory === category ? 'active' : ''}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="emoji-picker-emojis">
        {filteredEmojis && filteredEmojis.map((emoji) => (
      <span key={emoji.reaction_id} onClick={() => handleEmojiClick(emoji)}>
        {emoji.reaction_icons}
      </span>
        ))}
      </div>
    </div>
  );
};

export default EmojiPicker;
