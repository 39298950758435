import {AppDispatch} from "../store";
import {fetchErrorAction, fetchSuccessAction} from "./creators/common_actions";
import { get } from "../../services/axiosWrapper";



export const getRunResultsViaExcel = (run_id:number) => (dispatch: AppDispatch): Promise<any> => {

    const request: any = get(`/api/run/getRunResultsViaExcel?run_id=${run_id}`);

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response.data; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
}