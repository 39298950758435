import React  from "react";
import { Line } from '@ant-design/plots';
import { colors } from "../../assets/data/colors";

interface LineCProps {
    data: { type: string, value: number }[],
    colors?: string[],
    style?: React.CSSProperties
}

interface MultiLineCProps {
    data: { month: string, key: string, title: string, value: number }[],
    style?: React.CSSProperties
}

export const BasicLine = React.memo<LineCProps>(({data, style}) => {
    const config = {
        data,
        style,
        xField: 'type',
        yField: 'value',
        xAxis: {
            tickCount: 7,
        },
        slider: {
            start: 0,
            end: 1,
        },
        tooltip: {
            customContent: (title: string, items: any[]) => {
      
              return `
                <div style="background-color: #f9f9f9; border-radius: 8px; padding: 20px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
                  <h2 style="font-size: 1.2rem; font-weight: bold; margin-bottom: 10px; color: grey;">${title}</h2>
                  <hr style="border: none; border-bottom: 1px solid #ccc; margin-bottom: 10px;">
                  <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                    ${items.map(item => `
                        <div style="margin-bottom: 8px; padding: 8px; background-color: #fff; border-radius: 6px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
                        <span style="font-weight: bold; color: ${colors.blue.default};">New Cases:</span>    
                        <span style="margin-left: 4px;">${item.value}</span>
                        </div>`).join('')
                    }
                </div>
                </div>`;
            },
          }
    };

    return (<Line {...config} />)
})

export const MultiLine: React.FC<MultiLineCProps> = React.memo(({ data, style }) => {
    // Custom color mapping based on title
    const colorMap: { [title: string]: string } = {
        'Passed': colors.green.forest,
        'Failed': colors.red.vermilion,
        'Unresolved': colors.orange.default,
        'Untested': colors.gray.shadow,
    };

    const config = {
        data,
        style,
        xField: 'month',
        yField: 'value',
        seriesField: 'title', // This maps each line to a unique title
        color: (datum: any) => colorMap[datum.title], // Mapping title to custom color
        xAxis: {
            tickCount: 7,
            label: {
                style: {
                    fontSize: 14, // Adjust x-axis label font size
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 14, // Adjust y-axis label font size
                },
            },
        },
        slider: {
            start: 0,
            end: 1,
        },
        tooltip: {
            customContent: (title: string, items: any[]) => {
                // Customize the tooltip content here, if needed
                return `
                <div style="background-color: #f9f9f9; border-radius: 8px; padding: 20px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
                <h2 style="font-size: 1.2rem; font-weight: bold; margin-bottom: 10px; color: grey;">${title}</h2>
                <hr style="border: none; border-bottom: 1px solid #ccc; margin-bottom: 10px;">
                <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                    ${items.map(item => `
                        <div style="margin-bottom: 8px; padding: 8px; background-color: #fff; border-radius: 6px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
                            <span style="font-weight: bold; color: ${colorMap[item.name]};">${item.name}:</span>
                            <span style="margin-left: 4px;">${item.value}</span>
                        </div>`).join('')
                    }
                </div>
            </div>`;
            },
        },
    };

    return <Line {...config} />;
});
