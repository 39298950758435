import React, { useEffect, useState } from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import Settings from "../../components/settings";
import { setting_sections } from '../../models/entities/settings';
import {useNavigate} from "react-router-dom";
import {Button, FloatButton, Input, Popconfirm, Select} from "antd";
import ButtonC from "../../components/button";
import UploaderC from "../../components/uploaders/uploaderC";
import {useAppContext} from "../../AppContext";
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { UserModal } from '../../components/modals/user_modal';
import { empty_org_user_info } from '../../services/EmptyEntities/EmptyUser';
import { OrgUsersInfo } from '../../models/entities/user';
import { createEmptyPage } from '../../services/utils/PageResourceUtils';
import { ComponentModal } from '../../components/modals/component_modal';
import {empty_component} from "../../services/EmptyEntities/EmptyComponent";
import {Component} from "../../models/entities/component";
import { PageResource } from '../../models/dtos/page_resource';
import { getOrgSettings, getTotalOrgUsers, updateOrg, updateOrgOwner } from '../../redux/actions/org_actions';
import { getComponents } from '../../redux/actions/component_actions';
import { getOrgUserInfo } from '../../redux/actions/user_actions';
import { CheckCircleOutlined } from '@ant-design/icons';
import { colors } from '../../assets/data/colors';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

const { Option } = Select;

function OrgSettings(): JSX.Element {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const {currentOrg, setAppContextOrg} = useAppContext();

    const [orgTitle, setOrgTitle] = useState<string>(currentOrg.domain);
    const [orgDomain, setOrgDomain] = useState<string>(currentOrg.domain);
    const [totalUsers, setTotalUsers] = useState<number>();
    const [visible, setVisible] = useState<boolean>(false);
    const [compVisible, setCompVisible] = useState<boolean>(false);
    const [userPage, setUsersPage] = useState<PageResource<OrgUsersInfo>>(createEmptyPage());
    const [selectedUser, setSelectedUser] = useState<OrgUsersInfo>(empty_org_user_info);
    const [initComponentPage, setInitComponentPage] = useState<PageResource<Component>>(createEmptyPage())
    const [selectedComponent, setSelectedComponent] = useState<Component>(empty_component);
    const [orgOwnerName, setOrgOwnerName] = useState<any>();
    const [orgOwner, setOrgOwner] = useState<number>();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getOrgSettings(currentOrg.id)).then(settings => {
            setOrgTitle(settings.content.name ? settings.content.name : '');
            setOrgDomain(settings.content.domain ? settings.content.domain : '');
            setOrgOwner(settings.content.owner_id);
            setOrgOwnerName(settings.content.owner_name + " " + settings.content.owner_lastname);}).catch((err) => {
                safeHandleErrorResponse(err)
            }) 
        }
        fetchData()
    }, [dispatch, currentOrg])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getTotalOrgUsers(currentOrg.id)).then(users => {
                setTotalUsers(users.total_users)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch, currentOrg])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getComponents({org_id: currentOrg.id})).then(foundPage => {
                setInitComponentPage(foundPage)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch, currentOrg])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getOrgUserInfo(currentOrg.id, '')).then(foundPage => {
                setUsersPage(foundPage)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch, currentOrg])

    const handleRenameTitle = () => {
        const params = {
            org_id: currentOrg.id,
            name: orgTitle
        }
        dispatch(updateOrg(params))
            .then(() =>{
                setAppContextOrg({...currentOrg, name: orgTitle})
                setOrgTitle(orgTitle)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
      };

    const handleRenameKey = () => {
      const params = {
          org_id: currentOrg.id,
          domain: orgDomain
      }
      dispatch(updateOrg(params))
          .then(() => {
            setAppContextOrg({...currentOrg, domain: orgDomain})
              setOrgDomain(orgDomain)
              navigate(`/${orgDomain}/manage/settings`)
          }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }

    const handleUpdateOrgOwner = async () => {
        if (orgOwner) {
        const params = {
            org_id: currentOrg.id,
            owner_id: orgOwner
        }
        dispatch(updateOrgOwner(params)).then(() => {setOrgOwner(orgOwner);}).catch((err) => {
            safeHandleErrorResponse(err)
        })}
    };

    const constructOrgOwners = () => {
        return userPage.content.map(user => {
            return <Option value={user.user_info.id} label={user.user_info.name + " " + user.user_info.lastname} style={user.user_info.id === orgOwner ? {color: colors.green.forest} : {color: 'black'}}>
                    {user.user_info.name + " " + user.user_info.lastname}
                   </Option>
        });

    }

    const setting_sections: setting_sections = [
        {attr_id: 'ps-general', title: 'General', settings: [
                {
                    title: 'Organization Title', description:'Change the Title of Organization.',
                    value: <><Input placeholder='Organization Title' value={orgTitle} onChange={(event) => setOrgTitle(event.target.value)} style={{width: 200}}/>
                             <Button type='default' onClick={handleRenameTitle} disabled={orgTitle === currentOrg.domain}>Rename</Button></>
                },{
                    title: 'Organization Image', description:'300x200',
                    value: <UploaderC
                        max_items={1}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card" />
                }
            ]},
        {attr_id: 'ps-users', title: 'Users', settings: [
                {
                    title: 'User Dashboard', description: `Total ${totalUsers} members.`,
                    value: <ButtonC onClick={()=>navigate(`/${currentOrg.domain}/users`)} text='View Members'/>
                },{
                    title: 'Add Users', description: `Add users to your Organization.`,
                    value: <> <ButtonC onClick={() => setVisible(true)} text='Add New'/>
                              <UserModal setVisible={setVisible} visible={visible}
                                         user={selectedUser} setUserPage={setUsersPage}
                                         userPage={userPage} setUser={setSelectedUser}
        /> 
        </>
                }
            ]},
        {attr_id: 'ps-components', title: 'Components', settings: [
                // {
                //     title: 'Who Can Create Organizational Components', description: `Organizational components can be used by projects and books.`,
                //     value: <ButtonC onClick={()=>navigate(`/${currentOrg.domain}/manage/users`)} text='View Users'/>
                // },
                {
                    title: 'Add Components', description: `Add components to your Organization.`,
                    value: <> <ButtonC onClick={() => setCompVisible(true)} text='Add New'/>
                                <ComponentModal setVisible={setCompVisible} visible={compVisible}
                                                componentPage={initComponentPage} setComponentPage={setInitComponentPage}
                                                selectedComponent={selectedComponent} setSelectedComponent={setSelectedComponent}
                                                hideGlobal={true}
            /> 
            </>
                },
                // {
                //     title: 'Color', description: `Add components to your Organization.`,
                //     value: <> <h2>Make this prettier - color picker instead of button each</h2><ButtonC text='Org Color' onClick={()=>{}}/><ButtonC text='Save, Show If Changed' onClick={()=>{}}/><br/><ButtonC text='Project Color' onClick={()=>{}}/><ButtonC text='Save, Show If Changed' onClick={()=>{}}/>  </>
                // }
            ]},
        {attr_id: 'ps-actions', title: 'Actions', settings: [
            // {
            //     title: 'Usage',
            //     description: 'View Your Usage',
            //     value: <Switch/>
            // },{
            //     title: 'Plan', description: '{PlanType}',
            //     value: <Button type='primary' shape='round' >Change Plan</Button>
            // },
            {
                title: 'Organization Domain', description:'No spaces are allowed.',
                value: <><Input placeholder='Organization Domain' value={orgDomain} onChange={(event) => setOrgDomain(event.target.value)} style={{width: 200}}/>
                         <Button type='default' onClick={handleRenameKey} disabled={orgDomain === currentOrg.domain}>Rename</Button></>
            },
            // {
            //     title: 'Storage', description: 'Clear Storage. This Action can not be undone.',
            //     value: <Button danger type='default'>clear</Button>
            // },
            {
                title: 'Transfer Ownership',
                description: 'Please change the owner of the organization. The new owner must be both a Member and an Admin within this organization. Kindly ensure you are aware of the implications of this action.',
                value: <>
                {/* <Input style={{width: 200}}/><Button danger type='default'>Set</Button> */}
                <Select onChange={(event) => setOrgOwner(event)} placeholder="Choose Organization Owner" 
                             defaultValue={orgOwnerName}>
                    {constructOrgOwners()}
                    </Select>
                    <Button
                    style={{marginLeft: 10}}
                    size='small'
                    shape='circle'
                    onClick={handleUpdateOrgOwner}
                    icon={<CheckCircleOutlined
                    style={{color: colors.green.forest}}/>}/>
                       </>
            },
            // {
            //     title: 'Delete', description:'This can not be undone.',
            //     value: <Popconfirm placement="top"
            //                        title={<p>Are you sure you want to Delete Organization <b>{orgTitle}</b> <br/>
            //                            This action is not reversible.</p>}
            //                        onConfirm={() => {}}
            //                        okText='Delete'

            //     > <Button danger>Delete</Button> </Popconfirm>}
            ]}
    ]

    return (
        <Page title="OrgSettings">
            <PageHeader title="Organization Settings"/>
            <PageContent>
                <Settings setting_sections={setting_sections}/>
                <FloatButton.BackTop />
            </PageContent>
        </Page>
    )
}

export default OrgSettings;