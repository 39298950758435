import {colors} from './colors';

export const run_statuses:any = {
    0: {
        title:'Failed',
        color: colors.red.vermilion
    },
    1: {
        title:'Passed',
        color: colors.green.forest
    },
    2:{
        title:'Untested',
        color: colors.gray.shadow
    },
    3:{
        title:'Unresolved',
        color: colors.orange.default
    }
}
