import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./post_popup.css";
import React from "react";
import ModalC from "../modal";
import { Form, Input, Switch } from 'antd';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import 'quill-image-upload';
import { useAppContext } from "../../AppContext";
import { AppDispatch } from "../../redux/store";
import ButtonC from "../button";
import { empty_argument } from "../../services/EmptyEntities/EmptyArgs";
import { ArgumentScriptSetting } from "../../models/entities/settings";
import { saveProjectRunScript } from "../../redux/actions/project_actions";
import TextArea from "antd/es/input/TextArea";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";

type SystemScriptProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  sysScripts?: ArgumentScriptSetting[];
  setSysScripts?: Dispatch<ArgumentScriptSetting[]>;
  selectedScript?: ArgumentScriptSetting;
  setSelectedScript?: Dispatch<SetStateAction<ArgumentScriptSetting | undefined>>;
}

export default function SystemScripts(props: SystemScriptProps):JSX.Element {
  
  const dispatch: AppDispatch = useDispatch();
  const {currentOrg, currentProject} = useAppContext();

  const org_id = currentOrg.id
  const project_id = currentProject.id
  const {visible, setVisible, sysScripts, setSysScripts, selectedScript, setSelectedScript} = props;

  const [scriptTitle, setScriptTitle] = useState<string>('');
  const [scriptDefaultValue, setScriptDefaultValue] = useState<string>('');
  const [scriptStatus, setScriptStatus] = useState<boolean>(false);
  const [restriction, setRestriction] = useState<boolean>(false);

  useEffect(() => {
    setScriptTitle(selectedScript?.title || '');
    setScriptDefaultValue(selectedScript?.default_value || '');
    setScriptStatus(selectedScript?.status || false);
  }, [selectedScript]);

  const handleScriptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Validate against .env restrictions
    const envValueRegex = /^[A-Za-z_][A-Za-z0-9_]*$/;
    if (!envValueRegex.test(inputValue)) {
      // If the input value violates the .env restrictions, don't update the state
      setRestriction(true)
    }

    // If the input value passes validation, update the state
    setScriptTitle(inputValue);
  };

  const handleScriptDefaultValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setScriptDefaultValue(event.target.value)
  };

  const handleScriptSwitchChange = (event: boolean) => {
    setScriptStatus(event)
 };

  const handleCancel = () => {
    setVisible(false)
    setScriptTitle('')
    setScriptDefaultValue('')
    setScriptStatus(false)
    if (selectedScript && setSelectedScript)
    {setSelectedScript(empty_argument)}
};

const handleUpdateScript = async () => {
    const params = {
        project_id: project_id,
        org_id: org_id,
        title: scriptTitle,
        default_value: scriptDefaultValue,
        status: scriptStatus}
    
    const update_params = {
        project_id: project_id,
        org_id: org_id,
        id: selectedScript?.id,
        title: scriptTitle,
        default_value: scriptDefaultValue,
        status: scriptStatus}

    dispatch(saveProjectRunScript(selectedScript === empty_argument ? params : update_params)).then(foundPage => {
        if (selectedScript === empty_argument || selectedScript === undefined) {
            if (setSysScripts)
            {const updated_scripts: any = sysScripts?.push(params); setSysScripts(updated_scripts)};
        }
        if (selectedScript !== empty_argument || selectedScript !== undefined) {
            setSysScripts && setSysScripts(sysScripts ? sysScripts.map(script => script.id === selectedScript?.id ? update_params : script) : []);
        }
    }).catch((err) => {
      safeHandleErrorResponse(err)
  })

    setVisible(false)
    setScriptTitle('')
    setScriptDefaultValue('')
    setScriptStatus(false)
    if (selectedScript && setSelectedScript)
    {setSelectedScript(empty_argument)}
};

const footer_buttons = [
  <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
  <ButtonC key="submit_ok" type="primary" onClick={handleUpdateScript} text={selectedScript === empty_argument ? 'Create' : 'Save'} 
           disabled={scriptTitle.length < 4 || (sysScripts?.some(item => item.title === scriptTitle) && selectedScript?.title !== scriptTitle) || restriction}
           />
];


  return (
    <ModalC open={visible} title={selectedScript !== empty_argument ? 'Edit System Script' : 'Create System Script'} onOk={handleUpdateScript} onCancel={handleCancel}
    footer={footer_buttons}>
      <Form layout="vertical">
        <Form.Item label="Title">
        {sysScripts?.some(item => item.title === scriptTitle) && selectedScript?.title !== scriptTitle && <p style={{color: '#F6C324'}}>The specific System Script already exists!</p>}
        {restriction && <p style={{color: '#F6C324'}}>Invalid input value. Please use only letters, numbers, and underscores, starting with a letter or underscore!</p>}
          <Input
            placeholder='Provide value'
            onChange={handleScriptChange}
            value={scriptTitle} />
        </Form.Item>
        <Form.Item label="Default Value">
          <TextArea
            placeholder='Provide default value'
            onChange={handleScriptDefaultValueChange}
            value={scriptDefaultValue} />
        </Form.Item>
        <Form.Item label="Status">
          <Switch
            onChange={handleScriptSwitchChange}
            checked={scriptStatus}
            defaultChecked={scriptStatus} />
        </Form.Item>
      </Form>
    </ModalC>
  );
}
