import React, { useEffect, useState } from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Settings from "../../components/settings";
import { setting_sections } from '../../models/entities/settings';
import {Button, FloatButton, Input, Popconfirm, Select, Switch} from "antd";
import ButtonC from "../../components/button";
import {LcsKeys, remove_item_from_lcs} from "../../assets/local_storage/lcs";
import {deleteTeam, getTeamSettings, getTeamUsers, updateTeam, updateTeamOwner, updateTeamNotificationSetting} from "../../redux/actions/team_actions";
import {useAppContext} from "../../AppContext";
import { colors } from '../../assets/data/colors';
import { CheckCircleOutlined } from '@ant-design/icons';
import { User } from '../../models/entities/user';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
const { Option } = Select;


function TeamSettings(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const {currentOrg, currentTeam, setAppContextTeam} = useAppContext();
    const org_id = currentOrg.id
    const team_id= currentTeam.id
    const params = {org_id, team_id}

    const [teamTitle, setTeamTitle] = useState<any>();
    const [teamDescription, setTeamDescription] = useState<any>();
    const [teamVisibility, setTeamVisibility] = useState<boolean>(false);
    const [teamStateStatus, setTeamStateStatus] = useState<string>("");
    const [teamStateName, setTeamStateName] = useState<string>("");
    const [teamState, setTeamState] = useState<number>(1);
    const [teamOwner, setTeamOwner] = useState<number>();
    const [teamOwnerName, setTeamOwnerName] = useState<any>();
    const [savedTeamOwners, setSavedTeamOwners] = useState<User[]>([]);
    const [addTeamUsers, setAddTeamUsers] = useState<number[]>([]);
    const [wallPostsNotif, setWallPostsNotif] = useState<boolean>();
    const [newMembersNotif, setNewMembersNotif] = useState<boolean>();
    const [newJoinNotif, setNewJoinNotif] = useState<boolean>();

    useEffect(() => {
        dispatch(getTeamSettings(params))
            .then(settings => {
                setTeamVisibility(settings.content.team_visible? settings.content.team_visible : false);
                setTeamOwner(settings.content.owner_id);
                setTeamOwnerName(settings.content.owner_name + " " + settings.content.owner_lastname);
                setTeamState(settings.content.archived)
                setTeamTitle(settings.content.name)
                setTeamDescription(settings.content.description)
                setWallPostsNotif(settings.content.wall_posts)
                setNewMembersNotif(settings.content.new_member)
                setNewJoinNotif(settings.content.new_join)}).catch((err) => {
                    safeHandleErrorResponse(err)
                })
    }, [dispatch])

    useEffect(() => {
        setTeamStateName(teamState === 1 ? "Archive" : "Active");
        setTeamStateStatus(teamState === 1 ? "Activated" : "Archived");
    }, [teamState])

    useEffect(() => {
        const fetchData = () => {
            dispatch(getTeamUsers(params)).then(users => {
                const data: number[] = users.content.map(user => {
                    return user.id
                })
                setAddTeamUsers(data)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])

    const handleTeamTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTeamTitle(event.target.value);
      };

    const handleTeamDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTeamDescription(event.target.value);
      };

    const handleVisibilitySwitchChange = (event: boolean) => {
        setTeamVisibility(event);
        };

    const handleWallPostsNotifChange = (event: boolean) => {
        setWallPostsNotif(event);
        };

    const handleNewMembersNotifChange = (event: boolean) => {
        setNewMembersNotif(event);
        };

    const handleNewJoinNotifChange = (event: boolean) => {
        setNewJoinNotif(event);
        };

    const handleUpdateTeamOwnerChange = async (event: number) => {
        setTeamOwner(event)
        };

    const handleRenameTitle = () => {
      const params = {
          team_id: team_id,
          org_id: org_id,
          name: teamTitle
      }
      dispatch(updateTeam(params))
          .then(() =>{
              setTeamTitle(teamTitle)
              setAppContextTeam({...currentTeam, name: teamTitle})
          }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleChangeDescription = () => {
        const params = {
            team_id: team_id,
            org_id: org_id,
            description: teamDescription
        }
        dispatch(updateTeam(params))
            .then(() =>{
                setTeamDescription(teamDescription)
                currentTeam.description = teamDescription
                setAppContextTeam(currentTeam)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
      };

    const handleUpdateTeamVisibility = async () => {
      const params = {
          team_id: currentTeam.id,
          org_id: org_id,
          visibility: !teamVisibility
      }
      dispatch(updateTeam(params))
      };

    const handleUpdateWallPostsNotif= async () => {
      dispatch(updateTeamNotificationSetting({team_id: currentTeam.id, org_id: org_id, wall_posts: !wallPostsNotif}))
      };

    const handleUpdateNewMembersNotif= async () => {
      dispatch(updateTeamNotificationSetting({team_id: currentTeam.id, org_id: org_id, new_member: !newMembersNotif}))
      };

    const handleUpdateNewJoinNotif= async () => {
        dispatch(updateTeamNotificationSetting({team_id: currentTeam.id, org_id: org_id, new_join: !newJoinNotif}))
        };

    const handleDelete = () => {
        const confirmation = window.confirm('Are You Sure?')

        if (confirmation){
            dispatch(deleteTeam(team_id?team_id:0)).then( value =>{
                if (value === 200) {
                    remove_item_from_lcs(LcsKeys.current_project);
                    navigate(`/${currentOrg.domain}/teams`)
                    // window.location.reload();
                    // delete all the other project related data
                }
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
    }

    const handleUpdateTeamStatus = async () => {
        const params = {
            team_id: currentTeam.id,
            org_id: org_id,
            archived: !teamState
        }
        dispatch(updateTeam(params)).then(() => {
            setTeamState(teamState === 1 ? 0 : 1);
            setTeamStateName(teamState === 1 ? "Archive" : "Active");
            setTeamStateStatus(teamState === 1 ? "Activated" : "Archived");
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const getTeamOwnersOptions = async () => {
        dispatch(getTeamUsers(params)).then(users => {
            let data: User[] = []
            users.content.forEach(user => {
                data.push({id: user.id, name: user.name, lastname: user.lastname, email: user.email})
            });
            setSavedTeamOwners(data)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
};

    const handleUpdateTeamOwner = async () => {
        const params = {
            team_id: currentTeam.id,
            org_id: org_id,
            owner_id: teamOwner        }
        dispatch(updateTeamOwner(params)).then(() => {setTeamOwner(teamOwner);}).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const constructTeamOwners = () => {
        return savedTeamOwners.map(team_owner => {
            return <Option value={team_owner.id} label={team_owner.lastname + " " + team_owner.name} style={team_owner.id === teamOwner ? {color: colors.green.forest} : {color: 'black'}}>
                    {team_owner.lastname + " " + team_owner.name}
                    </Option>
        });

    }

    const setting_sections: setting_sections = [
        {attr_id: 'ps-general', title: 'General', settings: [
            {
                title: 'Team Title', description:'Change the Title of Project.',
                value: <><Input 
                            placeholder='Team Title' 
                            value={teamTitle} 
                            onChange={handleTeamTitleChange}
                            style={{width: 200}}/>
                        <Button 
                            type='default'
                            onClick={handleRenameTitle}
                            disabled={teamTitle === currentTeam.name}>Rename
                        </Button></>
            },{
                title: 'Description', description:'Sort Description For you Team.',
                value:<><Input 
                            placeholder='Description' 
                            value={teamDescription} 
                            onChange={handleTeamDescriptionChange}
                            style={{width: 200}}/>
                        <Button 
                            type='default'
                            onClick={handleChangeDescription}>Change
                        </Button></>
            }
        ]},
        {attr_id: 'ps-users', title: 'Users', settings: [
                {
                    title: 'User Dashboard', description: `Total ${addTeamUsers.length} members.`,
                    value: <ButtonC onClick={()=>navigate(`/${currentOrg.domain}/teams/${team_id}/${teamTitle}/members`)} text='View Users'/>
                }
            ]},
        {attr_id: 'ps-notifications', title: 'Notifications', settings: [{
                title: 'Wall Posts', description: 'Notify all members that someone posted on the team wall.',
                value:<Switch onChange={handleWallPostsNotifChange} onClick={handleUpdateWallPostsNotif} checked={wallPostsNotif}/>
            },{
                title: 'New Member', description: 'Notify team owner that someone has been added to the Team.',
                value:<Switch onChange={handleNewMembersNotifChange} onClick={handleUpdateNewMembersNotif} checked={newMembersNotif}/>
            },{
                title: 'New Join', description: 'Notify team owner that someone has joined to the Team.',
                value:<Switch onChange={handleNewJoinNotifChange} onClick={handleUpdateNewJoinNotif} checked={newJoinNotif}/>
            }
            ]},
        {attr_id: 'ps-actions', title: 'Actions', settings: [{
                title: 'Visibility',
                description:'As Visible, users can join to the team, otherwise they can only be added by Organization Admins or Team Owners.',
                value:<Switch onChange={handleVisibilitySwitchChange} onClick={handleUpdateTeamVisibility} checked={teamVisibility}/>
            },{
                title: 'Provide the desired Team Owner', 
                description: 'Change The owner of the Team. The new owner must be a member in this Team. Make sure you are aware of what your doing.',
                value: <><Select onChange={handleUpdateTeamOwnerChange} placeholder="Choose Team Owner" 
                                 defaultValue={teamOwnerName} onClick={getTeamOwnersOptions}>
                            {constructTeamOwners()}
                        </Select>
                        <Button
                            style={{marginLeft: 10}}
                            size='small'
                            shape='circle'
                            onClick={handleUpdateTeamOwner}
                            icon={<CheckCircleOutlined
                            style={{color: colors.green.forest}}/>}/></>
            },{
                title: teamStateStatus, description: 'Look here on how to ' + teamStateName + '.',
                value: <Button danger onClick={handleUpdateTeamStatus}>{teamStateName}</Button>
            },{
                title: 'Delete', description:'This can not be undone.',
                value: <Popconfirm placement="top"
                                   title={<p>Are you sure you want to Delete Team <b>{teamTitle}</b> <br/>
                                       This action is not reversible. You can archive it instead and use it in the future.</p>}
                                   onConfirm={handleDelete}
                                   okText='Delete'

                > <Button danger>Delete</Button> </Popconfirm>}
            ]}
        ]
    return (
        <Page title={`${teamTitle} Settings`}>
            <PageHeader title={`${teamTitle} Settings`}/>
            <PageContent>
                <Settings setting_sections={setting_sections}/>
                <FloatButton.BackTop />
            </PageContent>
        </Page>
    )
}
  
export default TeamSettings;
