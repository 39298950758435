import React from 'react';
import {notification, Col} from 'antd';
import ButtonC from "../button";
import RowC from "../row";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {colors} from '../../assets/data/colors';


type CancelActionProps = {
    children: React.ReactNode,
    action: () => void,
    secs?: number,
    msg?: string,
    description?: React.ReactNode
}


function CancelAction({children, action, secs, msg, description}: CancelActionProps): JSX.Element {
    const seconds = secs ? secs : 4
    const message = msg ? msg : 'Do you want to cancel?'

    const onFinish = (manually: boolean) => {
        if (!manually) { action() }
    };

    const handleCancel = () => {
        const key = Date.now(); // Dayjs is also OK
        let manually = false
        notification.info({
            key,
            placement: 'bottomLeft',
            message,
            onClose: () => onFinish(manually),
            description:
            <>
                {
                    description?
                        <RowC>
                            {description}
                        </RowC>
                        : null
                }
                <RowC gutter={16} style={{float: 'right'}}>
                    <Col>
                        <CountdownCircleTimer
                            size={40}
                            strokeWidth={6}
                            isPlaying
                            duration={seconds}
                            // colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                            // colorsTime={[7, 5, 2, 0]} '#A30000', '#A30000']}
                            colors={[colors.blue.dark, colors.yellow.default]}
                            colorsTime={[3, 0]}
                        >
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>

                    </Col>
                    <Col span={12} >
                        <ButtonC
                            style={{marginTop:3}}
                            onClick={() => {
                                manually = true
                                notification.destroy(key)
                            }} text={'Cancel'}/>
                    </Col>
                </RowC>
            </>
                ,
            duration: seconds
        })
    }

    return (
        <div onClick={() => handleCancel()}>{children} </div>
    )
}

export default CancelAction;
