import React from 'react';
import { Modal } from 'antd';

type modalOptionsType = {
    open: boolean,
    children: React.ReactNode,
    title?: React.ReactNode,
    onOk?: any,
    onCancel?: any,
    footer?: any,
    key?: string,
    maskClosable?: boolean,
    keyboard?: boolean,
    style?: {},
    width?: number
}


function ModalC(modal_options:modalOptionsType): JSX.Element {
    return(
    <Modal {...modal_options}
          >
            {modal_options.children}
    </Modal>)

}
export default ModalC;