import {Dayjs} from "dayjs";
import {Moment} from "moment";

class TimeHandler{
    static getDateToSend(date?:Date){
        const now = date? date: new Date();

        const utcYear = now.getUTCFullYear();
        const utcMonth = (now.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const utcDay = now.getUTCDate().toString().padStart(2, '0');
        const utcHours = now.getUTCHours().toString().padStart(2, '0');
        const utcMinutes = now.getUTCMinutes().toString().padStart(2, '0');
        const utcSeconds = now.getUTCSeconds().toString().padStart(2, '0');

        return `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;

    }

    static getDatejsToSend(date?: Dayjs | null){
        const newDate = date? new Date(date.toString()): new Date();
        return this.getDateToSend(newDate)
    }

    static getDateMomentToSend(date?: Moment | null){
        const newDate = date? new Date(date.toString()): new Date();
        return this.getDateToSend(newDate)
    }

    static getDateToShow(utcTime: string){
        const date = new Date(utcTime);
        return date.toLocaleString();
    }

    static getDateToShowJanDDYYHHMM(utcTime: string){
        const date = new Date(utcTime);
        const options: Intl.DateTimeFormatOptions = {
            month: 'short',  // 'Aug'
            day: '2-digit',  // '04'
            year: 'numeric', // '2024'
            hour: '2-digit', // '00'
            minute: '2-digit', // '07'
            hour12: false,   // Use 24-hour format
        };

        return date.toLocaleString('en-US', options).replace(',', '');
    }
}

export default TimeHandler