import React from 'react';
import { colors } from '../../assets/data/colors';
import { Divider } from 'antd';


export function PageDivider(props: React.CSSProperties): JSX.Element {
    const {height, marginTop, marginRight, marginBottom, marginLeft, width, backgroundColor} = props;
            
    return (
            <div style={{
                marginTop: marginTop? marginTop: 10,
                marginRight:  marginRight? marginRight: "80px !important",
                marginBottom: marginBottom? marginBottom: 10,
                marginLeft:  marginLeft? marginLeft: -25,
                height: height? height: 15, 
                width: width? width: "calc(100% + 50px)",
                backgroundColor: backgroundColor? backgroundColor: colors.gray.background
                }}> </div>
    )
}

export function PageVerticalDivider(style_props:React.CSSProperties): JSX.Element {
    /* Dont use this */
    const {marginTop, marginRight, width, position, float, height} = style_props;
    let style:React.CSSProperties = {
        height: height? height: 'calc(95vh - 160px)', 
        width: width? width: `${width}%`, 
        marginRight: marginRight? marginRight: 10,
        marginTop: marginTop? marginTop: 10 
    }
    if (float){
        style['float'] = float
    }
    if (position){
        style['position'] = position
    }

    return(
        <Divider style={style} type='vertical'> </Divider>
        // <div style={{
        //     marginTop: marginTop? marginTop: -25,
        //     marginRight:  marginRight? marginRight: "80px !important",
        //     marginBottom: marginBottom? marginBottom: 10,
        //     marginLeft:  marginLeft? marginLeft: -25,
        //     height: pixels? pixels: 'calc(100vh - 70px)', 
        //     width: width? width: 15,
        //     backgroundColor: colors.background_gray
        //     }}> </div>
    )
}

export function verticalDividerFolder(style_props:React.CSSProperties, icon:React.ReactNode): JSX.Element {
    return (
    <div>
        {icon}
        <Divider style={style_props} type='vertical' />
    </div> 
    )
}