import React, {useState} from 'react';
import { green, presetPalettes, red, blue, yellow, gold } from '@ant-design/colors';
import {ColorPicker, Space} from 'antd';
import type { ColorPickerProps } from 'antd';
import { Color } from 'antd/es/color-picker';
import CopyToClipboard from "../buttons/copyToClipboard";

function ColorPickerTool(): JSX.Element {
    const [color, setColor] = useState<string>('#1677ff');
    const [open, setOpen] = useState(true); // Control the popover visibility
    type Presets = Required<ColorPickerProps>['presets'][number];

    const genPresets = (presets = presetPalettes) =>
        Object.entries(presets).map<Presets>(([label, colors]) => ({ label, colors }));

    const presets = genPresets({ blue, red, green, yellow, gold });
    const handleColorChange = (value: Color) => {
        setColor(value.toHexString()); // Convert Color object to hex string and set it
    };

    return <Space style={{marginLeft: '45%'}}>
        <ColorPicker
            presets={presets}
            value={color}
            onChange={handleColorChange}

            defaultValue="#1677ff"
            showText
            open={open}
            onOpenChange={setOpen}
        />
        <CopyToClipboard text={color}/>
    </Space>
}

export default ColorPickerTool;