// @ts-ignore
import React, { FC } from 'react';
import {Avatar, AvatarProps, Tooltip} from "antd";
import {colors} from "../assets/data/colors";
import {TooltipProps} from "antd/es/tooltip";


interface TPAvatarProps extends AvatarProps{
    user: {
        img: string,
        fullname: string,
        initials: string,
        id?: number | string,
        url?: string
    }
}

interface TPTooltipAvatarProps extends AvatarProps{
    avatarPros: TPAvatarProps,
    tooltipProps?: TooltipProps
}

const TPAvatar: FC<TPAvatarProps> = (props) => {
    const {user, ...rest} = props
    return <Avatar style={{ backgroundColor: colors.blue.dark }} {...rest}>
        {user.img?
            <img alt={user.fullname} src={user.img}/>: user.initials}
    </Avatar>;
};

export const TPTooltipAvatar: FC<TPTooltipAvatarProps> = (props) => {
    const {avatarPros, tooltipProps} = props
    const { id, fullname, url } = avatarPros.user
    return <Tooltip
        key={id}
        placement="top"
        title={ <a target='_blank' rel="noreferrer" href={url}> {fullname} </a> }
        {...tooltipProps}
    >
        <Avatar {...avatarPros}/>
    </Tooltip>;
};

// function TPAjvatar({avatarProps, useTooltip}:TPAvatarProps): JSX.Element {
//
//     return
//     //     return (
//     //         <Avatar.Group>
//     //             {
//     //                 reviewers.reviewers.map(reviewer=>{
//     //                     return <Tooltip
//     //                         key={reviewer.id}
//     //                         placement="top"
//     //                         title={
//     //                             <a target='_blank'
//     //                                href={urlProject("userProfile", )}
//     //                                // href={`/${currentOrg.name}/projects/${currentProject.p_key}/users/${reviewer.id !== undefined ? reviewer.id : reviewer.assignee}/view`}>
//     //                                 {reviewer.fullname}
//     //                             </a>}
//     //                     >
//     //                         <Avatar size={avatarSize} style={{ backgroundColor: colors.blue.dark }}>{reviewer.initials?reviewer.initials:null}</Avatar>
//     //                     </Tooltip>
//     //                 })
//     //             }
//     //             {
//     //                 reviewers.total_reviewers > 5?
//     //                     <Avatar size={avatarSize} style={{ backgroundColor: colors.blue.dark }}>+{reviewers.total_reviewers - 5}</Avatar>
//     //                     : null
//     //             }
//     //         </Avatar.Group>
//     //     )
//     // }
//     //
//     // return(<></>)
//
// }

export default TPAvatar;