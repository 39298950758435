import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux/store';
import {TestCase} from '../../models/entities/test_case_info';
import {Card, Table, Tag} from 'antd';
import {DropDownOnClick} from "../../components/dropdown/dropdown";
import {updateCase, updateCaseComponents} from "../../redux/actions/cases";
import {useAppContext} from "../../AppContext";
import {priorities} from "../../assets/data/priority";
import {UserOptions} from "../../models/entities/user";
import {getAllProjectUsers} from "../../redux/actions/project_actions";
import {getComponents} from "../../redux/actions/component_actions";
import {colors} from "../../assets/data/colors";
import {TextareaOnClick} from "../../components/input";
import CopyToClipboard from "../../components/buttons/copyToClipboard";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';


type TestSuiteCaseStatsProps = {
    openedInfo: TestCase,
    setOpenedInfo: Dispatch<SetStateAction<TestCase>>,
    cases?: TestCase[],
    setCases?: Dispatch<SetStateAction<TestCase[]>>
}

interface DataType {
    key: React.Key,
    title: React.ReactNode,
    dataIndex: string,
    width: string,
    align?: any
}

function TestSuiteCaseInfoTable({openedInfo, setOpenedInfo, cases, setCases}: TestSuiteCaseStatsProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {currentProject, currentOrg} = useAppContext()

    const [projectUsers, setProjectUsers] = useState<UserOptions[]>([])
    const [projectComponents, setProjectComponents] = useState<UserOptions[]>([])

    const defaultOwner = {
        value: openedInfo.owner.id,
        label: `${openedInfo.owner.lastname} ${openedInfo.owner.name}`
    }

    const defaultPriority = {
        value: openedInfo.priority,
        label: openedInfo.priority
    }

    const defaultComponents = openedInfo.components.map(component => {
        return {
            value: component.id,
            label: component.title
        }
    })

    // Edit state
    const [editPriority, setEditPriority] = useState<UserOptions>(defaultPriority)
    const [editOwner, setEditOwner] = useState<UserOptions>(defaultOwner)
    const [editComponents, setEditComponents] = useState<UserOptions[]>(defaultComponents)
    const [editDescription, setEditDescription] = useState<string>(openedInfo.description)

    const editComponentsIds = editComponents.map(component => component.value)

    useEffect(() => {
        // Update editOwner when openedInfo.owner.id changes
        setEditOwner(defaultOwner)
        setEditPriority(defaultPriority)
        setEditComponents(defaultComponents)
        setEditDescription(openedInfo.description)
    }, [openedInfo]);

    useEffect(() => {
        const params = {org_id: currentOrg.id, project_id: currentProject.id}
        dispatch(getAllProjectUsers(params))
            .then(users_found => {
                const users = users_found.content.map((user): UserOptions => {
                    return {
                        value: user.id,
                        label: `${user.lastname} ${user.name}`
                    }
                })
                setProjectUsers(users)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getComponents(params))
            .then(components_found => {
                const components = components_found.content.map((components): UserOptions => {
                    return {
                        value: components.id,
                        label: components.title
                    }
                })
                setProjectComponents(components)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }, [dispatch])

    const handleSaveCase = async (extra: any) => {
        return dispatch(updateCase({
            org_id: currentOrg.id,
            project_id: currentProject.id,
            id: openedInfo.id,
            ...extra
        }))
    }

    const handleEditComponents = () => {

        dispatch(updateCaseComponents({
            project_id: currentProject.id,
            id: openedInfo.id,
            org_id: currentOrg.id,
            components_ids: editComponentsIds
        })).then(()=>{
            let new_info = {...openedInfo}
            new_info.components = editComponents.map((component)=>{
                return {
                    id: component.value,
                    title: component.label as string
                }
            })
            setOpenedInfo(new_info)
            if(cases && setCases){
                let new_c = [...cases].map((t_case => {
                    if(t_case.id === openedInfo.id){
                        t_case.components = new_info.components
                    }
                    return t_case
                }))
                setCases(new_c)
            }
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }
    const columns: DataType[] = [
        {
            title: 'Attribute',
            dataIndex: 'attr',
            key: 'attr',
            width: '25%'
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            width: '75%'
        }
    ];

    const dataSource = [
        {
            key: '1',
            attr: 'Owner',
            value: <DropDownOnClick
                onChange={(e) => {
                    setEditOwner(e)
                }}
                onOK={() => {
                    handleSaveCase({owner_id: editOwner.value})
                        .then(() => {
                            let new_info = {...openedInfo}
                            new_info.owner.id = editOwner.value
                            new_info.owner.lastname = (editOwner.label as string).split(' ')[0]
                            new_info.owner.name = (editOwner.label as string).split(' ')[1]
                            setOpenedInfo(new_info)
                        })
                }}
                style={{width: 150}}
                button={{
                    text: `${openedInfo.owner.name} ${openedInfo.owner.lastname}`
                }}
                value={editOwner.value}
                options={projectUsers}/>
        },
        {
            key: '2',
            attr: 'Priority',
            value: <DropDownOnClick
                onChange={(e) => {
                    setEditPriority(e)
                }}
                onOK={() => {
                    handleSaveCase({priority: editPriority.value})
                        .then(()=>{
                            let new_info = {...openedInfo}
                            new_info.priority=editPriority.value
                            setOpenedInfo(new_info)

                            if (cases && setCases){
                                const new_c = [...cases].map(t_case=>{
                                    if(t_case.id === openedInfo.id){
                                        t_case.priority=editPriority.value
                                    }
                                    return t_case
                                })
                                setCases(new_c)
                            }
                        })
                }}
                style={{width: 50}}
                value={editPriority.value}
                button={{
                    text: `${openedInfo.priority}`
                }}
                options={priorities}/>
        },
        {
            key: '3',
            attr: 'Components',
            value: <DropDownOnClick
                onChange={(e) => {setEditComponents(e)}}
                onOK={handleEditComponents}
                style={{width: 350}}
                value={editComponentsIds}
                mode={'multiple'}
                button={{
                    text: openedInfo.components.length === 0 ?
                        'No Components' :
                        openedInfo.components.map((component =>
                                <Tag color="blue" key={component.id}>{component.title}</Tag>
                        ))
                }}
                options={projectComponents}/>
        }
    ];

    return <>
        <Card style={{backgroundColor: colors.gray.default, width: '100%', maxHeight: 180, overflow: 'auto'}}>
            <CopyToClipboard type={'text'}
                             text={openedInfo.description}
                             style={{float: 'right', marginTop: -30, marginRight: -20, color: colors.blue.default}}
            />
            <TextareaOnClick
                input={{
                    value: editDescription,
                    setValue: setEditDescription,
                    handleOk: () => {
                        handleSaveCase({description: editDescription})
                            .then(() => {
                                let new_info = {...openedInfo}
                                new_info.description = editDescription
                                setOpenedInfo(new_info)
                                if (cases && setCases){
                                    const new_c = [...cases].map(t_case=>{
                                        if(t_case.id === openedInfo.id){
                                            t_case.description=editDescription
                                        }
                                        return t_case
                                    })
                                    setCases(new_c)
                                }
                            })

                    }
                }}
                text={{
                    style: {width: '100%', height: '100%'}, //, textAlign:'left'
                    value: openedInfo.description ? openedInfo.description
                        : 'No Descriptions'
                }}
            />
        </Card>
        <Table style={{marginTop: 10, width: '100%', marginLeft: 25, float: 'left'}}
               dataSource={dataSource}
               columns={columns}
               showHeader={false}
               pagination={false}/>
    </>
}

export default TestSuiteCaseInfoTable;