import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Space } from 'antd';
import ModalC from "../modal";
import RichEditor from "../rich_editor/rich_editor";
import { createWallPost, updatePost } from "../../redux/actions/team_wall_actions";
import { getTeamUsers } from "../../redux/actions/team_actions";
import { useAppContext } from "../../AppContext";
import { AppDispatch } from "../../redux/store";
import { WallPosts, NewWallPost } from "../../models/entities/team";
import { empty_post } from "../../services/EmptyEntities/EmptyPost";
import './post_popup.css';
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";

interface PostProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    wallPostPage: WallPosts[];
    setWallPostPage: React.Dispatch<React.SetStateAction<WallPosts[]>>;
    wallPinnedPostPage: WallPosts[];
    setWallPinnedPostPage: React.Dispatch<React.SetStateAction<WallPosts[]>>;
    selectedPost: WallPosts;
    setSelectedPost: React.Dispatch<React.SetStateAction<WallPosts>>;
}

export default function CreatePostPopup(props: PostProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const { currentOrg, currentTeam, loggedInUser} = useAppContext();
    const { visible, setVisible, wallPostPage, setWallPostPage, selectedPost, wallPinnedPostPage, setWallPinnedPostPage, setSelectedPost } = props;
    const [post, setPost] = useState<string>("");
    const [tagOptions, setTagOptions] = useState<{ id: number; value: string }[]>([]);

    useEffect(() => {
        dispatch(getTeamUsers({ org_id: currentOrg.id, team_id: currentTeam.id })).then(users => {
            const options = users.content.map(user => ({ id: user.id, value: `${user.name} ${user.lastname}` }));
            setTagOptions(options);
        }).catch((err) => {
            safeHandleErrorResponse(err)
        });
    }, [dispatch, currentOrg.id, currentTeam.id]);

    const handleEditorChange = (content: string) => {
        setPost(content);
    };

    const handleCancel = () => {
        setVisible(false);
        setSelectedPost(empty_post);
        setPost('');
    };

    const handlePost = async (isCreating: boolean) => {
        const postPayload: NewWallPost = {
            org_id: currentOrg.id,
            team_id: currentTeam.id,
            user_id: loggedInUser.id,
            post_text: post,
            post_image: post.includes('<img') ? post : null,
            ...(!isCreating && {
                post_id: selectedPost.post_id,
                poster: selectedPost.poster,
                timestamp: selectedPost.timestamp,
                pinned: selectedPost.pinned
            })
        };

        if (post.replace(/<(?!img)[^>]+>/g, '').trim().length > 0) {
            if (!isCreating && selectedPost.pinned === 1) {
                await dispatch(updatePost(postPayload as WallPosts, wallPinnedPostPage));
                setWallPinnedPostPage(wallPinnedPostPage);
            }

            const action = isCreating ?
                createWallPost(postPayload, wallPostPage)
                : updatePost(postPayload as WallPosts, wallPostPage);
            // const updatedPosts = await dispatch(action);
            dispatch(action)
                .then(updatedPosts=>{
                    setWallPostPage(updatedPosts);
                    setVisible(false);
                    setPost('');
                    setSelectedPost(empty_post);

                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
    };

    return (
        <ModalC open={visible} title={selectedPost === empty_post ? 'Create Post' : 'Edit Post'} onOk={() => handlePost(selectedPost === empty_post)} onCancel={handleCancel}>
            <Form.Item>
                <Space>
                    <RichEditor
                        value={selectedPost === empty_post ? post : selectedPost.post_text}
                        onChange={handleEditorChange}
                        atValues={tagOptions}/>
                </Space>
            </Form.Item>
        </ModalC>
    );
}

// import { Dispatch, SetStateAction, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import "./post_popup.css";
// import React from "react";
// import ModalC from "../modal";
// import { Form, Space } from 'antd';
// import 'react-quill/dist/quill.snow.css'; // Import the styles
// import 'quill-image-upload';
// import RichEditor from "../rich_editor/rich_editor";
// import { createWallPost, updatePost } from "../../redux/actions/team_wall_actions";
// import { useAppContext } from "../../AppContext";
// import { AppDispatch } from "../../redux/store";
// import { NewWallPost, WallPosts } from "../../models/entities/team";
// import { empty_post } from "../../services/EmptyEntities/EmptyPost";
// import { getTeamUsers } from "../../redux/actions/team_actions";
//
//
// type PostProps = {
//   visible: boolean;
//   setVisible: Dispatch<SetStateAction<boolean>>;
//   wallPostPage: WallPosts[];
//   setWallPostPage: Dispatch<WallPosts[]>;
//   wallPinnedPostPage: WallPosts[];
//   setWallPinnedPostPage: Dispatch<WallPosts[]>;
//   selectedPost: WallPosts;
//   setSelectedPost: Dispatch<SetStateAction<WallPosts>>;
// }
//
// export default function CreatePostPopup(props: PostProps):JSX.Element {
//   const dispatch: AppDispatch = useDispatch();
//   const {currentOrg, currentTeam, loggedInUser} = useAppContext();
//   const loggedin_user = loggedInUser
//
//   const {visible, setVisible, setWallPostPage, wallPostPage, selectedPost, wallPinnedPostPage, setWallPinnedPostPage, setSelectedPost} = props;
//   const [post, setPost] = useState<any>();
//   const [tagOptions, setTagOptions] = useState<{id: number, value: string}[]>([]);
//
//   const onCreate = () => {
//
//     const new_post: NewWallPost = {
//             org_id: currentOrg.id,
//             team_id: currentTeam.id,
//             post_text: post,
//             user_id: loggedin_user.id,
//             post_image: post.includes('<img') ? post: null
//         }
//         if (post.replace(/<(?!img)[^>]+>/g, '').trim().length > 0) {
//         dispatch(createWallPost(new_post, wallPostPage))
//             .then((posts) => {
//                 setWallPostPage(posts);})
//                 setPost('');
//     setTimeout(() => {
//         setVisible(false)
//         setSelectedPost(empty_post);
//     }, 500);}
// }
//
//   const onEdit = () => {
//
//     const new_post: WallPosts = {
//             org_id: currentOrg.id,
//             team_id: currentTeam.id,
//             post_text: post,
//             post_image: post.includes('<img') ? post: null,
//             post_id: selectedPost.post_id,
//             poster: selectedPost.poster,
//             timestamp: selectedPost.timestamp,
//             pinned: selectedPost.pinned
//         }
//         if (post.replace(/<(?!img)[^>]+>/g, '').trim().length > 0) {
//         if (selectedPost.pinned === 1) {
//           dispatch(updatePost(new_post, wallPinnedPostPage))
//             .then(() => {
//                 setWallPinnedPostPage(wallPinnedPostPage);})
//         }
//         dispatch(updatePost(new_post, wallPostPage))
//             .then(() => {
//                 setWallPostPage(wallPostPage);
//                 setSelectedPost(empty_post);})
//                 setPost('');
//     setTimeout(() => {
//         setVisible(false)
//     }, 500);}
//   }
//
//   const handleEditorChange = (content: string) => {
//     setPost(content);
//   };
//
//   const handleCancel = () => {
//     setVisible(false)
//     setSelectedPost(empty_post)
//     setPost('')
// };
//
// useEffect(() => {
//   const fetchData = () => {
//       dispatch(getTeamUsers({org_id: currentOrg.id, team_id: currentTeam.id})).then(users => {
//           const data:any[] = users.content.map(user => {
//               return {id: user.id, value: user.name + " " + user.lastname}
//           })
//           setTagOptions(data)
//       })
//   }
//   fetchData()
// }, [dispatch, currentTeam])
//
//   return (
//     <ModalC open={visible} title={selectedPost === empty_post ? 'Create Post' : 'Edit Post'} onOk={() => selectedPost === empty_post? onCreate() : onEdit()} onCancel={handleCancel}>
//       <Form.Item>
//         <Space.Compact>
//         <div>
//           <RichEditor
//               value={selectedPost === empty_post ? post : selectedPost.post_text}
//               onChange={handleEditorChange}
//               atValues={tagOptions}/>
//         </div>
//         </Space.Compact>
//       </Form.Item>
//     </ModalC>
//   );
// }
