import { Form, Select } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { Team } from "../../models/entities/team";

type UsersProps = {
    preselectedTeamIds: string[];
    teams: Team[];
    setUserTeams: Dispatch<SetStateAction<Team[]>>;
}

export function TeamFormSelect(props: UsersProps):JSX.Element{
    const {preselectedTeamIds, teams, setUserTeams} = props;

    const renderTeamOptions = () => {
        return teams.map(team => {
            return <Select.Option value={team.id}>{team.name}</Select.Option>
        })
    }

    const handleTeamTagChange = (e:any) => {
        teams.find(({id})=> id===e)
        // e.map((team:any)=>{console.log(team.find(team))})
        setUserTeams(e.map((team:any)=>{return teams.find(({id})=> id===team)}))
    }

    return (
        <Form.Item name="teams" label="Teams">
            <Select mode="tags" 
                    style={{ width: '100%' }}
                    defaultValue={preselectedTeamIds}
                    onChange={(e) => handleTeamTagChange(e)}
            >
                {renderTeamOptions()}
            </Select>
        </Form.Item>
    )
}